import React from 'react';

export default props => {
    const {color} = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" {...props}>
            <g fill="none" fillRule="evenodd">
                <g>
                    <circle cx="20" cy="20" r="20" fill={color}/>
                    <path fill="#FFF" fillRule="nonzero" d="M23.578 21.652l3.221 2.285c.31.22.436.613.314.974-.12.352-.372.8-.905 1.247-1.663 1.396-4.313 1.982-9.56-4.272-5.247-6.253-4.21-8.761-2.546-10.156.53-.447 1.015-.619 1.382-.675a.858.858 0 0 1 .908.481L18.08 15.1a.855.855 0 0 1-.08.867l-.923 1.282a.85.85 0 0 0-.027.963c.333.512.896 1.34 1.51 2.07.612.732 1.33 1.43 1.777 1.846a.85.85 0 0 0 .953.141l1.422-.687c.281-.135.611-.11.866.071z"/>
                </g>
            </g>
        </svg>
    );
}

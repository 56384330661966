import React, {Component} from 'react';
import classnames from 'classnames';
import _ from "lodash";

import CallButtons from './CallButtons';
import './ScreenHeader.scss';

class ScreenHeader extends Component {
    consultationRequestHandler(type) {
        const {screen, requestConsultationWithType, requestPhoneConsultation} = this.props;
        if (screen.settings.whereToCall === 'phone' && type === 'audio') {
            return requestPhoneConsultation(screen.settings.phone);
        }
        requestConsultationWithType(type);
    }

    render() {
        const {
            screen,
            isVideoConsultationEnabled,
            isAudioConsultationEnabled,
            consultationRequestProgress,
            departmentsState,
            requestChat,
            showChatWasNotReadIcon,
            isQrChatEnabled,
            logo,
            isCallButtonsExpanded,
            requestAIChat,
        } = this.props;

        const {settings} = screen;
        const buttonColor = screen.settings.buttonColor;
        const title = _.get(screen, 'settings.options.title') || '';
        const screenHeaderClassNames = classnames("mw-screen-header", "mw-call-buttons-opened");

        return (
            <div className={screenHeaderClassNames}>
                {!isCallButtonsExpanded && <div className="mw-screen-title">{title}</div>}
                <div className="mn-call-buttons-container">
                    <CallButtons
                        isVideoConsultationEnabled={isVideoConsultationEnabled && settings.isVideoConsultationEnabled}
                        isAudioConsultationEnabled={isAudioConsultationEnabled && settings.isAudioConsultationEnabled}
                        requestChat={requestChat}
                        requestAIChat={requestAIChat}
                        requestConsultationWithType={this.consultationRequestHandler.bind(this)}
                        consultationRequestProgress={consultationRequestProgress}
                        color={buttonColor}
                        departmentsState={departmentsState}
                        showChatWasNotReadIcon={showChatWasNotReadIcon}
                        isQrChatEnabled={isQrChatEnabled && settings.isQrChatEnabled}
                        logo={logo}
                        isInHeader
                    />
                </div>
            </div>
        );
    }
}

export default ScreenHeader;

import React, { useEffect, useState } from 'react';
import browserIcons from '../../assets/icons/BrowserIcons';
import capitalize from '../../utils/capitalize';
import Modal from '../Modal/Modal';

import './WrongBrowser.module.scss'

const BROWSER_BRAND_NAMES = {
    chrome: 'Chrome',
    firefox: 'Firefox',
    samsung: 'Samsung'
}

const WrongBrowser = (props) => {

    const [closeTimeout, setCloseTimeout] = useState( setTimeout(() => { if (onHide) onHide()}, 10000))
    const {
        show,
        onHide,
        closable = false,
        recommendedBrowsers=[]
    } = props;

    useEffect(() => {
        return () => {
            clearTimeout(closeTimeout)
        }
    }, []);

    const getRecommendationString = () => {
        const browserBrands = recommendedBrowsers.map((name) => BROWSER_BRAND_NAMES[name] || name);
        if (!browserBrands.length || browserBrands.length === 0 ) {
            return 'a compatible'
        }
        if (browserBrands.length <= 2) {
            return browserBrands.map(capitalize).sort().join(' or ')
        }
        if (browserBrands.length > 2) {
            return `${browserBrands.slice(0, browserBrands.length-1).map(capitalize).join(', ')}, or ${capitalize(browserBrands[browserBrands.length-1])}`
        }
    }

    return <Modal
        show={show}
        onHide={onHide}
        title={`Important - use ${  recommendedBrowsers.includes('chrome') 
        ? BROWSER_BRAND_NAMES.chrome
        : (BROWSER_BRAND_NAMES[recommendedBrowsers[0]] || recommendedBrowsers[0])} 
        `}
        titleClassName={'errorMessage_title'}
        contentClassName={'errorMessage_wrapper'}
        closable={closable}
    >
            <div
                className={'errorMessage'}
            >
                The browser you're using isn't compatible with <b>video and voice</b> calls. Please re-open in {getRecommendationString()}{(getRecommendationString() === 'Safari') ? '.' : ' Browser.'}
            </div>
            <div
                className={'browserRecommendations'}
            >
            {
                recommendedBrowsers.map( browser => {
                    const Icon = browserIcons[capitalize(browser)];
                    if (Icon) return <div  key={browser} className={'browserSuggestion'}><Icon/></div>
                })
            }
            </div>
    </Modal>
};
WrongBrowser.defaultProps = {
    recommendedBrowsers: []
}
export default WrongBrowser;
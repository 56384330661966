import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';
import Logo from '../../components/Logo/Logo';
import {ConsultationConsumer} from '../../contexts/Consultation';
import {TemplateConsumer} from '../../contexts/Template';
import Spinner from '../../components/Spinner/Spinner';
import CallStopIcon from '../../assets/icons/CallStop';
import Button from '../../components/Button/Button';
import ConfirmationNotificationSoundUrl from '../../assets/areyouhere.mp3';
import CallingIcon from '../../assets/icons/CallingIcon';
import './ConsultationRequest.scss';

const WAIT_CONFIRMATION_TIME = 30000;
const WAIT_TIMEOUT_WITHOUT_QUEUE = 60000;

const ConfirmationNotificationSound = new Audio(
    ConfirmationNotificationSoundUrl
);

const REQUEST_STATES = {
    initial: 'initial',
    confirmQuestion: 'confirmQuestion',
};

class ConsultationRequest extends Component {
    static propTypes = {
        consultationRequest: PropTypes.object.isRequired,
        room: PropTypes.object.isRequired,
        stopConsultationRequest: PropTypes.func.isRequired,
    };

    state = {
        state: REQUEST_STATES.initial,
        tickTime: 15,
    };

    componentDidMount() {
        if (this.props.consultationRequest.shouldShowQueue) {
            return this.initWaitConfirmationTimeout();
        }
        this.initWaitTimeoutWithoutQueue();
    }

    componentWillUnmount() {
        clearTimeout(this.waitConfirmationTimeout);
        clearInterval(this.confirmationTicker);
        clearTimeout(this.timeoutWithoutQueue);
    }

    resetConfirmationQuestion() {
        clearInterval(this.confirmationTicker);
        this.setState({state: REQUEST_STATES.initial, tickTime: 15}, () =>
            this.initWaitConfirmationTimeout()
        );
    }

    initWaitTimeoutWithoutQueue() {
        clearTimeout(this.timeoutWithoutQueue);
        this.timeoutWithoutQueue = setTimeout(() => {
            this.props.stopConsultationRequest();
        }, WAIT_TIMEOUT_WITHOUT_QUEUE);
    }

    initWaitConfirmationTimeout() {
        if (!this.props.consultationRequest.shouldAskConfirmation) {
            return;
        }
        clearTimeout(this.waitConfirmationTimeout);
        this.waitConfirmationTimeout = setTimeout(() => {
            this.initConfirmation();
        }, WAIT_CONFIRMATION_TIME);
    }

    initConfirmation() {
        ConfirmationNotificationSound.play();
        this.setState(
            {state: REQUEST_STATES.confirmQuestion, tickTime: 15},
            () => this.initTicker()
        );
    }

    initTicker() {
        clearInterval(this.confirmationTicker);
        this.confirmationTicker = setInterval(() => {
            let {tickTime} = this.state;
            if (tickTime === 0) {
                clearInterval(this.confirmationTicker);
                return this.props.stopConsultationRequest();
            }
            this.setState({tickTime: --tickTime});
        }, 1000);
    }

    getWaitingContent() {
        const {
            consultationRequest,
            stopConsultationRequest,
            toSendingPhoneNumber,
            room,
            template,
        } = this.props;

        const companyName = room && room.roomInfo.company_name;
        const allowLeavePhoneNumber =
            _.get(room, 'roomInfo.allow_leave_phone_number') &&
            _.get(template, 'hasAdditionalOption');

        return (
            <div className={classnames('contentContainer', 'consultationRequestContainer', allowLeavePhoneNumber && 'with-leave-a-phone-number-button')}>
                <div className='positionInQueue'>
                    <div className='requestCompanyName'>{companyName}</div>
                    {consultationRequest.shouldShowQueue ? (
                        <Fragment>
                            <CallingIcon className='calling-icon' />
                            <div className='positionText'>
                                Please wait...
                                <br />
                                Your position in the queue:
                            </div>
                            <div className='numberText'>
                                {consultationRequest.positionInQueue}
                            </div>
                        </Fragment>
                    ) : (
                        <Spinner>Connecting</Spinner>
                    )}
                </div>
                {allowLeavePhoneNumber && (
                    <Button
                        className='leave-a-message-button'
                        type='primary'
                        onClick={toSendingPhoneNumber}
                    >
                        Leave a message
                    </Button>
                )}
                <CallStopIcon
                    className='callStopIcon'
                    onClick={stopConsultationRequest}
                />
                <Logo room={room} position='bottomleft' withLink />
            </div>
        );
    }

    getConfirmationQuestion() {
        const {stopConsultationRequest, room} = this.props;
        const {tickTime} = this.state;

        return (
            <div className='contentContainer consultationRequestContainer'>
                <div className='questionContainer'>
                    <div className='confirmationQuestion'>
                        Are you still here?
                    </div>
                    <Button
                        type='primary'
                        onClick={this.resetConfirmationQuestion.bind(this)}
                    >
                        Yes, i`m here
                    </Button>
                    <Button type='default' onClick={stopConsultationRequest}>
                        No, i left{' '}
                        <span className='requestTickerTime'>{tickTime}</span>
                    </Button>
                </div>
                <CallStopIcon
                    className='callStopIcon'
                    onClick={stopConsultationRequest}
                />
                <Logo room={room} position='bottomleft' withLink />
            </div>
        );
    }

    render() {
        const {state} = this.state;
        const {consultationRequest} = this.props;

        if (
            !consultationRequest.shouldShowQueue ||
            state === REQUEST_STATES.initial
        ) {
            return this.getWaitingContent();
        }

        return this.getConfirmationQuestion();
    }
}

export default props => (
    <TemplateConsumer>
        {({state: templateState}) => (
            <ConsultationConsumer>
                {({state, actions}) => (
                    <ConsultationRequest
                        template={templateState.template}
                        {...state}
                        {...actions}
                        {...props}
                    />
                )}
            </ConsultationConsumer>
        )}
    </TemplateConsumer>
);

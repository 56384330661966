import React, {Component} from 'react';
import Warning from '../Warning/Warning';
import './AppError.scss';

class AppError extends Component {
    render() {
        return (
            <div className="contentContainer" style={{backgroundColor: '#2f3243'}}>
                <Warning text={this.props.error}/>
            </div>
        );
    }
}

export default AppError;

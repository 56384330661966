import React, {Component} from 'react';
import classnames from 'classnames';
import logoImage from '../../assets/logo.svg';
import ScreenHeader from './ScreenHeader';
import './VideoTextScreen.scss';

class VideoTextScreen extends Component {
    constructor(props) {
        super(props);
        this.updateFileUrl();
    }

    getIdFromUrl(url) {
        const regExp =
            /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        const match = url.match(regExp);
        return match && match[7].length === 11 ? match[7] : false;
    }

    updateFileUrl() {
        const {screen} = this.props;
        const file = screen.settings.options.file;
        if (!file || file.name === this.fileName) {
            return;
        }
        this.fileUrl =
            file && (file.url ? file.url : URL.createObjectURL(file.file));
        this.fileName = file.name;
    }

    render() {
        const {
            screen,
            room,
            isVideoConsultationEnabled,
            isAudioConsultationEnabled,
            requestConsultationWithType,
            requestPhoneConsultation,
            consultationRequestProgress,
            isOnPreview,
            logo,
            departmentsState,
            requestChat,
            showChatWasNotReadIcon,
            isQrChatEnabled,
            onQrLogoClick,
            isCallButtonsExpanded,
            requestAIChat,
        } = this.props;
        this.updateFileUrl();
        const videoSource = screen.settings.options.source;
        const videoLink = screen.settings.options.link;
        const videoFile = screen.settings.options.file;
        const text = screen.settings.options.text;
        const backgroundColor = screen.settings.backgroundColor;

        const showTeleportivityLogo = room && room.roomInfo.powered_by_logo;

        const uploadedLogoClassName = classnames(
            'mw-uploaded-logo',
            isCallButtonsExpanded && 'withExpandedCallButtons'
        );

        return (
            <div
                className='mw-screen-container mw-videotext-screen'
                style={{background: backgroundColor}}
            >
                <ScreenHeader
                    room={room}
                    screen={screen}
                    isVideoConsultationEnabled={isVideoConsultationEnabled}
                    isAudioConsultationEnabled={isAudioConsultationEnabled}
                    requestConsultationWithType={requestConsultationWithType}
                    requestChat={requestChat}
                    requestAIChat={requestAIChat}
                    requestPhoneConsultation={requestPhoneConsultation}
                    consultationRequestProgress={consultationRequestProgress}
                    departmentsState={departmentsState}
                    showChatWasNotReadIcon={showChatWasNotReadIcon}
                    isQrChatEnabled={isQrChatEnabled}
                    logo={logo}
                    isCallButtonsExpanded={isCallButtonsExpanded}
                />
                {(videoLink || videoFile) && (
                    <div className='mw-screen-videotext-container'>
                        {videoSource === 'youtube' && (
                            <iframe
                                src={`https://www.youtube.com/embed/${this.getIdFromUrl(
                                    videoLink
                                )}`}
                                allowFullScreen
                            />
                        )}
                        {videoSource === 'file' && (
                            <video
                                src={
                                    videoFile.url ? videoFile.url : this.fileUrl
                                }
                                disablePictureInPicture={true}
                                controls
                            />
                        )}
                        <div
                            className='mw-videotext-text customYScroll'
                            style={{
                                overflow: isOnPreview ? 'hidden' : 'scroll',
                            }}
                        >
                            {text}
                        </div>
                    </div>
                )}
                {logo && (
                    <div
                        onClick={onQrLogoClick}
                        className={uploadedLogoClassName}
                    >
                        <img src={logo.url} />
                    </div>
                )}

            </div>
        );
    }
}

export default VideoTextScreen;

import React, {Component} from 'react';
import Textarea from 'react-textarea-autosize';
import classNames from 'classnames';
import './Input.scss';

import _ from 'lodash';


class Input extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messageLength: 0
        };

    }

    get value() {
        return this.refs.input.value;
    }

    handleChange = (e) => {
        this.setState({messageLength: _.get(e, 'target.value.length') || 0});
    };

    render() {
        const {withCountDown, maxLength, multiline, className, ...restProps} = this.props;

        const inputClassName = classNames("webscreenInput", className, {
            "webscreenMultirowInput": multiline,
        });

        if (withCountDown) {
            return (
                <div>
                    <div className="inputWrapper">
                        <label className="countDownLabel"
                               htmlFor={this.props.id}>{this.state.messageLength || 0}/{maxLength || ''}</label>
                        {multiline
                            ? <Textarea id={this.props.id}
                                        ref="input"
                                        onChange={this.handleChange}
                                        className={inputClassName}
                                        type="text"
                                        maxRows={4}
                                        maxLength={maxLength}
                                        {...restProps}/>
                            : <input id={this.props.id}
                                     ref="input"
                                     onChange={this.handleChange}
                                     className={inputClassName}
                                     type="text"
                                     maxLength={maxLength}
                                     {...restProps}/>
                        }
                    </div>
                </div>
            );
        }
        return (
            <input ref="input"
                   className={inputClassName}
                   type="text"
                   maxLength={maxLength}
                   {...restProps}/>
        );
    }
}

export default Input;

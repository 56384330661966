import React from 'react';

export default ({color}) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 26'
        >
            <path
                fill={color || '#6D6F7B'}
                fillRule='evenodd'
                d='M10.25.14l.07.061 2.588 2.588c.246.245.266.63.061.9l-.061.07-2.588 2.588c-.268.268-.702.268-.97 0-.246-.246-.266-.631-.061-.9l.06-.07 1.418-1.417h-.017c-5.18 0-9.378 4.199-9.378 9.378s4.199 9.377 9.378 9.377c5.18 0 9.378-4.198 9.378-9.377 0-.38.307-.686.686-.686.379 0 .686.307.686.686 0 5.937-4.813 10.75-10.75 10.75S0 19.275 0 13.338C0 7.492 4.666 2.736 10.477 2.59l.29-.003L9.35 1.171c-.246-.245-.266-.63-.061-.9l.06-.07c.247-.246.632-.266.901-.061zm2.782 8.088l.007.104v10.55c0 .567-.6.867-1.111.65l-.095-.048-4.295-2.863H5.504c-.415 0-.703-.253-.748-.644l-.006-.11v-4.52c0-.415.253-.703.644-.748l.11-.006h2.034l4.295-2.864c.497-.354 1.127-.041 1.199.5zm2.418 2.139c1.68 1.679 1.732 4.418.158 6.232l-.158.173c-.226.301-.753.301-1.055 0-.301-.302-.301-.754 0-1.055 1.206-1.206 1.206-3.09 0-4.295-.301-.302-.301-.754 0-1.055.302-.302.754-.302 1.055 0z'
            />
        </svg>
    );
};

import React, {Component} from 'react';
import _ from 'lodash';
import {AppConsumer} from '../../contexts/App';
import {ConsultationConsumer} from '../../contexts/Consultation';
import {TemplateConsumer} from '../../contexts/Template';
import {DepartmentsConsumer} from '../../contexts/Departments';
import PaginationView from '../../components/TemplateScreen/PaginationView';
import PaginationChevrons from '../../components/TemplateScreen/PaginationChevrons';
import TemplateScreen from '../../components/TemplateScreen/TemplateScreen';
import './MiniWebsite.scss';
import CarouselComponent from '../../components/carouselComponent/CarouselComponent';
import Logo from '../../components/Logo/Logo';

class MiniWebsite extends Component {
    constructor(props) {
        super(props);
        this.resize = this.onResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize);
        if (this.props.template) {
            this.handleChangeScreenByIndex(this.getDefaultScreenIndex({}));
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.template !== this.props.template && this.props.template) {
            this.handleChangeScreenByIndex(this.getDefaultScreenIndex(prevProps));
        }
    }

    getDefaultScreenIndex(prevProps) {
        const {hash} = document.location;
        return !prevProps.template && hash
            ? parseInt(hash.substring(1))
            : this.props.miniWebsiteScreen;
    }

    onResize() {
        if (this.swipeableActions) {
            this.swipeableActions.updateHeight();
        }
    }

    hasWhitePaginationView() {
        const {template, miniWebsiteScreen} = this.props;
        return template.screens[miniWebsiteScreen].settings.type === 'text' && template.screens[miniWebsiteScreen].settings.options.image;
    }

    handleChangeScreenById = (id) => {
        if (id) {
            const newScreenIndex = this.props.template.screens.findIndex(el => el.id === id);
            if (newScreenIndex > -1) {
                const screenName = _.get(this, `props.template.screens[${newScreenIndex}].settings.name`);
                this.props.setMiniWebsiteScreen({
                    index: newScreenIndex,
                    screenId: id,
                    screenName
                });
            }
        }
    };

    handleChangeScreenByIndex = index => {
        if (!isNaN(index)) {
            const screen = this.props.template.screens[index];
            if (screen) {
                this.props.setMiniWebsiteScreen({
                    index,
                    screenId: screen.id,
                    screenName: _.get(screen, 'settings.name')
                });
            }
        }
    };

    handleGoToNext = () => {
        this.handleChangeScreenByIndex(this.props.miniWebsiteScreen + 1);
    };

    handleGoToPrevious = () => {
        this.handleChangeScreenByIndex(this.props.miniWebsiteScreen - 1);
    };

    getTemplateScreens() {
        const {
            template,
            ticketWasSentFromScreens,
            room,
            consultant,
            requestConsultationWithType,
            consultationRequestProgress,
            miniWebsiteScreen,
            departmentsState,
            requestPhoneConsultation,
            phoneConsultationOnPCRequested,
            removePhoneConsultationOnPC,
            requestChat,
            requestAIChat,
            qrChats,
            sendServiceNowTicket,
            isPdfViewerLoaded,
            requestConsultationWithDepartmentNumber,
            requestConsultationWithDepartmentNumberWithType,
            requestConsultationWithDepartmentId,
            qrIntercomNumberNotFound,
            removeQrIntercomNumberNotFoundState,
            onPhotoDone,
            setInWaitingCallState,
        } = this.props;
        const isQrChatEnabled = (
            _.get(room, 'roomInfo.qr_code_chat', false)
            && _.get(template, 'isQrChatEnabled', false)
            && !_.get(consultant, 'connectedWithMobileDevice', false)
        );
        const hasUnreadMessagesWithoutDepartments = qrChats.nodepartments && !qrChats.nodepartments.wasRead;
        const hasUnreadMessagesWithDepartments = !!departmentsState.departments.find(d => {
            return d.hasAvailableMembers && qrChats[d.id] && !qrChats[d.id].wasRead;
        });
        const hasUnreadMessages = departmentsState.departments.length
            ? hasUnreadMessagesWithDepartments
            : hasUnreadMessagesWithoutDepartments;
        const showChatWasNotReadIcon = isQrChatEnabled && hasUnreadMessages;

        return (
            template.screens.map((s, i) => {
                if (Math.abs(miniWebsiteScreen - i) > 1 || (miniWebsiteScreen !== i && _.get(s, 'settings.type', '') === 'file')) {
                    return null;
                }
                return (
                    <TemplateScreen
                        isQrChatEnabled={room.roomInfo.qr_code_chat && template.isQrChatEnabled}
                        key={s.id}
                        screen={s}
                        room={room}
                        onChangeScreenById={this.handleChangeScreenById}
                        isVideoConsultationEnabled={room.roomInfo.qr_mini_websites_video_call && template.isVideoConsultationEnabled}
                        isAudioConsultationEnabled={room.roomInfo.qr_mini_websites_audio_call && template.isAudioConsultationEnabled}
                        logo={template.logo}
                        requestPhoneConsultation={requestPhoneConsultation}
                        phoneConsultationOnPCRequested={phoneConsultationOnPCRequested}
                        removePhoneConsultationOnPC={removePhoneConsultationOnPC}
                        requestChat={requestChat}
                        requestAIChat={requestAIChat}
                        requestConsultationWithType={requestConsultationWithType}
                        requestConsultationWithDepartmentNumber={requestConsultationWithDepartmentNumber}
                        requestConsultationWithDepartmentNumberWithType={requestConsultationWithDepartmentNumberWithType}
                        requestConsultationWithDepartmentId={requestConsultationWithDepartmentId}
                        consultationRequestProgress={consultationRequestProgress}
                        departmentsState={departmentsState}
                        isCurrent={miniWebsiteScreen === i}
                        showChatWasNotReadIcon={showChatWasNotReadIcon}
                        miniWebsiteScreen={miniWebsiteScreen}
                        sendServiceNowTicket={sendServiceNowTicket}
                        ticketWasSent={ticketWasSentFromScreens.includes(i)}
                        onQrLogoClick={this.handleChangeScreenByIndex.bind(this, 0)}
                        isPdfViewerLoaded={isPdfViewerLoaded}
                        qrIntercomNumberNotFound={qrIntercomNumberNotFound}
                        removeQrIntercomNumberNotFoundState={removeQrIntercomNumberNotFoundState}
                        onPhotoDone={onPhotoDone}
                        setInWaitingCallState={setInWaitingCallState}
                    />
                )
            })
        )
    }

    render() {
        const {template, miniWebsiteScreen, room} = this.props;
        if (!template) {
            return null;
        }
        if (template.screens.length === 0) {
            return <div className="mw-no-screens">There are no screens yet...</div>;
        }
        return (
            <div className="mw-swipe-container">
                <CarouselComponent activeIndex={miniWebsiteScreen}
                                   onSwipedLeft={this.handleGoToNext}
                                   onSwipedRight={this.handleGoToPrevious}
                                   count={template.screens}>
                    {this.getTemplateScreens()}
                </CarouselComponent>
                <PaginationView index={miniWebsiteScreen}
                                count={template.screens.length}
                                white={this.hasWhitePaginationView()}
                                onChangeIndex={this.handleChangeScreenByIndex}/>
                <PaginationChevrons onPrev={this.handleGoToPrevious}
                                    onNext={this.handleGoToNext}
                                    showPrev={miniWebsiteScreen > 0}
                                    showNext={miniWebsiteScreen < template.screens.length - 1}/>
                <Logo room={room} position='bottomleft' />
            </div>
        );
    }
}

export default props => (
    <ConsultationConsumer>
        {({state: consultationState, actions: consultationAction}) => (
            <DepartmentsConsumer>
                {({state: departmentsState}) => (
                    <TemplateConsumer>
                        {({state, actions}) => <MiniWebsite
                            requestConsultationWithType={consultationAction.requestConsultationWithType}
                            requestChat={consultationAction.requestChat}
                            requestAIChat={consultationAction.requestAIChat}
                            qrChats={consultationState.qrCodeChats}
                            requestPhoneConsultation={consultationAction.requestPhoneConsultation}
                            removePhoneConsultationOnPC={consultationAction.removePhoneConsultationOnPC}
                            consultationRequestProgress={consultationState.consultationRequestProgress}
                            phoneConsultationOnPCRequested={consultationState.phoneConsultationOnPCRequested}
                            template={state.template}
                            isPdfViewerLoaded={state.isPdfViewerLoaded}
                            ticketWasSentFromScreens={state.ticketWasSentFromScreens}
                            consultant={consultationState.consultant}
                            departmentsState={departmentsState}
                            sendServiceNowTicket={actions.sendServiceNowTicket}
                            requestConsultationWithDepartmentNumber={consultationAction.requestConsultationWithDepartmentNumber}
                            requestConsultationWithDepartmentNumberWithType={consultationAction.requestConsultationWithDepartmentNumberWithType}
                            onPhotoDone={consultationAction.onPhotoDone}
                            setInWaitingCallState={consultationAction.setInWaitingCallState}
                            requestConsultationWithDepartmentId={consultationAction.requestConsultationWithDepartmentId}
                            qrIntercomNumberNotFound={consultationState.qrIntercomNumberNotFound}
                            removeQrIntercomNumberNotFoundState={consultationAction.removeQrIntercomNumberNotFoundState}
                            {...props}/>}
                    </TemplateConsumer>
                )}
            </DepartmentsConsumer>
        )}
    </ConsultationConsumer>
);

import React, {Component} from 'react';
import './Spinner.scss';

class Connecting extends Component {
    render() {
        return (
            <div className="spinnerContainer">
                <div className="spinner"/>
                {this.props.children}
            </div>
        );
    }

}

export default Connecting;
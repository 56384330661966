import React, {Component} from 'react';
import classnames from 'classnames';
import './PaginationView.scss';

class PaginationView extends Component {
    render() {
        const {index, count, white, onChangeIndex} = this.props;
        return (
            <div className="mw-pagination-view">
                {[...Array(count).keys()].map(i => <div key={i}
                                                        onClick={onChangeIndex.bind(null, i)}
                                                        className={classnames("mw-pagination-circle", white && 'white', i === index && 'selected')}/>)}
            </div>
        );
    }
}

export default PaginationView;
const constrainsDefault = {
    audio: true,
    video: {height: {ideal: 720}},
};

class MediaDevice {
    constructor() {
        this.devices = {audio: null, video: null};
        this.audioContext = null;
        this.audio = null;
        this.canPlayThroughHandler = this._canPlayThroughHandler.bind(this);
    }

    _canPlayThroughHandler() {
        this.audio.removeEventListener('canplay', this.canPlayThroughHandler);
        this.audio = null;
    }

    connectToSpeaker(remoteAudioStream, gain) {
        if (!this.audioContext) {
            this.audioContext = 
                new (window.AudioContext || window.webkitAudioContext)();
        }
        this.audio = new Audio();
        this.audio.muted = true;
        this.audio.autoplay = true;
        this.audio.srcObject = remoteAudioStream;
        this.audio.addEventListener('canplay', this.canPlayThroughHandler);
        const audioNode = this.audioContext.createMediaStreamSource(remoteAudioStream);
        const gainNode = this.audioContext.createGain();
        gainNode.gain.value = gain;
        audioNode.connect(gainNode);
        gainNode.connect(this.audioContext.destination);
    }

    getUserMedia(facingMode) {
        if (!this.audioContext) {
            this.audioContext = 
                new (window.AudioContext || window.webkitAudioContext)();
        }
        const constrains = {
            audio: {deviceId: this.devices.audio},
            video: {
                facingMode: {exact: facingMode},
                height: {min: 720},
                deviceId: this.devices.video,
            }
        };

        return this.tryToGetMedia(constrains);
    }

    tryToGetMedia(constrains) {
        return new Promise((resolve, reject) => {
            this.gum(constrains)
                .then(resolve)
                .catch((err) => {
                    console.log(err);
                    this.gum(constrainsDefault).then(resolve).catch(reject)
                });
        });
    }

    gum(constrains) {
        return navigator.mediaDevices.getUserMedia(constrains);
    }

    setDevices(devices) {
        this.devices = devices;
    }
}

export default new MediaDevice();

import PropTypes from 'prop-types';
import React, {Component} from 'react';
import './CarouselComponent.module.scss';
import classnames from 'classnames';
import { Swipeable } from '../hocs/Swipeable';


export default class CarouselComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cur: 0
        };
    }

    componentDidMount() {
        if (this.props.activeIndex) {
            this.changeActiveScreen();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.activeIndex !== this.props.activeIndex) {
            this.changeActiveScreen();
        }
    }

    changeActiveScreen() {
        this.setState(prevState => ({
            prev: prevState.cur,
            cur: this.props.activeIndex,
            isBackWard: this.props.activeIndex < prevState.cur,
        }));
    }

    render() {
        const {children, onSwipedLeft, onSwipedRight} = this.props;
        const {isBackWard, cur, prev} = this.state;
        return (
            <Swipeable onSwipedLeft={onSwipedLeft} onSwipedRight={onSwipedRight} className={'carousel-container'}>
                {children.map((el, i) => {
                    const className = classnames(
                        {['carousel-activeCard']: i === cur},
                        {['carousel-no-animation']: isNaN(prev)},
                        {['carousel-previousCard']: i === prev},
                        {['carousel-reverse']: !isBackWard}
                    );
                    return <div key={i} className={className}>{el}</div>;
                })}
            </Swipeable>
        );
    }
}

CarouselComponent.propTypes = {
    activeIndex: PropTypes.number,
    onSwipedLeft: PropTypes.func,
    onSwipedRight: PropTypes.func
};

CarouselComponent.defaultProps = {
    onSwipedLeft: f => f,
    onSwipedRight: f => f
};

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Logo from '../../components/Logo/Logo';
import {ConsultationConsumer} from '../../contexts/Consultation';
import {TemplateConsumer} from '../../contexts/Template';
import TextArea from '../../components/TextArea/TextArea';
import Button from '../../components/Button/Button';
import SadActive from '../../assets/icons/SadActive';
import SadInactive from '../../assets/icons/SadInactive';
import NeutralActive from '../../assets/icons/NeutralActive';
import NeutralInactive from '../../assets/icons/NeutralInactive';
import HappyActive from '../../assets/icons/HappyActive';
import HappyInactive from '../../assets/icons/HappyInactive';
import RedirectTimeout from '../../components/RedirectTimeout/RedirectTimeout';
import _ from 'lodash';
import './SurveyRateChart.scss';

const EMOTIONS = [
    {value: 0, activeIcon: <SadActive />, inactiveIcon: <SadInactive />},
    {
        value: 1,
        activeIcon: <NeutralActive />,
        inactiveIcon: <NeutralInactive />,
    },
    {value: 2, activeIcon: <HappyActive />, inactiveIcon: <HappyInactive />},
];

class EmotionIcon extends Component {
    render() {
        const {activeIcon, inactiveIcon, isActive, ...rest} = this.props;

        return <div {...rest}>{isActive ? activeIcon : inactiveIcon}</div>;
    }
}

class SurveyRateChart extends Component {
    static propTypes = {
        room: PropTypes.object.isRequired,
        sendFeedback: PropTypes.func.isRequired,
    };

    state = {
        step: 'emotion',
        choosedEmotion: null,
        emotionTitleState: 'initial',
    };

    chooseEmotion(choosedEmotion) {
        this.setState({choosedEmotion, emotionTitleState: 'hidden'}, () => {
            setTimeout(
                () => this.setState({emotionTitleState: 'thankYou'}),
                700
            );
            setTimeout(() => this.setState({step: 'message'}), 2700);
            this.redirectTimeout.setRedirectTimeout();
        });
    }

    onSendFeedback() {
        const data = this.getFeedbackData();
        this.sendFeedback(data);
    }

    onCancelFeedback() {
        this.sendFeedback(this.getFeedbackData());
    }

    getFeedbackData() {
        const {choosedEmotion} = this.state;
        const {messageInput} = this.refs;
        return {rating: choosedEmotion.value, comment: messageInput.value};
    }

    sendFeedback(data) {
        this.props.sendFeedback(data);
    }

    renderEmotionChoosing() {
        const {screenRateConsultationMessage} = this.props;
        const {choosedEmotion, emotionTitleState} = this.state;

        const emotionContainerClassName = classnames(
            'emotionContainer',
            choosedEmotion && 'active'
        );
        const titleClassName = classnames('emotionTitle', emotionTitleState);

        return (
            <div className='emotionStep'>
                <div className={titleClassName}>
                    {emotionTitleState === 'thankYou'
                        ? 'Thank you!'
                        : screenRateConsultationMessage}
                </div>
                <div className={emotionContainerClassName}>
                    {EMOTIONS.map((emotion, i) => {
                        const isActive =
                            choosedEmotion &&
                            choosedEmotion.value === emotion.value;
                        return (
                            <EmotionIcon
                                key={i}
                                isActive={isActive}
                                className={classnames(
                                    choosedEmotion && isActive && 'activeIcon',
                                    choosedEmotion &&
                                        !isActive &&
                                        'inactiveIcon'
                                )}
                                activeIcon={emotion.activeIcon}
                                inactiveIcon={emotion.inactiveIcon}
                                onClick={this.chooseEmotion.bind(this, emotion)}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }

    renderMessageInput() {
        const {room} = this.props;
        const title = 'Any further comments or feedback?';
        // This option is not editable in QR codes for now
        // const title = room && room.screenSettings.screenFeedbackCommentTitle;

        return (
            <div className='messageStep'>
                <div className='messageTitle'>{title}</div>
                <TextArea
                    ref='messageInput'
                    placeholder='Type your message...'
                    maxLength={1000}
                />
                <div className='buttonContainer'>
                    <Button
                        type='primary'
                        onClick={this.onSendFeedback.bind(this)}
                    >
                        Send
                    </Button>
                    <Button
                        type='default'
                        onClick={this.onCancelFeedback.bind(this)}
                    >
                        No, thanks
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        const {room, stopConsultation} = this.props;
        const {step} = this.state;

        return (
            <div className='surveyRateChartContainer contentContainer'>
                {step === 'emotion' && this.renderEmotionChoosing()}
                {step === 'message' && this.renderMessageInput()}

                <RedirectTimeout
                    onRedirect={stopConsultation}
                    ref={ref => (this.redirectTimeout = ref)}
                />
                <Logo room={room} position='bottomleft' withLink />
            </div>
        );
    }
}

export default props => (
    <TemplateConsumer>
        {({state: templateState, actions: templateActions}) => (
            <ConsultationConsumer>
                {({state, actions}) => (
                    <SurveyRateChart
                        {...state}
                        {...actions}
                        {...props}
                        screenRateConsultationMessage={_.get(
                            templateState,
                            'template.screenRateConsultationMessage',
                            'Please rate the consultation quality.'
                        )}
                    />
                )}
            </ConsultationConsumer>
        )}
    </TemplateConsumer>
);

import React from 'react';
import classnames from 'classnames';
import PieChart from '../../components/PieChart/PieChart';

const QrChatAttachmentItem = props => {
    const {name, onItemDelete, isUploading, uploadingProgress} = props;
    const containerClassName = classnames('qr-chat-attachment-item-container', isUploading && 'uploading');
    return (
        <div className={containerClassName} title={name}>
            {isUploading && (
                <div className='qr-chat-attachments-upload-progress'>
                    <PieChart percentage={uploadingProgress}/>
                </div>
            )}
            {!isUploading && <div className='qr-chat-attachment-item-close' onClick={onItemDelete} />}
            <div className='qr-chat-attachment-item-name'>{name}</div>
        </div>
    );
};

export default QrChatAttachmentItem;

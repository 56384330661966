import React from 'react';

export default (props) => {
    return (
        <svg viewBox="0 0 44 45" {...props}>
            <defs>
                <path id="a" d="M18.628 21.499H42v19.229H18.628z"/>
            </defs>
            <g fill="none">
                <g transform="translate(-8.842 -8)">
                    <use fill="#FFF" xlinkHref="#a"/>
                    <path stroke="#979797" d="M19.128 21.999H41.5v18.229H19.128z"/>
                </g>
                <path fill="#EC6969"
                      d="M5.042 9.314C12.5-.23 26.175-2.006 35.59 5.35c9.414 7.355 11 21.054 3.544 30.598C31.676 45.492 18 47.267 8.586 39.912c-9.414-7.355-11-21.054-3.544-30.598zM15.096 20.37c-2.371 1.181-1.287 5.602-1.287 5.602l4.578-.731s-.437-2.593.278-3.013c0 0 2.629-1.079 6.857-.125.813.195.295 3.02.295 3.02l4.59.552s.828-4.15-1.017-5.248c-1.875-1.123-7.56-3.362-14.294-.057z"/>
            </g>
        </svg>
    );
}
import React from 'react';

export default (props) => {
    const {color} = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" {...props}>
            <g fill="none" fillRule="evenodd">
                <g>
                    <circle cx="20" cy="20" r="20" fill={color} />
                    <path fill="#FFF" d="M24.813 18.197v-3.04a.871.871 0 0 0-.869-.868h-10.42a.871.871 0 0 0-.87.869v8.684c0 .478.392.869.87.869h10.42c.478 0 .869-.391.869-.869v-3.04l3.473 3.474v-9.552l-3.473 3.473z" />
                </g>
            </g>
        </svg>
    );
}

export class ChatGPTHistory {
    constructor() {
        this.history = [];
    }

    add(q, a) {
        this.history.push({
            q,
            a,
        })
    }

    clear() {
        this.history = [];
    }

    getFormatedString(cutHistory) {
        if (cutHistory) {
            let cutedHistory = '';
            while (cutedHistory.length <= cutHistory) {
                const h = this.history.shift()
                if (h) {
                    cutedHistory += `Q: ${h.q}\nA:${h.a}\n`
                } else {
                    break;
                }
            }
        }
        return this.history.map(({q, a}) => `Q: ${q}\nA:${a}`).join('\n')
    }
}

//Phone format example: RU (+7)1111111111

export function getCountryCodeWithCountry(phoneString) {
    const rightBraceIndex = phoneString.indexOf(')');
    return phoneString.substr(0, rightBraceIndex + 1);
}

export function getCountryCodeWithoutCountry(phoneString) {
    const rightBraceIndex = phoneString.indexOf(')');
    return phoneString.substr(0, rightBraceIndex + 1).split(' ').filter(el => el)[1];
}

export function getPhoneWithoutCode(phoneString) {
    const rightBraceIndex = phoneString.indexOf(')');
    return phoneString.substr(rightBraceIndex + 1, phoneString.length);
}

export function getPhoneString(countryCode, phone) {
    return `${countryCode}${phone}`
}

import React, {Component} from 'react';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.i18n.js';

import './Input.scss';

class FormattedInput extends Component {
    get value() {
        return this.input.value;
    }

    render() {
        return <Cleave htmlRef={ref => this.input = ref} className="webscreenInput" {...this.props}/>;
    }
}

export default FormattedInput;
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import logoImage from '../../assets/logo.svg';
import ScreenHeader from './ScreenHeader';
import PinchZoom from './PinchZoom';
import './FileScreen.scss';
import classnames from 'classnames';

class FileScreen extends Component {
    componentDidMount() {
        const {isOnPreview} = this.props;
        if (this.pinchzoom && !isOnPreview) {
            this.pz = new PinchZoom(ReactDOM.findDOMNode(this.pinchzoom), {});
        }
        this.updateFileUrl();

        if (this.props.isPdfViewerLoaded) {
            this.renderPdf();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.didPdfViewerLoaded(prevProps, this.props) || this.didPreviewFileChanged(prevProps, this.props)) {
            this.renderPdf();
        }
    }

    didPdfViewerLoaded(prevProps, nextProps) {
        return !prevProps.isPdfViewerLoaded && nextProps.isPdfViewerLoaded
    }

    didPreviewFileChanged(prevProps, nextProps) {
        const prevFileName = _.get(prevProps, 'screen.settings.options.file.file.name', null);
        const nextFileName = _.get(nextProps, 'screen.settings.options.file.file.name', null);

        return prevFileName !== nextFileName;
    }

    get viewerId() {
        const {screen, isOnPreview} = this.props;
        return `pdf-viewer-${isOnPreview ? 'preview' : screen.id}`;
    }

    renderPdf() {
        if (!this.fileUrl || !this.fileName) return;

        const ext = this.fileName.split('.').slice(-1)[0];
        if (ext !== 'pdf') return;

        const adobeDCView = new AdobeDC.View({clientId: process.env.PDF_VIEWER_KEY, divId: this.viewerId});
        adobeDCView.previewFile({
            content: {location: {url: this.fileUrl}},
            metaData: {fileName: this.fileName}
        }, {showAnnotationTools: false, showLeftHandPanel: false, enableFormFilling: false});
    }

    updateFileUrl() {
        const {screen} = this.props;
        const file = screen.settings.options.file;
        if (!file || file.name === this.fileName) {
            return;
        }
        this.fileUrl = file && (file.url ? file.url : URL.createObjectURL(file.file));
        this.fileName = file.name;
    }

    render() {
        this.updateFileUrl();
        const {
            screen,
            room,
            isVideoConsultationEnabled,
            isAudioConsultationEnabled,
            requestConsultationWithType,
            requestPhoneConsultation,
            consultationRequestProgress,
            logo,
            departmentsState,
            requestChat,
            showChatWasNotReadIcon,
            isQrChatEnabled,
            onQrLogoClick,
            isCallButtonsExpanded,
            requestAIChat,
        } = this.props;
        const file = screen.settings.options.file;
        const ext = file && file.name.split('.').slice(-1)[0];
        const showTeleportivityLogo = room && room.roomInfo.powered_by_logo;
        const backgroundColor = screen.settings.backgroundColor;

        const uploadedLogoClassName = classnames('mw-uploaded-logo', isCallButtonsExpanded && 'withExpandedCallButtons');

        return (
            <div className="mw-screen-container mw-file-screen" style={{background: backgroundColor}}>
                <ScreenHeader
                    room={room}
                    screen={screen}
                    isVideoConsultationEnabled={isVideoConsultationEnabled}
                    isAudioConsultationEnabled={isAudioConsultationEnabled}
                    requestConsultationWithType={requestConsultationWithType}
                    requestChat={requestChat}
                    requestAIChat={requestAIChat}
                    requestPhoneConsultation={requestPhoneConsultation}
                    consultationRequestProgress={consultationRequestProgress}
                    departmentsState={departmentsState}
                    showChatWasNotReadIcon={showChatWasNotReadIcon}
                    isQrChatEnabled={isQrChatEnabled}
                    logo={logo}
                    isCallButtonsExpanded={isCallButtonsExpanded}
                />
                {file && <div className="mw-screen-file-container">
                    {ext === 'pdf'
                        ? <div id={this.viewerId}/>
                        : <div ref={ref => this.pinchzoom = ref}><img src={this.fileUrl}/></div>}
                </div>}
                {logo && <div onClick={onQrLogoClick} className={uploadedLogoClassName}><img src={logo.url}/></div>}
            </div>
        );
    }
}

export default FileScreen;

import {
    AudioConfig,
    SpeechConfig,
    SpeechTranslationConfig,
    SpeechRecognizer,
    TranslationRecognizer,
} from 'microsoft-cognitiveservices-speech-sdk';

class AzureSpeechRecognizer {
    constructor() {
        this.speechRecognizer = null;
        this.translationRecognizer = null;
        this.stopPromise = null;
        this.envs = {
            AU: {
                key: process.env.AZURE_KEY_AU,
                region: process.env.AZURE_REGION_AU,
            },
            US: {
                key: process.env.AZURE_KEY_US,
                region: process.env.AZURE_REGION_US,
            },
            EU: {
                key: process.env.AZURE_KEY_EU,
                region: process.env.AZURE_REGION_EU,
            },
        };
    }

    createAudioConfig(stream) {
        if (stream) {
            return AudioConfig.fromStreamInput(stream);
        }
        return AudioConfig.fromDefaultMicrophoneInput();
    }

    createSpeechConfig(env) {
        return SpeechConfig.fromSubscription(this.envs[env].key, this.envs[env].region);
    }

    createTranslationConfig(env, fromLanguage, toLanguage) {
        const config = SpeechTranslationConfig.fromSubscription(this.envs[env].key, this.envs[env].region);
        config.speechRecognitionLanguage = fromLanguage;
        config.addTargetLanguage(toLanguage);

        return config;
    }

    startSpeechRecognition(env, onStart, onRecognize, onError) {
        if (this.stopPromise) {
            this.stopPromise.then(() => {
                this.stopPromise = null;
                this.startSpeechRecognition(env, onStart, onRecognize, onError);
            });
            return;
        } else if (!this.speechRecognizer) {
            this.speechRecognizer = new SpeechRecognizer(
                this.createSpeechConfig(env),
                this.createAudioConfig()
            );
        }        

        const onRecognitionStart = () => {
            console.log('recognition started');
            onStart();
        };

        this.speechRecognizer.startContinuousRecognitionAsync(onRecognitionStart, onError);
        this.speechRecognizer.recognizing = (r, e) => {
            onRecognize(e.result.text);
        };
        this.speechRecognizer.recognized = (r, e) => {
            onRecognize(e.result.text);
        };
    }

    stopSpeechRecognition(onStop) {
        if (!this.speechRecognizer) {
            if (onStop) onStop(false);
            return;
        }

        this.stopPromise = new Promise(res => {
            const onRecognitionStop = () => {
                console.log('recognition stopped');
                this.speechRecognizer.close(() => {
                    this.speechRecognizer = null;
                    res();
                    this.stopPromise = null;
                    if (onStop) onStop(true);
                });
            };
            const onError = e => console.error(e);
            this.speechRecognizer.stopContinuousRecognitionAsync(onRecognitionStop, onError);
        });
    }

    startTranslationRecognition(env, onStart, onRecognize, onError, fromLanguage, toLanguage, stream) {
        const [toLanguageFirstPart] = toLanguage.split('-');  // Result will be stored in 'en' key instead of en-US, for example.
        if (this.stopPromise) {
            this.stopPromise.then(() => {
                this.stopPromise = null;
                this.startTranslationRecognition(env, onStart, onRecognize, onError, fromLanguage, toLanguage, stream);
            });
            return;
        } else if (!this.translationRecognizer) {
            this.translationRecognizer = new TranslationRecognizer(
                this.createTranslationConfig(env, fromLanguage, toLanguage),
                this.createAudioConfig(stream)
            );
        }

        const onRecognitionStart = () => {
            console.log('recognition started');
            onStart();
        };

        this.translationRecognizer.recognized = (r, e) => {
            let text = e.result.translations.get(toLanguageFirstPart);
            if (text) {
                onRecognize(e.result.offset, text);
                return;
            }
            const key = e.result.translations.privMap.privKeys.find(k => k.startsWith(toLanguageFirstPart));
            if (!key) {
                return;
            }
            text = e.result.translations.get(key);
            if (text) {
                onRecognize(e.result.offset, text);
            }
        };

        // Azure sometimes cancels recognition with "No error" error. Probably it's fixed by selecting closest to
        // client Azure server
        // this.translationRecognizer.canceled = (r, e) => {
        //     console.log('recognition failed, restarting');
        //     console.log(e);
        //
        //     this.stopTranslationRecognition(() => {
        //         this.startTranslationRecognition(onStart, onRecognize, onError, fromLanguage, toLanguage);
        //     });
        // };

        this.translationRecognizer.startContinuousRecognitionAsync(onRecognitionStart, onError);
    }

    stopTranslationRecognition(onStop) {
        if (!this.translationRecognizer) {
            if (onStop) onStop(false);
            return;
        }

        this.stopPromise = new Promise(res => {
            const onRecognitionStop = () => {
                console.log('recognition stopped');
                this.translationRecognizer.close(() => {
                    this.translationRecognizer = null;
                    res();
                    this.stopPromise = null;
                    if (onStop) onStop(true);
                });
            };
            const onError = e => console.error(e);
            this.translationRecognizer.stopContinuousRecognitionAsync(onRecognitionStop, onError);
        });
    }

    stopAll(onStopSpeech, onStopTranslation) {
        if (this.speechRecognizer) {
            this.stopSpeechRecognition(onStopSpeech);
        }

        if (this.translationRecognizer) {
            this.stopTranslationRecognition(onStopTranslation);
        }
    }
}

export default new AzureSpeechRecognizer();

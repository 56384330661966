import io from 'socket.io-client';
import ss from 'socket.io-stream';

class IOController {
    connect() {
        return new Promise(resolve => {
            const url = process.env.SERVER_URL;
            this.socket = io(url, {
                transports: ['websocket'],
                query: {
                    ssid: this.getSSID()
                }
            });
            this.socket.on('connect', resolve);
            this.sendStream = (event, data, cb) => {
                ss(this.socket).emit(event, data, cb);
            };
        });
    }

    fakeConnect() {
        this.socket = new Proxy(io, {
            get: (target, propKey, receiver) => {
                return function (...args) {
                    
                };
            }
        })
    }

    sendFile = (event, file, data, cb) => {
        const stream = ss.createStream();
        ss(this.socket).emit(event, stream, data, cb);
        ss.createBlobReadStream(file).pipe(stream);
    };

    getSSID() {
        const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        return new Date().getTime() + S4() + S4();
    }
}

export default new IOController();


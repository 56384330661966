import React from 'react';
import PropTypes from 'prop-types';

const size = 100;
const radCircumference = Math.PI * 2;
const center = size / 2;
const radius = center - 1; // padding to prevent clipping
const DEFAULT_COLOR = '#5465e6';

/**
 * @param {number} percentage
 * @return {Object[]}
 */
function renderPie(percentage) {

    let radSegment = 0;
    let lastX = radius;
    let lastY = 0;


    if (percentage === 0) {
        return;
    }

    // Should the arc go the long way round?
    const longArc = (percentage <= 0.5) ? 0 : 1;

    radSegment += percentage * radCircumference;
    const nextX = Math.cos(radSegment) * radius;
    const nextY = Math.sin(radSegment) * radius;

    // d is a string that describes the path of the slice.
    // The weirdly placed minus signs [eg, (-(lastY))] are due to the fact
    // that our calculations are for a graph with positive Y values going up,
    // but on the screen positive Y values go down.
    const d = [
        `M ${center},${center}`,
        `l ${lastX},${-lastY}`,
        `a${radius},${radius}`,
        '0',
        `${longArc},0`,
        `${nextX - lastX},${-(nextY - lastY)}`,
        'z'
    ].join(' ');

    lastX = nextX;
    lastY = nextY;

    return (
        <path d={d} fill={DEFAULT_COLOR}/>
    );
}

/**
 * Generates an SVG pie chart.
 * @see {http://wiki.scribus.net/canvas/Making_a_Pie_Chart}
 */
export default class PieChart extends React.Component {
    /**
     * @return {Object}
     */
    render() {
        const {percentage} = this.props;
        const width = size / 7;
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${size} ${size}`} height={200} width={200} style={{transform: 'scale(-1,1)'}}>
                <defs>
                    <mask id="hole">
                        <rect width="100%" height="100%" fill="white"/>
                        <circle r={size / 2 - width} cx={center} cy={center} fill="black"/>
                    </mask>
                    <mask id="biggerHole">
                        <rect width="100%" height="100%" fill="white"/>
                        <circle r={size / 2 - width / 2 - 1} cx={center} cy={center} fill="black"/>
                    </mask>
                </defs>
                <circle r={size / 2 - width / 2 + 1} cx={center} cy={center} fill="#4e4f6b" mask="url(#biggerHole)"/>
                <g transform={`rotate(-90 ${center} ${center})`} mask="url(#hole)">
                    {(percentage >= 1)
                        ? <circle r={radius} cx={center} cy={center} fill={DEFAULT_COLOR}/>
                        : renderPie(this.props.percentage)}
                </g>
            </svg>
        );
    }
}

PieChart.propTypes = {
    percentage: PropTypes.number.isRequired
};

const uuidv4 = require('uuid').v4;

class ConsultationDataChannel {
    constructor(peerConnection, options) {
        if (!peerConnection) {
            return console.log('Please provide PeerConnection to ConsultationDataChannel');
        }

        this.snapshotBuffer = null;
        this.drawingBuffer = null;
        this.options = options;
        this.onDataChannelCreatedCB = options.onDataChannelCreatedCB;
        peerConnection.ondatachannel = this.onDataChannelCreated.bind(this);
    }

    onDataChannelCreated(event) {
        this.dataChannel = event.channel;
        this.dataChannel.onerror = error => console.log(error);
        this.dataChannel.onmessage = this.onDataChannelMessage.bind(this);
        if (this.onDataChannelCreatedCB) {
            setTimeout(this.onDataChannelCreatedCB, 0);
        }
    }

    onDataChannelMessage(event) {
        try {
            const eventData = JSON.parse(event.data);
            const eventName = eventData.event;
            switch (eventName) {
                case 'videoState':
                    if (this.options.onChangeVideoState) this.options.onChangeVideoState(eventData.video);
                    break;
                case 'snapshot_create_info':
                    this.options.onReceiveCreateInfo(eventData.info);
                    break;
                case 'snapshot_create':
                    this.handleChunkReceive(eventData, 'snapshotBuffer');
                    break;
                case 'snapshot_draw_update':
                    this.handleChunkReceive(eventData, 'drawingBuffer');
                    break;
                case 'snapshot_close':
                    this.options.onSnapshotClose();
                    break;
                case 'ping':
                    this.dataChannel.send(event.data);
                    break;
                default:
            }
        } catch (err) {
            console.log(err.message);
        }
    }

    handleChunkReceive({event, id, isStart, index, chunk, count}, bufferName) {
        if (!id) {
            console.error('id is required');
        }
        if (isStart) {
            this[bufferName] = {
                id,
                chunks: Array(count)
            };

            return;
        }

        if (chunk) {
            if (!this[bufferName] || this[bufferName].id !== id || isNaN(index)) {
                return;
            }

            this[bufferName].chunks[index] = chunk;

            const isLastChunk = !this[bufferName].chunks.includes(undefined);
            if (isLastChunk) {
                switch (event) {
                    case 'snapshot_create':
                        console.log('snap')
                        this.options.onSnapshotCreated({event, dataURL: this[bufferName].chunks.join('')});
                        break;
                    case 'snapshot_draw_update':
                        this.options.onDrawUpdate({event, dataURL: this[bufferName].chunks.join('')});
                        break;
                }
               this[bufferName] = null;
            }
        }
    }

    send(data) {
        try {
            if (!this.dataChannel) return;

            this.dataChannel.send(JSON.stringify(data));
        } catch (err) {
            console.log(err);
        }
    }

    sendDataURLByChunks({event, dataURL}) {
        try {
            if (!this.dataChannel) {
                console.error('no dataChannel');
                return;
            }

            const id = Date.now();
            const charSlice = 10000;
            const metaDataChunk = {
                event,
                id,
                isStart: true,
                count: Math.ceil(dataURL.length / charSlice)
            };
            this.dataChannel.send(JSON.stringify(metaDataChunk));

            let [dataSent, slideEndIndex, index] = [0, 0, 0];
            while (dataSent + 1 < dataURL.length) {
                slideEndIndex = Math.min(dataSent + charSlice, dataURL.length);

                const chunkData = {
                    chunk: dataURL.slice(dataSent, slideEndIndex),
                    event,
                    id,
                    index
                };

                this.dataChannel.send(JSON.stringify(chunkData));
                index++;
                dataSent = slideEndIndex;
            }
        } catch (err) {
            console.log(err);
        }
    }


    dataChannelIsClosing() {
        return ['closing', 'closed'].includes(this.dataChannel.readyState);
    }

    stop() {
        if (this.dataChannel && !this.dataChannelIsClosing()) {
            this.dataChannel.close();
        }
    }
}

export default ConsultationDataChannel;

import React, { useCallback } from 'react';
import { getBrowserOs } from '../utils/browserFeatures';

export default function useCamera({
    width,
    height,
    onPhotoDone,
    imageQuality,
}) {
    const makePhoto = useCallback(async (cb = () => {}) => {
        const video = document.createElement('video');
        const canvas = document.createElement('canvas');
        try {
            await new Promise(async (mainResolve) => {

                const isIos = getBrowserOs() === 'iOS';
                const videoConstraints = { width, maxHeight: height}
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: {
                        ...videoConstraints
                    },
                    audio: false,
                });
                video.addEventListener('canplay', async function onCanPlay(ev) {
                    const { width: photoWidth, height: photoHeight } = stream.getVideoTracks()[0].getSettings()

                    Object.entries({width: photoWidth, height: photoHeight})
                        .forEach(([key, value]) => {
                            canvas[key] = value;
                        })

                    video.removeEventListener('canplay', onCanPlay);
                    await new Promise(r => setTimeout(r, 250));
                    const context = canvas.getContext('2d');
                    context.drawImage(video, 0, 0, photoWidth, photoHeight);
                    cb();
                    stream.getVideoTracks().forEach((t) => t.stop());
                    video.pause();
                    await new Promise((resolve) => {
                        canvas.toBlob(
                            async (blob) => {
                                await onPhotoDone(blob);
                                resolve();
                            },
                            'image/png',
                            imageQuality ?? 0.95
                        );
                    });
                    mainResolve();
                });
                video.srcObject = stream;
                video.play();
            });
        } catch (err) {
            console.log(err);
        } finally {
            video.remove();
            console.log({canvas})
            canvas.remove();
        }
    }, [width, height, onPhotoDone, imageQuality]);
    return {
        makePhoto,
    };
}

import React from 'react';
import './RightArrow.scss';

export default function RightArrow() {
    return (
        <svg
            width='7'
            height='12'
            viewBox='0 0 7 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M4.84138 5.99998L0.964815 1.92959C0.78426 1.74 0.78387 1.43568 0.962396 1.24822C1.14217 1.05947 1.43145 1.06191 1.61131 1.25076L5.80871 5.65803L5.80992 5.6593L5.81113 5.66057C5.90061 5.75453 5.94498 5.87698 5.94479 5.99956C5.94453 6.12382 5.89999 6.24609 5.81113 6.33939L5.80992 6.34066L5.80871 6.34193L1.61131 10.7492C1.43076 10.9388 1.14092 10.9392 0.962396 10.7517C0.782625 10.563 0.784953 10.2592 0.964815 10.0704L4.84138 5.99998Z'
                fill='white'
                stroke='white'
            />
        </svg>
    );
}

import React, {Component} from 'react';
import classnames from 'classnames';
import logoimage from '../../assets/logo.svg';
import './Logo.scss';

class Logo extends Component {
    render() {
        const {room, position, withLink} = this.props;

        const poweredByLogo = room && room.roomInfo.powered_by_logo;
        const logoClassName = classnames('pageLogo', position, !poweredByLogo && 'hidden');
        return (
            <div className={logoClassName}>
                {withLink
                    ? <a href="https://teleportivity.com/" target="__blank" rel="noopener noreferrer"><img src={logoimage}/></a>
                    : <img src={logoimage}/>
                }
            </div>
        );
    }
}

export default Logo;
import React, {Component} from 'react';
import ChevronLeft from '../../assets/chevron-left.svg';
import ChevronLeftDesktop from '../../assets/chevron-left-desktop.svg';
import './PaginationChevrons.scss';

class PaginationChevrons extends Component {
    render() {
        const {onPrev, onNext, showPrev, showNext} = this.props;

        return (
            <React.Fragment>
                {showPrev && <img src={ChevronLeft} className="pagination-chevron prev mobile" onClick={onPrev}/>}
                {showNext && <img src={ChevronLeft} className="pagination-chevron next mobile" onClick={onNext}/>}

                {showPrev && <img src={ChevronLeftDesktop} className="pagination-chevron prev desktop" onClick={onPrev}/>}
                {showNext && <img src={ChevronLeftDesktop} className="pagination-chevron next desktop" onClick={onNext}/>}
            </React.Fragment>
        );
    }
}

export default PaginationChevrons;

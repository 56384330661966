import React from 'react';

export default props => {
    return (
        <svg viewBox="0 0 16 16" width="16" height="16" {...props}>
            <rect width="16" height="16" fill="#5465E6" rx="4"/>
            <path fill="#FFF"
                  d="M12.597 4.2l-4.732 5.94s-.274.686-.486.678c-.212-.008-.697-.678-.697-.678l-2.49-2.41a.703.703 0 0 0-.98 1.008L6.89 12.3c.008.008.017.013.025.02l.034.027a.707.707 0 0 0 .258.127c.012.004.024.009.037.011a.71.71 0 0 0 .622-.178l5.703-7.11a.696.696 0 0 0 .022-.987.703.703 0 0 0-.993-.01z"/>
        </svg>
    );
};

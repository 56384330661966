import React from 'react';

export default props => (
    <svg width="58" height="75" viewBox="0 0 58 75" {...props}>
        <g fill="none" fillRule="evenodd" transform="translate(1 1)">
            <path
                d="M28 0c-9.531 0-17 7.908-17 18v6h34v-6C45 7.908 37.531 0 28 0zm21 24H7c-3.85 0-7 3.15-7 7v35c0 3.85 3.15 7 7 7h42c3.85 0 7-3.15 7-7V31c0-3.85-3.15-7-7-7z"
                fillRule="nonzero" stroke="#FFF" strokeWidth="2"/>
            <circle cx="28" cy="44" r="6" stroke="#FFF" strokeWidth="2"/>
            <path fill="#FFF" d="M27 50h2v11h-2z"/>
        </g>
    </svg>
);

import React, {Component} from 'react';
import {Scrollbars} from 'react-custom-scrollbars-2';
import onClickOutside from 'react-onclickoutside';
import './Dropdown.scss';


class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opened: false,
            selectedValue: this.props.defaultValue || null,
            filter: ''
        };
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (!this.state.selectedValue && !this.props.defaultValue && newProps.defaultValue) {
            this.setState({selectedValue: newProps.defaultValue});
        }
    }

    handleClickOutside() {
        if (this.state.opened) {
            this.toggleOpened();
        }
    }

    get value() {
        return this.state.selectedValue.value;
    }

    toggleOpened() {
        if (this.state.opened) {
            this.setState({filter: ''});
        }
        this.setState({opened: !this.state.opened});
    }

    handleSelectItem(value) {
        const {onChange} = this.props;
        this.setState({selectedValue: value});
        if (onChange) onChange(value);
    }

    handleChangeFilter = (e) => {
        this.setState({filter: e.target.value || ''});
    };

    renderDropdownContent() {
        const {data, withSearch} = this.props;
        const {filter} = this.state;

        return (
            <div className="dropdownContent">
                {withSearch && <input onClick={e => e.stopPropagation()} onChange={this.handleChangeFilter}/>}
                <div>
                    <Scrollbars autoHeight autoHeightMax="17rem">
                        {data
                            .filter(t => t.label && t.label.toLowerCase().includes(filter.toLowerCase()))
                            .map((d, i) => (
                                <div key={i}
                                     className="dropdownItem"
                                     onClick={this.handleSelectItem.bind(this, d)}>
                                    {d.label}
                                </div>
                            ))}
                    </Scrollbars>
                </div>
            </div>
        );
    }

    render() {
        const {opened, selectedValue} = this.state;

        return (
            <div className="dropdownContainer" onClick={this.toggleOpened.bind(this)}>
                {selectedValue.label || ''}
                {opened && this.renderDropdownContent()}
            </div>
        );
    }
}

export default onClickOutside(Dropdown);

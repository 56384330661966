import React, {useEffect, useRef, useState} from 'react';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import MediaDevice from '../../models/MediaDevice';
import DeviceSelect from '../DeviceSelect/DeviceSelect';
import './DevicesModal.scss';

function DevicesModal({modalsState, closeDevicesModal}) {
    const videoRef = useRef(null);
    const [gotDevices, setGotDevices] = useState(false);
    const [devices, setDevices] = useState([]);
    const [selectedDevices, setSelectedDevices] = useState({audio: null, video: null});
    const [stream, setStream] = useState(null);
    const [isVideoLoaded, setVideoLoaded] = useState(false);
    const {isOpened} = modalsState.devices;

    function getDevices() {
        return MediaDevice.tryToGetMedia({audio: true, video: true}).then(tempStream => {
            tempStream.getTracks().map(track => track.stop());
            navigator.mediaDevices.enumerateDevices().then(rawDevices => {
                const defaultAudio = rawDevices.find(d => d.kind === 'audioinput');
                const defaultVideo = rawDevices.find(d => d.kind === 'videoinput');
                setSelectedDevices({
                    audio: defaultAudio ? defaultAudio.deviceId : null,
                    video: defaultVideo ? defaultVideo.deviceId : null,
                });
                setDevices(rawDevices);
            });
        });
    }

    function closeStream(streamToClose) {
        streamToClose.getTracks().map(track => track.stop());
    }

    function closeCurrentStream() {
        if (stream) {
            closeStream(stream);
        }
    }

    function updateStream(newStream) {
        setStream(newStream);
        videoRef.current.oncanplay = () => setVideoLoaded(true);
        videoRef.current.srcObject = newStream;
    }

    useEffect(() => {
        if (isOpened) {
            getDevices()
                .then(() => setGotDevices(true))
                .catch(e => {
                    console.log(e);
                    setGotDevices(false);
                });
        } else {
            closeCurrentStream();
        }

        return closeCurrentStream;
    }, [isOpened]);

    useEffect(() => {
        const hasAtLeastOneComponent = !!Object.values(selectedDevices).find(deviceId => !!deviceId);
        if (!gotDevices || !hasAtLeastOneComponent) return;

        closeCurrentStream();
        const constraints = {
            audio: selectedDevices.audio ? {deviceId: selectedDevices.audio} : false,
            video: selectedDevices.video ? {deviceId: selectedDevices.video} : false,
        };
        setVideoLoaded(false);
        MediaDevice.tryToGetMedia(constraints)
            .then(newStream => {
                if (!modalsState.devices.isOpened) {
                    closeStream(newStream);
                    return;
                }

                MediaDevice.setDevices(constraints);
                updateStream(newStream);
            })
            .catch(e => console.log(e));
    }, [gotDevices, selectedDevices]);

    function renderContent() {
        if (!gotDevices) {
            return (
                <div className="noMediaDevicesAccessMessage">
                    No access to media devices. Please, make sure you have given access to your microphone and camera
                </div>
            );
        }

        return (
            <div>
                <div className="mediaDevicesContainer">
                    <DeviceSelect
                        mediaDevices={devices}
                        deviceType="videoinput"
                        selected={selectedDevices.video}
                        onSelect={deviceId => setSelectedDevices(prev => ({...prev, video: deviceId}))}
                    />
                    <DeviceSelect
                        mediaDevices={devices}
                        deviceType="audioinput"
                        selected={selectedDevices.audio}
                        onSelect={deviceId => setSelectedDevices(prev => ({...prev, audio: deviceId}))}
                    />
                </div>
                <div className="devicesCheckVideoContainer">
                    <video ref={videoRef} className="devicesCheckVideo" autoPlay playsInline/>
                </div>
            </div>
        );
    }

    const confirmButtonClassName = (!gotDevices || !isVideoLoaded) && 'disabledButton';

    return (
        <Modal
            title="Checking your Mic and Camera"
            onHide={closeDevicesModal}
            show={isOpened}
            contentClassName="consultation-modal-content"
            withoutScroll
            closable
        >
            {renderContent()}
            <Button onClick={modalsState.onConfirm} type="primary" className={confirmButtonClassName}>
                Confirm
            </Button>
        </Modal>
    );
}

export default DevicesModal;

import 'webrtc-adapter';
import IOController from '../controllers/IOController';
import MediaDevice from './MediaDevice';
import ConsultationDataChannel from './ConsultationDataChannel';

function getSafariVersion(userAgent) {
    try {
        const userAgentSplited = userAgent.split(' ');
        const versionString = userAgentSplited.find(s => s.toLowerCase().startsWith('version'));
        const [_, version] = versionString.split('/');
        return Number.parseFloat(version);
    } catch(err) {
        console.log(err);
        return null;
    }
}

class VideoChat {
    constructor(data) {
        this.forceTurnUsage = data.forceTurnUsage;
        this.offer = data.offer;
        this.roomId = data.roomId;
        this.presenterId = data.presenterId;

        this.iceServers = data.iceServers;
        this.onMediaError = data.onMediaError;
        this.onGetMediaError = data.onGetMediaError;
        this.onGenerateCandidate = data.onGenerateCandidate;
        this.onGotSdpAnswer = data.onGotSdpAnswer;
        this.onGotLocalStream = data.onGotLocalStream;
        this.onGotRemoteStream = data.onGotRemoteStream;

        this.voiceEnabled = data.voiceEnabled;
        this.videoEnabled = data.videoEnabled;
        this.withoutVideoTrack = data.withoutVideoTrack;

        this.onSnapshotCreated = data.onSnapshotCreated;
        this.onDrawUpdate = data.onDrawUpdate;
        this.onSnapshotClose = data.onSnapshotClose;
        this.onReceiveCreateInfo = data.onReceiveCreateInfo;
        this.onReleaseLocalStream = data.onReleaseLocalStream;

        this.facingMode = data.facingMode || 'user';

        this.onVideoStateChange = data.onVideoStateChanged;
        const userAgent = window.navigator.userAgent
            .toLowerCase();
        this.isSafari = userAgent.includes('safari/') && !userAgent.includes('chrome/');
        this.safariVersion = this.isSafari ? getSafariVersion(userAgent) : null;
        this.shouldUseSafariHack = this.isSafari && this.safariVersion ? this.safariVersion < 15.4 : false;
        this.shouldUseSafariReupdateStream = this.isSafari && this.safariVersion ? this.safariVersion < 15.5 : false;
    }

    transformIceServers(iceServers) {
        for (let i = 0; i < iceServers.iceServers.length; i++) {
            if (iceServers.iceServers[i].url) {
                iceServers.iceServers[i].urls = iceServers.iceServers[i].url;
                delete iceServers.iceServers[i].url;
            }
        }
        return iceServers;
    }

    start() {
        console.log(this.transformIceServers(this.iceServers));
        this.peerConnection = new RTCPeerConnection({
            iceServers: this.transformIceServers(this.iceServers).iceServers,
        });

        const remoteStream = new MediaStream();
        const audioRemoteStream = this.shouldUseSafariHack ? new MediaStream() : null;

        this.peerConnection.ontrack = (ev) => {
            if (this.shouldUseSafariHack && ev.track.kind === 'audio') {
                audioRemoteStream.addTrack(ev.track);
            } else {
                remoteStream.addTrack(ev.track);
                this.onGotRemoteStream(remoteStream);
            }
        }

        this.peerConnection.addEventListener('connectionstatechange', e => {
            console.log(e);
            console.log(
                'Connection state changed: ' +
                    this.peerConnection.connectionState
            );
            IOController.socket.emit('webrtc_sendLogs', {
                logType: 'connectionstatechange',
                log:
                    'Connection state changed: ' +
                    this.peerConnection.connectionState,
            });
        });

        this.peerConnection.addEventListener('icecandidateerror', function (e) {
            console.log('icecandidateerror');
            console.log(e);
            IOController.socket.emit('webrtc_sendLogs', {
                logType: 'icecandidateerror',
                log: {
                    address: e.address,
                    errorCode: e.errorCode,
                    errorText: e.errorText,
                    hostCandidate: e.hostCandidate,
                },
            });
        });

        this.consultationDataChannel = new ConsultationDataChannel(
            this.peerConnection,
            {
                onChangeVideoState: this.changeVideoState.bind(this),
                onSnapshotCreated: this.onSnapshotCreated.bind(this),
                onDrawUpdate: this.onDrawUpdate.bind(this),
                onSnapshotClose: this.onSnapshotClose.bind(this),
                onReceiveCreateInfo: this.onReceiveCreateInfo.bind(this),
                onDataChannelCreatedCB:
                    this.sendUserToggledCameraMicEvent.bind(this),
            }
        );

        this.peerConnection.onicecandidate = e => {
            console.log('generating candidate:');
            console.log(e);
            if (e.candidate) {
                this.onGenerateCandidate(
                    e.candidate,
                    this.roomId,
                    this.presenterId
                );
            }
        };

        const offerSdp = new RTCSessionDescription({
            type: 'offer',
            sdp: this.offer,
        });

        this.peerConnection.setRemoteDescription(
            offerSdp,
            () => {
                MediaDevice.getUserMedia(this.facingMode)
                    .then(stream => {
                        this.changeTrackState(
                            this.voiceEnabled,
                            stream.getAudioTracks()
                        );
                        this.changeTrackState(
                            this.videoEnabled,
                            stream.getVideoTracks()
                        );
                        if (this.withoutVideoTrack) {
                            stream.getVideoTracks().forEach(t => {
                                t.stop();
                                stream.removeTrack(t);
                            });
                        }

                        this.localStream = stream;
                        this.onGotLocalStream(this.localStream);
                        console.log('tracks', stream.getTracks());
                        this.peerConnection.addStream(stream);
                        this.peerConnection
                            .createAnswer()
                            .then(answer => {
                                this.peerConnection
                                    .setLocalDescription(answer)
                                    .then(() => {
                                        this.readyToAddCandidate = true;
                                        this.onGotSdpAnswer(
                                            answer.sdp,
                                            this.roomId,
                                            this.presenterId
                                        );
                                        if (this.shouldUseSafariHack) {
                                            setTimeout(() => {
                                                MediaDevice.connectToSpeaker(audioRemoteStream, 100);
                                                this.updateStream();
                                            }, 0);
                                        }
                                    });
                            })
                            .catch(this.onMediaError);
                    })
                    .catch(this.onGetMediaError);
            },
            this.onMediaError
        );
    }

    addIceCandidate(candidate) {
        this.peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
    }

    disableTracks(tracks) {
        if (tracks) {
            tracks.forEach(tr => (tr.enabled = false));
        }
    }

    enableTracks(tracks) {
        if (tracks) {
            tracks.forEach(tr => (tr.enabled = true));
        }
    }

    changeVideoState(remoteVideoOn) {
        // potential workaround for safari issue with local camera freezes on orientation chage
        if (remoteVideoOn) {
            this.enableTracks(this.localStream.getVideoTracks());
            this.localStream.getVideoTracks()
                .map((track) => this.peerConnection.addTrack(track));
        } else {
            const videoSender = this.peerConnection.getSenders()
                .find(({track}) => track.kind === 'video');
            this.peerConnection.removeTrack(videoSender);
        }
        if (this.onVideoStateChange) this.onVideoStateChange(remoteVideoOn);
    }

    stop() {
        this.stopDataChannel();
        this.releasePeerConnection();
        this.releaseStream();
    }

    stopDataChannel() {
        this.consultationDataChannel.stop();
    }

    releaseStream() {
        if (this.localStream) {
            this.localStream.getTracks().forEach(t => t.stop());
            if (this.onReleaseLocalStream) {
                this.onReleaseLocalStream();
            }
        }
    }

    toggleVoice() {
        this.voiceEnabled = !this.voiceEnabled;
        this.changeTrackState(
            this.voiceEnabled,
            this.localStream.getAudioTracks()
        );
        this.sendUserToggledCameraMicEvent();
        return this.voiceEnabled;
    }

    toggleVideo() {
        this.videoEnabled = !this.videoEnabled;
        this.changeTrackState(
            this.videoEnabled,
            this.localStream.getVideoTracks()
        );
        this.sendUserToggledCameraMicEvent();
        if (this.shouldUseSafariReupdateStream) {
            this.updateStream();
        }
        return this.videoEnabled;
    }

    sendUserToggledCameraMicEvent() {
        if (!this.consultationDataChannel) return;

        const data = {
            voiceEnabled: this.voiceEnabled,
            videoEnabled: this.videoEnabled,
        };
        this.consultationDataChannel.send({
            event: 'user_toggled_camera_mic',
            data,
        });
    }

    switchCamera() {
        this.facingMode = this.facingMode === 'user' ? 'environment' : 'user';
        console.log('switching cd')
        this.releaseStream();
        MediaDevice.getUserMedia(this.facingMode)
            .then(stream => {
                this.localStream = stream;
                stream.getTracks().forEach(track => {
                    const sender = this.peerConnection
                        .getSenders()
                        .find(s => s.track && s.track.kind === track.kind);
                    if (sender) {
                        sender.track.stop();
                        sender.replaceTrack(track);
                    } else {
                        this.peerConnection.addTrack(track)
                    }
                });

                this.changeTrackState(
                    this.voiceEnabled,
                    stream.getAudioTracks()
                );
                this.changeTrackState(
                    this.videoEnabled,
                    stream.getVideoTracks()
                );
                if (this.withoutVideoTrack) {
                    stream.getVideoTracks().forEach(t => stream.removeTrack(t));
                }

                this.onGotLocalStream(this.localStream);
            })
            .catch(err => console.log(err));
    }

    updateStream() {
        // Bug! Remote peer won't get stream from safari 15, without stream recreate.
        this.releaseStream();
        MediaDevice.getUserMedia(this.facingMode)
            .then(stream => {
                this.localStream = stream;
                stream.getTracks().forEach(track => {
                    const sender = this.peerConnection
                        .getSenders()
                        .find(s => s.track && s.track.kind === track.kind);
                    if (sender) {
                        sender.replaceTrack(track);
                    }
                });

                // this.changeTrackState(!this.voiceEnabled, stream.getAudioTracks());
                this.changeTrackState(
                    this.voiceEnabled,
                    stream.getAudioTracks()
                );
                this.changeTrackState(
                    this.videoEnabled,
                    stream.getVideoTracks()
                );
                if (this.withoutVideoTrack) {
                    stream.getVideoTracks().forEach(t => stream.removeTrack(t));
                }

                this.onGotLocalStream(this.localStream);
            })
            .catch(err => console.log(err));
    }

    changeTrackState(enabled, tracks) {
        if (enabled) {
            this.enableTracks(tracks);
        } else {
            this.disableTracks(tracks);
        }
    }

    releasePeerConnection() {
        if (
            this.peerConnection &&
            this.peerConnection.connectionState !== 'closed' &&
            this.peerConnection.signalingState !== 'closed'
        ) {
            this.peerConnection.close();
        }
    }
}

export default VideoChat;

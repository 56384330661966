import React from 'react';

export default props => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='19'
        viewBox='0 0 20 19'
        {...props}
    >
        <path
            fill='#FFF'
            fillRule='nonzero'
            d='M2 0C.9 0 0 .9 0 2v10.793a.5.5 0 0 0 .854.353L3 11h9c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2H2zm14 5v4a4 4 0 0 1-4 4H6v1c0 1.1.9 2 2 2h9l2.146 2.146a.5.5 0 0 0 .854-.353V7c0-1.1-.9-2-2-2h-2z'
        />
    </svg>
);

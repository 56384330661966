import React, {Component} from 'react';
import classnames from 'classnames';
import {getBrowserOs} from '../../utils/browserFeatures';
import ScreenHeader from './ScreenHeader';
import WebsitePreview from '../../assets/icons/WebsitePreview';
import Button from '../Button/Button';
import './WebsiteScreen.scss';

function isValidLink(link) {
    return link.includes('/') && link.includes('.') && link.includes(':');
}

class WebsiteScreen extends Component {

    render() {
        const {
            screen,
            room,
            isVideoConsultationEnabled,
            isAudioConsultationEnabled,
            requestConsultationWithType,
            requestPhoneConsultation,
            consultationRequestProgress,
            isOnPreview,
            logo,
            departmentsState,
            requestChat,
            showChatWasNotReadIcon,
            isQrChatEnabled,
            onQrLogoClick,
            isCallButtonsExpanded,
            requestAIChat,
        } = this.props;
        const link = screen.settings.options.link;
        const showLink = screen.settings.options.showLink;
        const isValid = isValidLink(link);
        const {backgroundColor} = screen.settings;
        const isIOS = getBrowserOs() === 'iOS';

        const containerStyle = isOnPreview
            ? {overflow: 'hidden', background: backgroundColor}
            : {background: backgroundColor};
        if (isIOS) {
            containerStyle.overflowY = 'scroll';
        }

        const iframeClassName = classnames((!isValid || showLink) && 'hidden');

        const showTeleportivityLogo = room && room.roomInfo.powered_by_logo;

        const uploadedLogoClassName = classnames('mw-uploaded-logo', isCallButtonsExpanded && 'withExpandedCallButtons');

        return (
            <div className="mw-screen-container mw-website-screen">
                <ScreenHeader
                    room={room}
                    screen={screen}
                    isVideoConsultationEnabled={isVideoConsultationEnabled}
                    isAudioConsultationEnabled={isAudioConsultationEnabled}
                    requestConsultationWithType={requestConsultationWithType}
                    requestChat={requestChat}
                    requestAIChat={requestAIChat}
                    requestPhoneConsultation={requestPhoneConsultation}
                    consultationRequestProgress={consultationRequestProgress}
                    departmentsState={departmentsState}
                    showChatWasNotReadIcon={showChatWasNotReadIcon}
                    isQrChatEnabled={isQrChatEnabled}
                    logo={logo}
                    isCallButtonsExpanded={isCallButtonsExpanded}
                />
                <div className="mw-screen-iframe-container" style={containerStyle}>
                    <iframe className={iframeClassName}
                            sandbox="allow-same-origin allow-forms allow-scripts allow-popups"
                            src={link}
                            scrolling={isOnPreview ? 'no' : 'yes'}/>
                    {showLink && (
                        <div className="direct-link">
                            <WebsitePreview/>
                            <a href={link} target="_blank" rel="noopener noreferrer">
                                <Button type="primary" className="link-button">Go to link</Button>
                            </a>
                        </div>
                    )}
                </div>
                {logo &&
                <div onClick={onQrLogoClick} className={uploadedLogoClassName}><img alt={'logo'} src={logo.url}/></div>}
            </div>
        );
    }
}

export default WebsiteScreen;

import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import CameraDisabled from '../../assets/icons/CameraDisabled';
import './VideoStream.module.scss';
import classNames from 'classnames';

const initialState = {
    isVideoReady: false,
    hasVideoStream: false,
};

const VideoStream = React.forwardRef(
    (
        {stream, onCanPlay, videoEnabled, placeholderImage, className, muted},
        videoRef
    ) => {
        const [state, setState] = useState({
            ...initialState,
            hasVideoStream: videoEnabled,
        });
        const [isVideoLoaded, setIsVideoLoaded] = useState(false);

        const loadVideo = () => {
            if (stream && videoRef.current) {
                videoRef.current.srcObject = stream;
                videoRef.current.load();
            }
        };

        useEffect(() => {
            if (videoRef.current) {
                videoRef.current.oncanplay = () => {
                    setIsVideoLoaded(true);
                    onCanPlay && onCanPlay();
                    setState(prevState => ({...prevState, isVideoReady: true}));
                    videoRef.current.play();
                };

                if (stream) {
                    loadVideo();
                }
            }
        }, [stream, videoRef]);

        useEffect(() => {
            setState(prevState => ({
                ...prevState,
                hasVideoStream: videoEnabled,
            }));
        }, [videoEnabled]);

        return (
            <>
                <video
                    ref={videoRef}
                    className={classNames(
                        className,
                        (!state.hasVideoStream || !isVideoLoaded) && 'noVideo'
                    )}
                    onStalled={() => {
                        if (state.hasVideoStream) loadVideo();
                    }}
                    onEmpty={() => {
                        if (state.hasVideoStream) loadVideo();
                    }}
                    controls={false}
                    autoPlay
                    playsInline
                    poster={CameraDisabled}
                    muted={muted}
                />
                {!state.hasVideoStream && (
                    <div className={classNames(className, 'iconWrapper')}>
                        {placeholderImage ? (
                            <img src={placeholderImage} />
                        ) : (
                            <CameraDisabled className='placeholderIcon' />
                        )}
                    </div>
                )}
            </>
        );
    }
);

export default VideoStream;

import React, {Component} from 'react';
import classnames from 'classnames';
import Logo from '../../components/Logo/Logo';
import Button from '../../components/Button/Button';
import TapMeIcon from '../../assets/icons/TapMe';
import {ConsultationConsumer} from '../../contexts/Consultation';
import {ScreenConsumer} from '../../contexts/Screen';
import {DepartmentsConsumer} from '../../contexts/Departments';
import './TapMe.scss';
import SwitchChatAiIcon from '../../assets/icons/SwitchChatAiIcon';

class TapMe extends Component {
    requestConsultation() {
        const {room} = this.props;
        if (!room.hasAvailableMembers) {
            return;
        }
        const {requestConsultation} = this.props.consultationAction;
        requestConsultation();
    }

    toSendingPhoneNumber() {
        const {toSendingPhoneNumber} = this.props.consultationAction;
        toSendingPhoneNumber();
    }

    render() {
        const {room, consultationState, departmentsState, consultationAction, qrCode} = this.props;
        const {didSendPhoneNumber, consultationRequestError} = consultationState;
        const {isUpdatingDepartments} = departmentsState;

        const companyName = room && room.roomInfo.company_name;

        const screenHoldScreenText = 'Need Assistance?';
        // This option is not editable in QR codes for now
        // const screenHoldScreenText = room && room.screenSettings.screenHoldScreenText;

        const buttonDisabled = !room.hasAvailableMembers || isUpdatingDepartments;
        const buttonClassName = classnames('tapMeButton', buttonDisabled ? 'inactive' : 'active');

        const allowLeavePhoneNumber = room && room.roomInfo.allow_leave_phone_number;

        if (consultationRequestError) {
            return (
                <div className='contentContainer tapMeContentContainer'>
                    {qrCode?.useAiAssistant && !qrCode?.aiDisabled && <div className='chatAiButton' onClick={consultationAction?.requestAIChat}>
                        <SwitchChatAiIcon />
                    </div>}
                    <div className='tapMeErrorContainer'>
                        Our operators are not available, please try again shortly
                    </div>
                </div>
            );
        }

        return (
            <div className='contentContainer tapMeContentContainer'>
                {qrCode?.useAiAssistant && !qrCode?.aiDisabled && <div className='chatAiButton' onClick={consultationAction?.requestAIChat}>
                    <SwitchChatAiIcon />
                </div>}
                <div className="contentContainer tapMeInnerContainer">
                    <div className="tapMeCompanyName">{companyName}</div>
                    <div className="tapMeHoldText">{screenHoldScreenText}</div>
                    <div className={buttonClassName} onClick={this.requestConsultation.bind(this)}>
                        <div className="innerButton">
                            {!buttonDisabled ? <TapMeIcon/> : 'Not available'}
                        </div>
                    </div>
                    {buttonDisabled && allowLeavePhoneNumber && !didSendPhoneNumber &&
                    <Button type="primary" onClick={this.toSendingPhoneNumber.bind(this)}>Leave a message</Button>}
                </div>
                <Logo room={room} position="bottomleft" withLink/>
            </div>
        );
    }
}

export default props => (
    <ConsultationConsumer>
        {({state: consultationState, actions: consultationAction}) => (
            <DepartmentsConsumer>
                {({state: departmentsState}) => (
                    <ScreenConsumer>
                        {({state, actions}) => <TapMe departmentsState={departmentsState}
                                                      consultationState={consultationState}
                                                      consultationAction={consultationAction}
                                                      {...state}
                                                      {...actions}
                                                      {...props}/>}
                    </ScreenConsumer>
                )}
            </DepartmentsConsumer>
        )}
    </ConsultationConsumer>
);

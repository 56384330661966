import DeviceDetector from 'device-detector-js';
const {detect} = require('detect-browser');

const getAndParseUa = (ua) => {
    const detector = new DeviceDetector();
    return detector.parse(ua)
};


const userDevice = getAndParseUa(navigator.userAgent);

const browser = detect();



const osPlatforms = {
    mobile: [
        'ios',
        'android'       
    ],
    desktop: [
        'windows', 
        'linux',
        'osx'
    ]
}

const osAliases = {
    android: [
        'android os',
        'android'
    ],
    ios: [
        'ios'
    ],
    windows: [
        'win',
        'windows',
        'windows 10'
    ],
    linux: [
        'linux',
        'ubuntu',
        'unix',
        'suse'
    ],
    osx: [
        'mac',
        'macosx',
        'osx'
    ]
}

const browserAliases = {
    safari: [
        'safari',
        'ios'
    ],
    chrome: [
        'chrome',
        'crios'
    ],
    ie: [
        'ie',
        'iexplorer',
        'internet explorer'
    ],
    firefox: [
        'firefox',
        'ff'
    ],
    edge: [
        'edge'
    ],
    opera: [
        'opera'
    ],
    samsung: [
        'samsung browser'
    ]
}

export function hasWebrtcSupport() {
    return navigator.mediaDevices && navigator.mediaDevices.getUserMedia &&
        (window.webkitRTCPeerConnection || window.mozRTCPeerConnection || window.RTCPeerConnection);
}

export function getBrowserOs() {
    return browser.os;
}

export function getBrowser() {
    return browser.name;
}

export function getOsAndBrowser () {
    const {
        client: browserDetails,
        device: deviceDetails,
        os: osDetails
    } = userDevice;

    const { name }  = browserDetails;
    const { name: os } = osDetails;
    const { brand, type } = deviceDetails;

    const osMatch = Object.entries(osAliases).find(([ osGenericName, aliases]) => {
        if (!aliases) return false;
        return aliases.includes(os.toLowerCase()) || os.toLowerCase().includes(osGenericName.toLowerCase())
    }) || []
    const normalizedOs = osMatch[0] || os;

    const browserMatch = Object.entries(browserAliases).find(([browserGenericName, aliases]) => {
        if (!aliases) return false;
        return aliases.includes(name.toLowerCase()) || name.toLowerCase().includes(browserGenericName.toLowerCase())
    }) || []
    const normalizedBrowserName = browserMatch[0] || name;

    let [ platform ] = Object.entries(osPlatforms).find(([_, aliases]) => {
        if (!aliases) return false;
        return aliases.includes( normalizedOs || name )
    });

    const isMobile = platform ? platform === 'mobile' : 'desktop'

    return [
        normalizedBrowserName,
        normalizedOs,
        isMobile,
        brand ? brand.toLowerCase() : undefined
    ]
};

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Cross from '../../../assets/icons/Cross';
import './SnapshotComponent.scss';
import {TransformComponent, TransformWrapper} from 'react-zoom-pan-pinch';
import ZoomSlider from './ZoomSlider';

export default class SnapshotComponent extends Component {
    static propTypes = {
        snapshot: PropTypes.object,
        snapshotDrawings: PropTypes.object,
        onClose: PropTypes.func,
        show: PropTypes.bool,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.show && !this.props.show) {
            this.clearDrawingCanvas();
            return;
        }

        const needLoadNewImage =
            this.props.snapshot.url !== prevProps.snapshot.url; // && !this.props.snapshot.isLocal;
        if (needLoadNewImage && this.canvas && this.drawingCanvas) {
            this.uploadImageToCanvas(this.props.snapshot.url, this.canvas);
        }
        if (!prevProps.snapshotSize && this.props.snapshotSize) {
            this.prepareCanvas();
        }

        const isDrawingUpdated =
            this.props.snapshotDrawings.url !== prevProps.snapshotDrawings.url;
        if (
            isDrawingUpdated &&
            this.drawingCanvas &&
            this.props.snapshotDrawings.url
        ) {
            this.uploadImageToCanvas(
                this.props.snapshotDrawings.url,
                this.drawingCanvas
            );
        }
    }

    clearDrawingCanvas = () => {
        const ctx = this.drawingCanvas.getContext('2d');
        ctx.clearRect(
            0,
            0,
            this.drawingCanvas.width,
            this.drawingCanvas.height
        );
    };

    prepareCanvas = () => {
        const {width, height} = this.props.snapshotSize;
        this.canvas.width = width;
        this.canvas.height = height;
        const ctx = this.drawingCanvas.getContext('2d');
        ctx.fillStyle = '#2f3243';
        ctx.fillRect(0, 0, width, height);
    };

    uploadImageToCanvas = (url, canvas) => {
        if (!url || !canvas) {
            return;
        }
        const img = new Image();
        img.onload = () => {
            this.drawImageToCanvas(img, canvas);
        };
        img.src = url;
        img.crossOrigin = 'Anonymous';

    };

    drawImageToCanvas = (img, canvas) => {
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, img.width, img.height);
        ctx.drawImage(img, 0, 0);
    };

    render() {
        const {onClose, show, extraClassName, qrCodeName} = this.props;

        const wrapperClassname = classnames(
            'snapshotWrapper',
            show && 'visible',
            extraClassName
        );
        return (
            <div className={wrapperClassname}>
                <div
                    className='snapshotContainer'
                    key={show ? 'show' : 'hidden'}
                >
                    <button className='close-button' onClick={onClose}>
                        <Cross />
                    </button>
                    <TransformWrapper
                        options={{
                            maxScale: 2,
                            minScale: 1,
                        }}
                    >
                        {({
                            state: {
                                scale
                            },
                            centerView,
                            resetTransform,
                            zoomIn,
                            zoomOut,
                            ...rest
                        }) => (
                            <React.Fragment>
                                <div className='zoom-slider-container'>
                                    <ZoomSlider
                                        min={0}
                                        max={100}
                                        step={0.2}
                                        zoomIn={zoomIn}
                                        zoomOut={zoomOut}
                                        value={
                                            Math.round(
                                                ((scale - 1) * 100) / 20
                                            ) * 20
                                        }
                                        onChange={val => {
                                            if (val === 0) {
                                                resetTransform();
                                                return;
                                            }
                                            centerView(
                                                1 + (1 * val) / 100,
                                            );
                                        }
                                    }
                                    {...rest}
                                    ></ZoomSlider>
                                </div>
                                <TransformComponent>
                                    <div className='board-wrapper'>
                                        <canvas
                                            id='snapshotCanvas'
                                            className='snapshotCanvas'
                                            ref={ref => (this.canvas = ref)}
                                        />
                                        <canvas
                                            className='drawingCanvas'
                                            ref={ref =>
                                                (this.drawingCanvas = ref)
                                            }
                                        />
                                    </div>
                                </TransformComponent>
                            </React.Fragment>
                        )}
                    </TransformWrapper>
                </div>
            </div>
        );
    }
}

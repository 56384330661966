import 'regenerator-runtime/runtime'
import React from 'react';
import {render} from 'react-dom';
import App from './src/containers/App/App';

render(<App/>, document.getElementById('root'));

if (module.hot) {
    module.hot.accept(function () {
        render(<App/>, document.getElementById('root'));
    });
}

(() => {
    function fixVH() {
        let vh = window.innerHeight * 0.01;
        // const isKeyboardOpen = (window.screen.height - window.innerHeight) > 120
        // const platform = navigator?.userAgentData?.platform || navigator?.platform
        // const isIpad = (platform === 'MacIntel' && navigator.maxTouchPoints > 0) || platform === 'iPad';
        // if (window.navigator.standalone && isKeyboardOpen && isIpad) {
        //     vh = vh * 0.9;
        // }
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    fixVH();
    window.addEventListener("resize", () => fixVH());
})();
console.log('ver 01');
// (function () {
//     var script = document.createElement('script');
//     script.src = "//cdn.jsdelivr.net/npm/eruda";
//     document.body.appendChild(script);
//     script.onload = function () {
//         eruda.init()
//     }
// })();

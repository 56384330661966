import React, {Component} from 'react';
import TemplateScreen from "../../components/TemplateScreen/TemplateScreen";
import {TemplateConsumer} from '../../contexts/Template';

class Preview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consultationType: null,
            screen: null,
            gotAcknowledge: false,
        }
        this.onMessageHandler = this._onMessageHandler.bind(this);
    }

    componentDidMount() {
        if (window.__QR_STOP_UPDATE_LOADER_FUNCTION__) {
            window.__QR_STOP_UPDATE_LOADER_FUNCTION__();
        }
        window.addEventListener("message", this.onMessageHandler, false);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.onMessageHandler);
    }

    _onMessageHandler(event) {
        const {gotAcknowledge} = this.state;
        const {message, data} = event.data;
        if (message === 'screen') {
            this.setState(data);
            const {screen} = data;
            if (!gotAcknowledge && screen && screen.id) {
                event.source.postMessage({message: 'ack', screenId: screen.id}, event.origin);
            }
        }
        if (message === 'gotAcknowledge') {
            this.setState({gotAcknowledge: true});
        }
    }

    render() {
        const {screen, isVideoConsultationEnabled, isAudioConsultationEnabled, isQrChatEnabled, logo, poweredByLogo} = this.state;
        const {type, isPdfViewerLoaded} = this.props;
        if (!screen) {
            return null;
        }

        const screenState = {
            companyCountryChoices: [{country_code: '61', region_code: 'AU'}],
            companyRegionCode: 'AU',
        };

        return (
            <div>
                <TemplateScreen
                    logo={logo}
                    previewType={type}
                    isLocked={false}
                    screen={screen}
                    room={{roomInfo: {powered_by_logo: poweredByLogo}, hasAvailableMembers: true}}
                    isVideoConsultationEnabled={isVideoConsultationEnabled}
                    isAudioConsultationEnabled={isAudioConsultationEnabled}
                    isQrChatEnabled={isQrChatEnabled}
                    isOnPreview
                    departmentsState={{departments: []}}
                    screenState={screenState}
                    isPdfViewerLoaded={isPdfViewerLoaded}
                />
            </div>
        );
    }
}

export default props => (
    <TemplateConsumer>
        {({state}) => <Preview isPdfViewerLoaded={state.isPdfViewerLoaded} {...props}/>}
    </TemplateConsumer>
);

import React, {useMemo, useState} from 'react';
import DeleteButton from '../../../../assets/icons/DeleteButton';
import KeyboardButton from './KeyboardButton';
import BellButton from './BellButton/BellButton';
import Letters from './Letters/Letters';
import './Keyboard.scss';

interface KeyboardProps {
    onKeyboardClick: (v: string) => void;
    onClearClick: () => void;
    onCallClick: () => void;
    controlButtonColor: string;
    digitButtonColor: string;
    insideElementColor: string;
    showBellButton: boolean;
    additionalKeyboardEnabled: boolean;
}

export default function Keyboard(props: KeyboardProps) {
    const {
        onKeyboardClick,
        onClearClick,
        controlButtonColor,
        digitButtonColor,
        onCallClick,
        insideElementColor,
        showBellButton,
        additionalKeyboardEnabled,
    } = props;

    const [showKeyboard, setShowKeyboard] = useState<boolean>(false);

    const canDisplayBellButton = showBellButton && !additionalKeyboardEnabled;

    const toggleAdditionalKeyboard = () => {
        setShowKeyboard(flag => !flag);
    };

    const buttons = useMemo(
        () =>
            new Array(12).fill(0).map((_, i) => {
                const idx = i + 1;
                if (idx === 10) {
                    if (showKeyboard)
                        return (
                            <Letters
                                onKeyboardClick={onKeyboardClick}
                                controlButtonColor={controlButtonColor}
                                toggleAdditionalKeyboard={
                                    toggleAdditionalKeyboard
                                }
                            />
                        );

                    if (!canDisplayBellButton)
                        return (
                            <KeyboardButton
                                key={idx}
                                buttonColor={controlButtonColor}
                                onClick={() => toggleAdditionalKeyboard()}
                                visibility={additionalKeyboardEnabled ? 'visible' : 'hidden'}
                            >
                                <div style={{color: 'white'}}>Az</div>
                            </KeyboardButton>
                        );

                    return (
                        <BellButton
                            key={idx}
                            buttonColor={controlButtonColor}
                            onCallClick={onCallClick}
                            canDisplayBellButton={canDisplayBellButton}
                        />
                    );
                }
                if (idx === 11) {
                    return (
                        !showKeyboard && (
                            <KeyboardButton
                                key={idx}
                                buttonColor={digitButtonColor}
                                onClick={() => onKeyboardClick('0')}
                            >
                                <div style={{color: insideElementColor}}>0</div>
                            </KeyboardButton>
                        )
                    );
                }
                if (idx === 12) {
                    return (
                        !showKeyboard && (
                            <DeleteButton
                                key={idx}
                                color='#FFFFFF'
                                backgroundColor={controlButtonColor}
                                onClick={onClearClick}
                                className='delete-button'
                            />
                        )
                    );
                }
                return (
                    <KeyboardButton
                        key={idx}
                        buttonColor={digitButtonColor}
                        onClick={() => onKeyboardClick(idx.toString())}
                    >
                        <div style={{color: insideElementColor}}>{idx}</div>
                    </KeyboardButton>
                );
            }),
        [
            controlButtonColor,
            digitButtonColor,
            insideElementColor,
            onKeyboardClick,
            onClearClick,
            onCallClick,
        ]
    );
    return <div className='keyboard-wrapper'>{buttons}</div>;
}

import React, {Component} from 'react';
import Logo from '../../components/Logo/Logo';
import Input from '../../components/Input/Input';
import FormattedInput from '../../components/Input/FormattedInput';
import Button from '../../components/Button/Button';
import Dropdown from '../../components/Dropdown/Dropdown';
import {ConsultationConsumer} from '../../contexts/Consultation';
import {ScreenConsumer} from '../../contexts/Screen';
import RedirectTimeout from '../../components/RedirectTimeout/RedirectTimeout';
import SuccessIcon from '../../assets/check.svg';
import './LeavePhoneNumber.scss';
import {TemplateConsumer} from '../../contexts/Template';
import isEmail from 'validator/lib/isEmail';
import Checkbox from '../../components/CheckBox/CheckBox';
import TextArea from '../../components/TextArea/TextArea';
import MobileDetect from 'mobile-detect';
import _ from 'lodash';

const TermsLabel = () => {
    return (
        <span>
            {'I agree to the '}
            <a
                className={'terms-link'}
                target='_blank'
                rel='noopener noreferrer'
                onClick={e => e.stopPropagation()}
                href={'https://teleportivity.com/policy'}
            >
                Privacy Policy
            </a>
        </span>
    );
};

class LeavePhoneNumber extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRegionCode: this.props.screenState.companyRegionCode,
            error: null,
            showSuccessfullySendedPopUp: false,
            needToSendEmail: false,
            termsAccepted: false,
        };
        this.isMobile = !!new MobileDetect(window.navigator.userAgent).mobile();
        if (this.isMobile) {
            this.onWindowVisibilityChange =
                this.stopConsultationAndRemoveChangeTabEvent.bind(this);
        }
    }

    componentDidMount() {
        if (this.isMobile) {
            document.addEventListener(
                'visibilitychange',
                this.onWindowVisibilityChange
            );
        }
    }

    componentWillUnmount() {
        if (this.isMobile) {
            document.removeEventListener(
                'visibilitychange',
                this.onWindowVisibilityChange
            );
        }
    }

    stopConsultationAndRemoveChangeTabEvent() {
        const {stopConsultationRequest} = this.props.consultationAction;
        document.removeEventListener(
            'visibilitychange',
            this.onWindowVisibilityChange
        );
        stopConsultationRequest();
    }

    onExternlLinkClick() {
        this.stopConsultationAndRemoveChangeTabEvent();
    }

    onCancelLeavePhone() {
        const {cancelLeavingPhoneNumber, stopConsultationRequest} =
            this.props.consultationAction;
        stopConsultationRequest();
        cancelLeavingPhoneNumber();
    }

    onReturnFromLeaveMessage() {
        const {phoneNumberSettings = {}} = this.props.consultationState;
        const {
            toChooseDepartmentsOrCancel,
            stopConsultationRequest,
            toStartState,
        } = this.props.consultationAction;
        const {withoutChoosingDepartments = false} = phoneNumberSettings;
        if (withoutChoosingDepartments) {
            return toStartState();
        }
        stopConsultationRequest();
        toChooseDepartmentsOrCancel();
    }

    getDropdownData() {
        const {companyRegionCode, companyCountryChoices} =
            this.props.screenState;

        const data = companyCountryChoices.map(c => ({
            label: `${c.region_code} +${c.country_code}`,
            value: c.country_code,
        }));
        const defaultCountry = companyCountryChoices.find(
            c => c.region_code === companyRegionCode
        );
        const defaultValue = {
            label: `${defaultCountry.region_code} +${defaultCountry.country_code}`,
            value: defaultCountry.country_code,
        };
        return {defaultValue, data};
    }

    onRegionCodeChanged({label, value}) {
        const {companyCountryChoices} = this.props.screenState;
        this.setState({
            selectedRegionCode: companyCountryChoices.find(
                c => c.country_code === value
            ).region_code,
        });
    }

    onSendPhoneNumber() {
        const {phoneNumberSettings = {}} = this.props.consultationState;
        const {phoneCode, phoneNumber, name, message, email} = this.refs;
        const {sendCallRequest, stopConsultationRequest} =
            this.props.consultationAction;
        if (!email.value) {
            return this.setState({error: 'Please input email'});
        }
        if (!isEmail(email.value)) {
            return this.setState({error: 'Email is invalid'});
        }
        if (!this.state.termsAccepted) {
            return this.setState({error: 'Please accept Privacy Policy'});
        }
        const phone = phoneNumber.value
            ? `+${phoneCode.getInstance().value}-${phoneNumber.value}`
            : null;
        const data = {
            name: name.value,
            message: message.value,
            phone: phone,
            email: email.value,
            needToSendEmail: this.state.needToSendEmail,
        };
        if (phoneNumberSettings.departmentId) {
            data.departmentId = phoneNumberSettings.departmentId;
        }
        stopConsultationRequest(false);
        sendCallRequest(data);
        this.setState({showSuccessfullySendedPopUp: true});
    }

    toggleTerms = () => {
        this.setState({termsAccepted: !this.state.termsAccepted});
    };

    toggleNeedsToSendEmail = () => {
        this.setState({needToSendEmail: !this.state.needToSendEmail});
    };

    isFormEnabled() {
        if (!this.hasTemplate()) {
            return this.isEnabledInCompany();
        }

        return this.isEnabledInCompany() && this.isEnabledInTemplate();
    }

    isEnabledInCompany() {
        return _.get(this.props.room, 'roomInfo.allow_leave_phone_number');
    }

    isEnabledInTemplate() {
        return _.get(this.props.templateState, 'template.hasAdditionalOption');
    }

    hasTemplate() {
        return !!this.props.templateState.template;
    }

    getAwayMessage = () => {
        const {templateState} = this.props;

        if (!this.isEnabledInCompany()) {
            return 'Our operators are not available, please try again shortly...';
        }

        const defaultAwayMessage =
            'We will call you back soon if you leave us your phone number';

        return _.get(templateState, 'template.awayMessage', defaultAwayMessage);
    };

    renderSuccessfullySendedPopUp() {
        return (
            <div className='successfully-sended-pop-up-wrapper contentContainer'>
                <div className='success-icon-wrapper'>
                    <img src={SuccessIcon} />
                </div>
                <h1>
                    Thank you!
                </h1>
                <Button
                    type='default'
                    onClick={this.onCancelLeavePhone.bind(this)}
                >
                    Back
                </Button>
                <RedirectTimeout
                    onRedirect={this.onCancelLeavePhone.bind(this)}
                    forceSingleTimeout
                    time={5000}
                />
            </div>
        );
    }

    render() {
        const {room, templateState} = this.props;
        const {
            selectedRegionCode,
            error,
            termsAccepted,
            showSuccessfullySendedPopUp,
            needToSendEmail,
        } = this.state;

        const {formLink, hasAdditionalOption} = templateState.template || {};
        const needForm = templateState.template ? hasAdditionalOption : true;
        const isAllowLeavePhoneNumber = this.isFormEnabled();
        const showForm = isAllowLeavePhoneNumber && needForm && !formLink;
        const showLink = isAllowLeavePhoneNumber && needForm && formLink;

        const phoneCodeDropdownData = showForm ? this.getDropdownData() : {};
        if (showSuccessfullySendedPopUp) {
            return this.renderSuccessfullySendedPopUp();
        }
        return (
            <div className='leavePhoneNumberContainer contentContainer'>
                <div className='leaveNumberTitle'>{this.getAwayMessage()}</div>
                {showLink && (
                    <div
                        onClick={this.onExternlLinkClick.bind(this)}
                        className='externalForm'
                    >
                        <a
                            href={formLink}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Click here
                        </a>
                        <Button
                            type='default'
                            onClick={this.onReturnFromLeaveMessage.bind(this)}
                        >
                            Return
                        </Button>
                    </div>
                )}
                {showForm && (
                    <div className='leavePhoneForm'>
                        <div className='phoneNumberField'>
                            <div className='phoneFieldPlaceholder'>
                                Your number (optional)
                            </div>
                            <Dropdown
                                ref='phoneCode'
                                withSearch={true}
                                defaultValue={
                                    phoneCodeDropdownData.defaultValue
                                }
                                data={phoneCodeDropdownData.data}
                                onChange={this.onRegionCodeChanged.bind(this)}
                            />
                            <FormattedInput
                                ref='phoneNumber'
                                options={{
                                    phone: true,
                                    phoneRegionCode:
                                        selectedRegionCode.toLowerCase(),
                                    delimiter: '-',
                                }}
                            />
                        </div>
                        <Input
                            ref='email'
                            placeholder='Your email'
                            maxLength={400}
                        />
                        <Input
                            ref='name'
                            placeholder='Your name (optional)'
                            maxLength={300}
                        />
                        <TextArea
                            ref='message'
                            placeholder='Your message (optional)'
                            maxLength={400}
                        />
                        <Checkbox
                            onToggle={this.toggleNeedsToSendEmail}
                            checked={needToSendEmail}
                            label="Send email"
                        />
                        <Checkbox
                            onToggle={this.toggleTerms}
                            checked={termsAccepted}
                            label={<TermsLabel />}
                        />
                        {error && <div className='formError'>{error}</div>}

                        <Button
                            type='primary'
                            onClick={this.onSendPhoneNumber.bind(this)}
                        >
                            Send
                        </Button>
                        <Button
                            type='default'
                            onClick={this.onCancelLeavePhone.bind(this)}
                        >
                            Cancel
                        </Button>
                    </div>
                )}
                {(!isAllowLeavePhoneNumber ||
                    (!hasAdditionalOption && !showForm)) && (
                    <Button
                        type='primary'
                        onClick={this.onReturnFromLeaveMessage.bind(this)}
                    >
                        Return
                    </Button>
                )}
                <RedirectTimeout
                    onRedirect={this.onCancelLeavePhone.bind(this)}
                    forceSingleTimeout={!hasAdditionalOption}
                />
                <Logo room={room} position='bottomleft' withLink />
            </div>
        );
    }
}

export default props => (
    <ConsultationConsumer>
        {({state: consultationState, actions: consultationAction}) => (
            <TemplateConsumer>
                {({state: templateState}) => (
                    <ScreenConsumer>
                        {({state: screenState, actions: screenAction}) => (
                            <LeavePhoneNumber
                                consultationState={consultationState}
                                consultationAction={consultationAction}
                                templateState={templateState}
                                screenState={screenState}
                                screenAction={screenAction}
                                {...props}
                            />
                        )}
                    </ScreenConsumer>
                )}
            </TemplateConsumer>
        )}
    </ConsultationConsumer>
);

import { ChatGPTHistory } from "./ChatGPTHistory";

const MAX_SCENARIO_LENGTH = 7600;

export const DOOR_STATES = {
    open: 'open',
    error: 'error',
    default: 'default',
}

export class ChatGPT {
    constructor(config = {}) {
        this.chatGPTHistroy = new ChatGPTHistory();
        this.config = config;
    }

    setConfig(config) {
        this.config = config;
    }

    getChatGPTPrompt (phrase) {
        let prompt = `${this.config.context || ''}\n\nLocal date and time: ${new Date().toLocaleString()}\n\n${this.chatGPTHistroy.getFormatedString()}\nQ: ${phrase}\nA:`;
        if (prompt.length >= MAX_SCENARIO_LENGTH) {
            prompt = `${this.config.context || ''}\n\nLocal date and time: ${new Date().toLocaleString()}\n\n${this.chatGPTHistroy.getFormatedString(prompt.length - MAX_SCENARIO_LENGTH)}\nQ: ${phrase}\nA:`;
        }
        return prompt;
    }

    addToHistory (q, a) {
        this.chatGPTHistroy.add(q, a);
    }

}

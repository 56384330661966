import React from 'react';

export default props => {
    const {backgroundColor, color, onClick, className} = props;
    return (
        <svg width="46" height="34" viewBox="0 0 46 34" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick} className={className}>
        <path d="M13.0611 6.44448C13.417 6.15688 13.8607 6 14.3182 6H44C45.1046 6 46 6.89543 46 8V26C46 27.1046 45.1046 28 44 28H14.3182C13.8607 28 13.417 27.8431 13.0611 27.5555L1.92476 18.5555C0.934254 17.755 0.934255 16.245 1.92477 15.4445L13.0611 6.44448Z" fill={backgroundColor}/>
        <path d="M32.8862 21.6498C32.8862 21.9767 32.7637 22.2831 32.5025 22.5422C32.274 22.7382 32.0246 22.8551 31.6817 22.8551C31.3285 22.8551 31.0443 22.728 30.8308 22.5144L27.0088 18.6897L23.0122 22.6871C22.7837 22.8831 22.5342 23 22.1913 23C21.8571 23 21.6112 22.8823 21.3536 22.6593C21.1332 22.4387 21 22.1468 21 21.8342C21 21.5072 21.1225 21.2008 21.3536 20.9695L25.3209 16.9994L21.3668 13.0305C21.1464 12.8099 21.0132 12.518 21.0147 12.2463C20.9901 11.926 21.1163 11.6166 21.3536 11.3539C21.574 11.1333 21.8658 11 22.1782 11C22.5049 11 22.8111 11.1226 23.0428 11.3544L26.9963 15.3228L30.8176 11.4988C31.038 11.2782 31.3298 11.1449 31.6422 11.1449C31.9689 11.1449 32.2751 11.2675 32.4959 11.4887C32.9592 11.9279 32.9665 12.6662 32.5194 13.1622L28.685 16.9994L32.5106 20.8158C32.7358 21.0241 32.8611 21.3036 32.8862 21.6498Z" fill={color}/>
        </svg>
    );
}

import React from 'react';

export default props => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='8'
        height='10'
        viewBox='0 0 8 10'
        {...props}
    >
        <path
            fill='#FFF'
            fillRule='nonzero'
            d='M4 0a.75.75 0 0 0-.75.75v.348A2.999 2.999 0 0 0 1 4v3l-.768.578A.5.5 0 0 0 .5 8.5h7a.5.5 0 0 0 .269-.922L7 7V4a2.999 2.999 0 0 0-2.25-2.902V.75A.75.75 0 0 0 4 0zM3 9c0 .55.45 1 1 1s1-.45 1-1H3z'
        />
    </svg>
);

import React, {FunctionComponent, ReactNode} from 'react';
import classnames from 'classnames';
import './LoadingOverlay.scss';
import PhoneRing from '../PhoneRing/PhoneRing';

interface Props {
    children?: ReactNode;
    loading: boolean;
    department: any;
    withChat: boolean;
}

const LoadingOverlay: FunctionComponent<Props> = (props: Props) => {
    const {children, loading, department, withChat} = props;
    const departmentNumber = department?.number;

    if (!loading) {
        return null;
    }

    return (
        <div className={classnames('loadingOverlay', {['withChat']: withChat})}>
            <div className='loading-info'>
                <div className='loading-container'>
                    <PhoneRing />
                </div>
                <div className='loading-text'>
                    {departmentNumber && (
                        <span className='loading-text-connect-to'>
                            {`Connecting to ${departmentNumber}`}
                        </span>
                    )}
                    <span className='loading-text-wait'>Please wait</span>
                </div>
            </div>
            <div className='controls-wrapper'>
                <div className='controls'>{children}</div>
            </div>
        </div>
    );
};

export default LoadingOverlay;

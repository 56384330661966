import React, {Component} from 'react';
import _ from 'lodash';
import {Scrollbars} from 'react-custom-scrollbars-2';
import logoImage from '../../assets/logo.svg';
import ScreenHeader from './ScreenHeader';
import TextArea from '../TextArea/TextArea';
import Button from '../Button/Button';
import Input from '../Input/Input';
import FormattedInput from '../Input/FormattedInput';
import Dropdown from '../Dropdown/Dropdown';
import {ScreenConsumer} from '../../contexts/Screen';

import './ServiceNowScreen.scss';
import classnames from 'classnames';

class ServiceNowScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedRegionCode: props.screenState.companyRegionCode || '',
            error: null,
        };
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const prevCompanyRegionCode = this.props.screenState.companyRegionCode;
        const newCompanyRegionCode = newProps.screenState.companyRegionCode;

        if (!prevCompanyRegionCode && newCompanyRegionCode) {
            this.setState({selectedRegionCode: newCompanyRegionCode});
        }
    }

    getDropdownData() {
        const {companyRegionCode, companyCountryChoices} = this.props.screenState;

        const data = companyCountryChoices.map(c => ({
            label: `${c.region_code} +${c.country_code}`,
            value: c.country_code
        }));
        const defaultCountry = companyCountryChoices.find(c => c.region_code === companyRegionCode);
        const defaultValue = {
            label: `${defaultCountry.region_code} +${defaultCountry.country_code}`,
            value: defaultCountry.country_code
        };
        return {defaultValue, data};
    }

    onRegionCodeChanged({label, value}) {
        const {companyCountryChoices} = this.props.screenState;
        this.setState({selectedRegionCode: companyCountryChoices.find(c => c.country_code === value).region_code});
    }

    get clientName() {
        return _.get(this.refs, 'name.value');
    }

    get clientPhone() {
        return _.get(this.refs, 'phone.value');
    }

    get clientEmail() {
        return _.get(this.refs, 'email.value');
    }

    get comment() {
        return _.get(this.refs, 'comment.value');
    }

    validate() {
        const {screen} = this.props;
        const {selectedRegionCode} = this.state;
        const {options} = screen.settings;

        if (options.clientName && options.clientNameRequired && !this.clientName) {
            return 'Name is required';
        }

        if (options.clientPhone && options.clientPhoneRequired && (!selectedRegionCode || !this.clientPhone)) {
            return 'Phone is required';
        }

        if (options.clientEmail && options.clientEmailRequired && !this.clientEmail) {
            return 'Email is required';
        }

        return this.comment ? null : 'Comment is required';
    }

    sendTicket() {
        const {sendServiceNowTicket, miniWebsiteScreen, screenState, isOnPreview} = this.props;
        const {selectedRegionCode} = this.state;
        const selectedCountry = screenState.companyCountryChoices.find(c => c.region_code === selectedRegionCode);

        if (isOnPreview) return;

        const error = this.validate();
        this.setState({error});

        if (error) return;

        const phone = this.clientPhone ? `+${selectedCountry.country_code}${this.clientPhone}` : null;

        const ticketData = {
            comment: this.comment,
            name: this.clientName,
            phone,
            email: this.clientEmail,
        };
        sendServiceNowTicket(ticketData, miniWebsiteScreen);
    }

    renderForm() {
        const {selectedRegionCode, error} = this.state;
        const {screen, ticketWasSent} = this.props;
        const {options} = screen.settings;
        const phoneCodeDropdownData = this.getDropdownData();

        if (ticketWasSent) {
            return (
                <div className="mw-servicenow-submitted-message">Ticket was submitted. Thank you for your message!</div>
            );
        }

        return (
            <Scrollbars>
                <div className="mw-servicenow-form">
                    {options.message && <div className="mw-servicenow-message">{options.message}</div>}
                    {options.clientName && (
                        <Input
                            placeholder={options.clientNameRequired ? 'Name' : 'Name (optional)'}
                            maxLength={100}
                            className="mw-servicenow-input"
                            ref="name"
                        />
                    )}
                    {options.clientPhone && (
                        <div className="mw-servicenow-phone">
                            <Dropdown
                                withSearch={true}
                                defaultValue={phoneCodeDropdownData.defaultValue}
                                data={phoneCodeDropdownData.data}
                                onChange={this.onRegionCodeChanged.bind(this)}
                            />
                            <FormattedInput
                                options={{
                                    phone: true,
                                    phoneRegionCode: selectedRegionCode.toLowerCase(),
                                    delimiter: '-'
                                }}
                                ref="phone"
                                placeholder={options.clientPhoneRequired ? 'Phone' : 'Phone (optional)'}
                            />
                        </div>
                    )}
                    {options.clientEmail && (
                        <Input
                            placeholder={options.clientEmailRequired ? 'Email' : 'Email (optional)'}
                            maxLength={100}
                            className="mw-servicenow-input"
                            ref="email"
                        />
                    )}
                    <TextArea
                        ref="comment"
                        placeholder="Comment"
                        maxLength={500}
                        containerClassName="mw-servicenow-comment"
                        autoHeightMax="100px"
                        autoHeightMin="100px"
                    />
                    {error && <div className="mw-servicenow-error">{error}</div>}
                    <Button
                        type="primary"
                        className="mw-servicenow-submit"
                        onClick={this.sendTicket.bind(this)}
                        style={{backgroundColor: screen.settings.buttonColor}}
                    >
                        {options.buttonText}
                    </Button>
                </div>
            </Scrollbars>
        );
    }

    render() {
        const {
            screen,
            screenState,
            room,
            isVideoConsultationEnabled,
            isAudioConsultationEnabled,
            requestConsultationWithType,
            requestPhoneConsultation,
            consultationRequestProgress,
            logo,
            departmentsState,
            requestChat,
            showChatWasNotReadIcon,
            isQrChatEnabled,
            onQrLogoClick,
            isCallButtonsExpanded,
            requestAIChat,
        } = this.props;

        if (!screenState.companyCountryChoices.length) return <div/>;

        const showTeleportivityLogo = room && room.roomInfo.powered_by_logo;
        const backgroundColor = screen.settings.backgroundColor;

        const uploadedLogoClassName = classnames('mw-uploaded-logo', isCallButtonsExpanded && 'withExpandedCallButtons');

        return (
            <div className="mw-screen-container mw-servicenow-screen" style={{background: backgroundColor}}>
                <ScreenHeader
                    room={room}
                    screen={screen}
                    isVideoConsultationEnabled={isVideoConsultationEnabled}
                    isAudioConsultationEnabled={isAudioConsultationEnabled}
                    requestConsultationWithType={requestConsultationWithType}
                    requestChat={requestChat}
                    requestAIChat={requestAIChat}
                    requestPhoneConsultation={requestPhoneConsultation}
                    consultationRequestProgress={consultationRequestProgress}
                    departmentsState={departmentsState}
                    showChatWasNotReadIcon={showChatWasNotReadIcon}
                    isQrChatEnabled={isQrChatEnabled}
                    logo={logo}
                    isCallButtonsExpanded={isCallButtonsExpanded}
                />
                {logo && <div onClick={onQrLogoClick} className={uploadedLogoClassName}><img src={logo.url}/></div>}
                {this.renderForm()}
            </div>
        );
    }
}

export default props => (
    <ScreenConsumer>
        {({state: screenState}) => <ServiceNowScreen screenState={screenState} {...props}/>}
    </ScreenConsumer>
);

import React from 'react';

export default props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none" fillRule="evenodd">
            <circle cx="24" cy="24" r="24" fill="#FFF"/>
            <path fill="#BBC0CB" fillRule="nonzero"
                  d="M31.438 29.914c0 .4-.15.776-.47 1.093-.28.24-.585.382-1.005.382-.433 0-.78-.155-1.042-.416l-4.68-4.68-4.895 4.89c-.28.24-.585.384-1.005.384-.41 0-.71-.144-1.026-.417a1.43 1.43 0 0 1-.433-1.01c0-.4.15-.775.433-1.058l4.859-4.858-4.843-4.857c-.27-.27-.433-.627-.43-.96-.03-.391.124-.77.414-1.092a1.43 1.43 0 0 1 1.01-.433c.4 0 .775.15 1.059.434l4.841 4.856 4.68-4.68a1.43 1.43 0 0 1 1.01-.432c.4 0 .775.15 1.045.42.567.538.576 1.441.029 2.048l-4.696 4.696 4.685 4.67c.276.255.43.597.46 1.02z"/>
        </g>
    </svg>
);
import React, {Component, createRef} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MobileDetect from 'mobile-detect';

import Logo from '../../components/Logo/Logo';

import Loading from '../../components/Loading/Loading';
import PauseIcon from '../../assets/icons/Pause';
import VideoStream from '../../components/VideoStream/VideoStream';

import {ConsultationConsumer} from '../../contexts/Consultation';
import {ScreenConsumer} from '../../contexts/Screen';
import {TemplateConsumer} from '../../contexts/Template';

import Camera from '../../assets/icons/Camera';
import CameraDisabled from '../../assets/icons/CameraDisabled';
import Mic from '../../assets/icons/Mic';
import MicDisabled from '../../assets/icons/MicDisabled';
import HangOut from '../../assets/icons/HangOut';
import ChatIcon from '../../assets/icons/Chat';
import ChatNewMessagesIcon from '../../assets/icons/ChatNewMessages';
import Chat from '../Chat/Chat';
import VolumeDisabled from '../../assets/icons/VolumeDisabled';
import VolumeEnabled from '../../assets/icons/VolumeEnabled';
import CameraReverse from '../../assets/icons/CameraReverse';
import CallPhone from '../../assets/icons/CallPhone';
import ReloadAudioButton from '../../assets/icons/ReloadAudioButton';
import _ from 'lodash';

import './Consultation.scss';
import SnapshotIcon from '../../assets/icons/SnapshotIcon';
import SnapshotComponent from './snapshot/SnapshotComponent';
import VoiceToText from '../../components/VoiceToText/VoiceToText';
import AzureSpeechRecognizer from '../../utils/AzureSpeechRecognizer';
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay';
import HangoutButton from '../../components/HangoutButton/HangoutButton';
import LeaveMessageButton from '../../components/LeaveMessageButton/LeaveMessageButton';
import {DepartmentsConsumer} from '../../contexts/Departments';
import {getOsAndBrowser} from '../../utils/browserFeatures';
import IOController from '../../controllers/IOController';
import RecordingNotification from './RecordingNotification';

class Consultation extends Component {
    constructor(props) {
        super(props);
        const [browser, os, isMobile] = getOsAndBrowser();
        this.isMobile = isMobile;
        this.isTablet = isMobile;
        this.isSafari = browser === 'safari';
        this.isIos = `${os}`.toLowerCase() === 'ios';
        this.isIosChrome =
            this.isIos && `${browser}`.toLowerCase().includes('chrome');

        this.state = {
            ...this.props.getInitialChatSettings(),
            volumeMuted: false,
            disableCameraSwitch: false,
            loading: true,
            isLocalVideoOnMainBlock: false,
            isChatOpen: false,
            isLandscape: window.innerWidth > window.innerHeight,
            isConsultationEnded: false,
            showReloadAudioControlTip:
                (this.isMobile || this.isTablet) &&
                (this.isSafari || this.isIosChrome),
            isReloadButtonProcessing: false,
            showChromeVolumeUpTip:
                this.isMobile &&
                !this.isIos &&
                (`${browser}`.toLowerCase().includes('chrome') ||
                    `${browser}`.toLowerCase().includes('samsung')),
        };
        this.resizeHandler = _.debounce(this.onWindowResize.bind(this), 500);
        this.removeAudioControlTipTimeout = null;
        this.smallVideo = React.createRef();
        this.bigVideo = React.createRef();
    }

    static propTypes = {
        room: PropTypes.object,
        paused: PropTypes.bool,
        consultant: PropTypes.object,
        localVideo: PropTypes.object,
        remoteVideo: PropTypes.object,
    };

    componentDidMount() {
        window.addEventListener('resize', this.resizeHandler);
    }

    componentDidUpdate(prevProps, prevState) {
        const {room, isSnapshotShown, handleLocalSnapshotClose} = this.props;
        const {room: prevRoom} = prevProps;
        const {loading} = this.state;
        const {loading: prevLoading} = prevState;
        if (this.state.showChromeVolumeUpTip && !loading && prevLoading) {
            setTimeout(() => {
                this.setState({showChromeVolumeUpTip: false});
            }, 15000);
        }

        if (this.state.showReloadAudioControlTip && !loading && prevLoading) {
            this.startRemoveAudioControlTipTimeout();
        }
        if (
            isSnapshotShown &&
            _.get(prevRoom, 'roomInfo.qr_snapshots', false) &&
            !_.get(room, 'roomInfo.qr_snapshots', false)
        ) {
            handleLocalSnapshotClose();
        }

        if (!prevProps.recognitionEnabled && this.props.recognitionEnabled) {
            this.hideAudioControlTipTimeout();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeHandler);
        if (this.removeAudioControlTipTimeout) {
            clearTimeout(this.removeAudioControlTip);
            this.removeAudioControlTip = null;
        }
        AzureSpeechRecognizer.stopAll(
            (workedBefore = true) => {
                if (!workedBefore) {
                    return;
                }
                IOController.socket.emit('azureStatistic_writeStatistic', {
                    type: 'end',
                    recognizerType: 'speech',
                });
            },
            (workedBefore = true) => {
                if (!workedBefore) {
                    return;
                }
                IOController.socket.emit('azureStatistic_writeStatistic', {
                    type: 'end',
                    recognizerType: 'translation',
                });
            }
        );
    }

    onWindowResize() {
        this.setState({isLandscape: window.innerWidth > window.innerHeight});
    }

    startRemoveAudioControlTipTimeout() {
        if (this.removeAudioControlTipTimeout) {
            clearTimeout(this.removeAudioControlTipTimeout);
        }
        this.removeAudioControlTipTimeout = setTimeout(() => {
            this.hideAudioControlTipTimeout();
        }, 10 * 1000);
    }

    hideAudioControlTipTimeout() {
        if (this.state.showReloadAudioControlTip) {
            this.setState({showReloadAudioControlTip: false});
        }

        if (this.removeAudioControlTipTimeout) {
            clearTimeout(this.removeAudioControlTipTimeout);
            this.removeAudioControlTipTimeout = null;
        }
    }

    sliceLastName(lastName) {
        if (lastName.length <= 1) {
            return lastName;
        }
        return lastName.slice(0, 1) + '.';
    }

    toggleVideo() {
        const {toggleVideo, videoAllowed} = this.props;
        if (!videoAllowed) return;
        toggleVideo();
        this.setState({videoEnabled: !this.state.videoEnabled});
    }

    toggleVoice() {
        const {toggleVoice} = this.props;
        toggleVoice();
        this.setState({voiceEnabled: !this.state.voiceEnabled});
    }

    toggleMuted() {
        this.setState({volumeMuted: !this.state.volumeMuted});
    }

    cantSwitchCamera() {
        return (
            this.state.disableCameraSwitch ||
            typeof window.RTCRtpSender !== 'function' ||
            typeof window.RTCRtpSender.prototype.replaceTrack !== 'function'
        );
    }

    switchCamera() {
        if (this.cantSwitchCamera() || !this.props.videoAllowed) {
            return;
        }
        this.setState({disableCameraSwitch: true}, () => {
            setTimeout(() => {
                this.props.switchCamera();
                this.setState({
                    isLocalVideoOnMainBlock:
                        !this.state.isLocalVideoOnMainBlock,
                });
            }, 300);
            setTimeout(() => this.setState({disableCameraSwitch: false}), 1000);
        });
    }

    makeSnapShot = () => {
        const {snapshotCanvas} = this;
        const {current: videoElement} = this.state.isLocalVideoOnMainBlock
            ? this.bigVideo
            : this.smallVideo;
        if (
            snapshotCanvas &&
            Boolean(snapshotCanvas.getContext) &&
            videoElement
        ) {
            snapshotCanvas.width = videoElement.videoWidth;
            snapshotCanvas.height = videoElement.videoHeight;
            const ctx = snapshotCanvas.getContext('2d');
            ctx.drawImage(videoElement, 0, 0);
            this.createImageStreamAndSend();
        }
    };

    createImageStreamAndSend = () => {
        const {snapshotCanvas} = this;
        const dataURL = snapshotCanvas.toDataURL('image/jpeg', 0.7);
        const {width, height} = snapshotCanvas;
        this.props.createSnapshot(
            {
                dataURL,
                info: {width, height},
                name: 'snapshot',
            },
            err => {
                if (err) {
                    console.error('error:', err);
                }
            }
        );
    };

    getToggleVoiceControl() {
        const {isConsultationEnded} = this.state;
        if (this.props.paused) return null;
        const className = classnames(
            'control-background',
            isConsultationEnded && 'disabled'
        );
        return (
            <div className={className} onClick={this.toggleVoice.bind(this)}>
                {this.state.voiceEnabled ? <Mic /> : <MicDisabled />}
            </div>
        );
    }

    getVoiceMuteControl() {
        const {isConsultationEnded} = this.state;
        if (this.props.paused) return null;
        const className = classnames(
            'control-background',
            isConsultationEnded && 'disabled'
        );
        return (
            <div className={className} onClick={this.toggleMuted.bind(this)}>
                {this.state.volumeMuted ? (
                    <VolumeDisabled />
                ) : (
                    <VolumeEnabled />
                )}
            </div>
        );
    }

    getToggleVideoControl() {
        const {isConsultationEnded} = this.state;
        const {videoAllowed, paused} = this.props;
        if (paused) return null;
        const className = classnames(
            'control-background',
            (!videoAllowed || isConsultationEnded) && 'disabled'
        );
        return (
            <div
                disabled={isConsultationEnded}
                onClick={this.toggleVideo.bind(this)}
                className={className}
            >
                {this.state.videoEnabled ? <Camera /> : <CameraDisabled />}
            </div>
        );
    }

    getCameraSwitchControl() {
        const {isConsultationEnded} = this.state;
        const {videoAllowed, paused} = this.props;
        const cantSwitchCamera = this.cantSwitchCamera();
        if (paused) return null;
        const className = classnames(
            'control-background',
            (!videoAllowed || cantSwitchCamera || isConsultationEnded) &&
                'disabled'
        );
        return (
            <div onClick={this.switchCamera.bind(this)} className={className}>
                <CameraReverse />
            </div>
        );
    }

    getSnapshotControl() {
        const {isConsultationEnded} = this.state;
        const {videoAllowed, paused} = this.props;
        if (paused || !this.isSnapshotsEnabled()) return null;
        const className = classnames(
            'control-background',
            (!videoAllowed ||
                !this.state.videoEnabled ||
                isConsultationEnded) &&
                'disabled'
        );
        return (
            <button className={className} onClick={this.makeSnapShot}>
                <SnapshotIcon />
            </button>
        );
    }

    onHangOutClick() {
        const {closeConsultation} = this.props;
        closeConsultation();
        this.setState({isConsultationEnded: true});
    }

    getHanoutButton(shouldBeDisabled = true) {
        const {isConsultationEnded, loading} = this.state;

        return (
            <HangoutButton
                disabled={loading && shouldBeDisabled}
                isConsultationEnded={isConsultationEnded}
                onClick={this.onHangOutClick.bind(this)}
            />
        );
    }

    isSnapshotsEnabled() {
        const {room, consultant} = this.props;

        return (
            _.get(room, 'roomInfo.qr_snapshots', false) &&
            !_.get(consultant, 'connectedWithMobileDevice', false)
        );
    }

    isQrChatEnabled() {
        const {
            room,
            template,
            consultant,
            isConsultantHasAccessToRequestedDepartment,
            isQrChatExists,
        } = this.props;

        return (
            _.get(room, 'roomInfo.qr_code_chat', false) &&
            _.get(template, 'isQrChatEnabled', false) &&
            (isConsultantHasAccessToRequestedDepartment || isQrChatExists)
        );
    }

    getChatControl() {
        const {isConsultationEnded} = this.state;
        const {chatDepartmentId, qrCodeChats} = this.props;
        if (!this.isQrChatEnabled()) {
            return null;
        }
        const chatWasRead = _.get(
            qrCodeChats,
            `${chatDepartmentId}.wasRead`,
            true
        );
        const className = classnames(
            'control-background',
            isConsultationEnded && 'disabled'
        );
        return (
            <div
                onClick={() => this.setState({isChatOpen: true})}
                className={className}
            >
                <ChatIcon />
                {!chatWasRead && (
                    <div className='chat-new-message-icon'>
                        <ChatNewMessagesIcon />
                    </div>
                )}
            </div>
        );
    }

    getStreamControls() {
        const {
            consultationType,
            paused,
            recognitionPhrases,
            recognitionEnabled,
            template,
        } = this.props;
        const {isLandscape, showReloadAudioControlTip, showChromeVolumeUpTip} =
            this.state;
        const hasFullScreenChat =
            template && this.isQrChatEnabled() && !this.isMobile;
        const voiceToTextWidth = hasFullScreenChat ? '75vw' : '100vw';
        if (consultationType === 'phone') {
            return (
                <div className='streamControls'>
                    <div className='control-line'>{this.getHanoutButton(false)}</div>
                </div>
            );
        }
        if (consultationType === 'audio') {
            return (
                <div className='streamControls'>
                    <VoiceToText
                        recognitionEnabled={recognitionEnabled}
                        recognitionPhrases={recognitionPhrases}
                        width={voiceToTextWidth}
                    />
                    {showReloadAudioControlTip && !paused && (
                        <div
                            onClick={this.reloadAudio.bind(this)}
                            className='description-wrapper'
                        >
                            <p>Audio issue? To refresh tap</p>
                            <ReloadAudioButton color={'#ffffff'} />
                        </div>
                    )}
                    {showChromeVolumeUpTip && !paused && (
                        <div
                            onClick={() =>
                                this.setState({showChromeVolumeUpTip: false})
                            }
                            className='description-wrapper'
                        >
                            <p>Please turn on your media volume</p>
                        </div>
                    )}
                    <div className='control-line'>
                        {(this.isTablet || this.isSafari || this.isMobile) &&
                            this.renderReloadAudioControls()}
                        {this.getToggleVoiceControl()}
                        {this.getHanoutButton()}
                        {this.getVoiceMuteControl()}
                        {this.isMobile && this.getChatControl()}
                    </div>
                </div>
            );
        }

        if (!this.isMobile) {
            return (
                <div className='streamControls'>
                    <VoiceToText
                        recognitionEnabled={recognitionEnabled}
                        recognitionPhrases={recognitionPhrases}
                        width={voiceToTextWidth}
                    />
                    {showReloadAudioControlTip && !paused && (
                        <div
                            onClick={this.reloadAudio.bind(this)}
                            className='description-wrapper'
                        >
                            <p>Audio issue? To refresh tap</p>
                            <ReloadAudioButton color={'#ffffff'} />
                        </div>
                    )}
                    {showChromeVolumeUpTip && !paused && (
                        <div
                            onClick={() =>
                                this.setState({showChromeVolumeUpTip: false})
                            }
                            className='description-wrapper'
                        >
                            <p>Please turn on your media volume</p>
                        </div>
                    )}
                    <div className='control-line'>
                        {(this.isTablet || this.isSafari) &&
                            this.renderReloadAudioControls()}
                        {this.getToggleVideoControl()}
                        {this.getCameraSwitchControl()}
                        {this.getSnapshotControl()}
                        {this.getHanoutButton()}
                        {this.getVoiceMuteControl()}
                        {this.getToggleVoiceControl()}
                    </div>
                </div>
            );
        }
        if (isLandscape) {
            return (
                <div className='streamControls'>
                    <VoiceToText
                        recognitionEnabled={recognitionEnabled}
                        recognitionPhrases={recognitionPhrases}
                        width={voiceToTextWidth}
                    />
                    {showReloadAudioControlTip && !paused && (
                        <div
                            onClick={this.reloadAudio.bind(this)}
                            className='description-wrapper'
                        >
                            <p>Audio issue? To refresh tap</p>
                            <ReloadAudioButton color={'#ffffff'} />
                        </div>
                    )}
                    {showChromeVolumeUpTip && !paused && (
                        <div
                            onClick={() =>
                                this.setState({showChromeVolumeUpTip: false})
                            }
                            className='description-wrapper'
                        >
                            <p>Please turn on your media volume</p>
                        </div>
                    )}
                    <div className='control-line'>
                        {this.renderReloadAudioControls()}
                        {this.getToggleVideoControl()}
                        {this.getCameraSwitchControl()}
                        {this.getSnapshotControl()}
                        {this.getHanoutButton()}
                        {this.getVoiceMuteControl()}
                        {this.getToggleVoiceControl()}
                        {this.getChatControl()}
                    </div>
                </div>
            );
        }
        return (
            <div className='streamControls'>
                <VoiceToText
                    recognitionEnabled={recognitionEnabled}
                    recognitionPhrases={recognitionPhrases}
                    width={voiceToTextWidth}
                />
                {showReloadAudioControlTip && !paused && (
                    <div
                        onClick={this.reloadAudio.bind(this)}
                        className='description-wrapper'
                    >
                        <p>Audio issue? To refresh tap</p>
                        <ReloadAudioButton color={'#ffffff'} />
                    </div>
                )}
                {showChromeVolumeUpTip && !paused && (
                    <div
                        onClick={() =>
                            this.setState({showChromeVolumeUpTip: false})
                        }
                        className='description-wrapper'
                    >
                        <p>Please turn on your media volume</p>
                    </div>
                )}
                <div className='control-line'>
                    {this.renderReloadAudioControls()}
                    {this.getCameraSwitchControl()}
                    {this.getSnapshotControl()}
                    {this.getChatControl()}
                </div>
                <div className='control-line'>
                    {this.getToggleVideoControl()}
                    {this.getVoiceMuteControl()}
                    {this.getToggleVoiceControl()}
                    {this.getHanoutButton()}
                </div>
            </div>
        );
    }

    getConsultantAvatar() {
        const {consultant} = this.props;
        if (!consultant) return;
        if (consultant.userInfo) {
            return consultant.userInfo.avatar;
        } else return null;
    }

    getConsultantInfo() {
        const {room, consultant, consultationType} = this.props;

        const screenShowOperatorTitle = true;
        // This option is not editable in QR codes for now
        // const screenShowOperatorTitle = room && room.screenSettings.screenShowOperatorTitle;
        if (
            !consultant ||
            !screenShowOperatorTitle ||
            consultationType === 'audio'
        )
            return null;
        return (
            <div className='consultantInfo'>
                <div>
                    {consultant.userInfo.first_name}{' '}
                    {this.sliceLastName(consultant.userInfo.last_name)}
                </div>
                <div>{consultant.userInfo.title_for_consultant}</div>
            </div>
        );
    }

    getAudioCallSection() {
        const {consultant, paused, consultationType} = this.props;
        if (consultationType !== 'audio' || paused || !consultant) return null;
        const avatar = consultant.userInfo.avatar;
        return (
            <div className='audioCallSection'>
                <div className='avatarSection'>
                    {avatar ? <img src={avatar} /> : <CallPhone />}
                </div>
                <div className='consultantName'>
                    {consultant.userInfo.first_name}{' '}
                    {consultant.userInfo.last_name}
                </div>
                <div className='titleForCons'>
                    {consultant.userInfo.title_for_consultant}
                </div>
            </div>
        );
    }

    sendQrChatMessage(message, cb) {
        const {consultant, sendQrChatMessageFromConsultation} = this.props;
        sendQrChatMessageFromConsultation(
            {...message, consultantId: consultant.id},
            cb
        );
    }

    renderReloadAudioControls() {
        const {isReloadButtonProcessing} = this.state;
        const {paused} = this.props;
        if (paused) {
            return null;
        }
        const buttonClassName = classnames(
            'reload-audio-button',
            isReloadButtonProcessing && 'loading'
        );
        return (
            <button
                className={buttonClassName}
                onClick={this.reloadAudio.bind(this)}
            >
                <ReloadAudioButton
                    color={isReloadButtonProcessing ? '#ffffff' : '#6d6f7b'}
                />
            </button>
        );
    }

    reloadAudio() {
        if (this.reloadAudioTimeout) {
            return;
        }
        if (this.removeAudioControlTipTimeout) {
            this.startRemoveAudioControlTipTimeout();
        }
        const {isLocalVideoOnMainBlock} = this.state;
        const usedElementRef = isLocalVideoOnMainBlock
            ? this.smallVideo
            : this.bigVideo;
        const {current: videoRef} = usedElementRef;
        if (!videoRef) {
            return;
        }
        this.setState({isReloadButtonProcessing: true});
        videoRef.pause();
        this.reloadAudioTimeout = setTimeout(() => {
            videoRef.play();
            videoRef.volume = 1;
            clearTimeout(this.reloadAudioTimeout);
            this.reloadAudioTimeout = null;
            this.setState({isReloadButtonProcessing: false});
        }, 300);
    }

    renderReloadAudioControls() {
        const {isReloadButtonProcessing} = this.state;
        const {paused} = this.props;
        if (paused) {
            return null;
        }
        const buttonClassName = classnames(
            'reload-audio-button',
            isReloadButtonProcessing && 'loading'
        );
        return (
            <button
                className={buttonClassName}
                onClick={this.reloadAudio.bind(this)}
            >
                <ReloadAudioButton
                    color={isReloadButtonProcessing ? '#ffffff' : '#6d6f7b'}
                />
            </button>
        );
    }

    renderSipAudio() {
        return (
            <div className='consultationContainer'>
                <div className='gradientTop' />
                <div className='gradientBottom' />
                <div className='audioOnlyPlaceholder'>Voice</div>
                {this.getConsultantInfo()}
                {this.getAudioCallSection()}
                {this.getStreamControls()}
                <Logo room={{roomInfo: {}}} position='bottomleft' />
            </div>
        );
    }

    render() {
        const {
            room,
            paused,
            localStream,
            remoteStream,
            remoteVideoOn,
            consultationType,
            user,
            template,
            consultant,
            snapshot,
            isSnapshotShown,
            handleLocalSnapshotClose,
            snapshotDrawings,
            snapshotSize,
            department,
            inWaitingToCallState,
        } = this.props;
        const {
            loading,
            videoEnabled,
            volumeMuted,
            disableCameraSwitch,
            isLocalVideoOnMainBlock,
            isChatOpen,
            isConsultationEnded,
        } = this.state;

        const isAudioOnly = consultationType === 'audio';

        const bigVideoBlockClassName = classnames('bigVideoBlock');
        const smallVideoBlockClassName = classnames(
            'smallVideoBlock',
            disableCameraSwitch && 'faded'
        );

        const chatContainerClassName = classnames(
            'chat-container',
            !this.isMobile && 'chat-right'
        );
        const qrChatEnabled = this.isQrChatEnabled();
        const consultationContainerClassName = classnames(
            'consultationContainer',
            template && !this.isMobile && qrChatEnabled && 'with-chat-right'
        );
        const shouldRenderChat =
            !inWaitingToCallState &&
            template &&
            (!this.isMobile || isChatOpen) &&
            qrChatEnabled;

        if (consultationType === 'phone') {
            return this.renderSipAudio();
        }

        const allowLeavePhoneNumber = room?.roomInfo.allow_leave_phone_number;
        return (
            <div className={consultationContainerClassName}>
                {shouldRenderChat && (
                    <div className={chatContainerClassName}>
                        <Chat
                            room={room}
                            user={user}
                            showConsultationButtons={false}
                            onChatClose={() =>
                                this.setState({isChatOpen: false})
                            }
                            showBackButton={this.isMobile}
                            onMessageSend={this.sendQrChatMessage.bind(this)}
                        />
                    </div>
                )}
                <LoadingOverlay
                    department={department}
                    loading={loading}
                    withChat={shouldRenderChat}
                >
                    {/* <LeaveMessageButton 
                        onClick={() => toSendingPhoneNumber()}
                        display={room.hasAvailableMembers && allowLeavePhoneNumber && !didSendPhoneNumber}
                    /> */}
                    {!inWaitingToCallState && (
                        <HangoutButton
                            disabled={loading}
                            isConsultationEnded={isConsultationEnded}
                            onClick={this.onHangOutClick.bind(this)}
                        />
                    )}
                </LoadingOverlay>
                {paused && (
                    <div className='pauseOverlay'>
                        <Loading>
                            <PauseIcon />
                        </Loading>
                        <div>Please hold for a moment...</div>
                    </div>
                )}
                {!inWaitingToCallState && (
                    <VideoStream
                        className={classnames(
                            bigVideoBlockClassName,
                            consultationType === 'audio' && 'hidden'
                        )}
                        ref={this.bigVideo}
                        stream={
                            isLocalVideoOnMainBlock ? localStream : remoteStream
                        }
                        placeholderImage={
                            !!isLocalVideoOnMainBlock
                                ? null
                                : this.getConsultantAvatar()
                        }
                        onCanPlay={() =>
                            this.setState({loading: false}, () => {
                                if (this.isSafari || this.isIosChrome) {
                                    this.reloadAudio();
                                }
                            })
                        }
                        muted={!!isLocalVideoOnMainBlock ? true : volumeMuted}
                        videoEnabled={
                            isLocalVideoOnMainBlock
                                ? this.state.videoEnabled
                                : remoteVideoOn
                        }
                    />
                )}
                <div className='gradientTop' />
                <div className='gradientBottom' />
                {consultationType !== 'audio' && !inWaitingToCallState && (
                    <VideoStream
                        className={smallVideoBlockClassName}
                        ref={this.smallVideo}
                        stream={
                            isLocalVideoOnMainBlock ? remoteStream : localStream
                        }
                        placeholderImage={
                            isLocalVideoOnMainBlock
                                ? this.getConsultantAvatar()
                                : null
                        }
                        muted={!!isLocalVideoOnMainBlock ? volumeMuted : true}
                        videoEnabled={
                            isLocalVideoOnMainBlock
                                ? remoteVideoOn &&
                                  consultationType.includes('video')
                                : this.state.videoEnabled &&
                                  consultationType.includes('video')
                        }
                    />
                )}
                {this.getConsultantInfo()}
                {this.getAudioCallSection()}
                {/* {!loading && this.renderReloadAudioControls()} */}
                {!loading && this.getStreamControls()}
                {inWaitingToCallState && <RecordingNotification className='recording-notifcation'/>}
                <Logo room={room} position='bottomleft' />
                {!inWaitingToCallState && (
                    <SnapshotComponent
                        snapshot={snapshot}
                        show={isSnapshotShown}
                        snapshotSize={snapshotSize}
                        snapshotDrawings={snapshotDrawings}
                        onClose={handleLocalSnapshotClose}
                        extraClassName={classnames(
                            !this.isMobile && qrChatEnabled && 'with-chat-right'
                        )}
                        ref={ref => (this.snapshotCanvas = ref && ref.canvas)}
                    />
                )}
            </div>
        );
    }
}

const getIsQrChatExists = consultationState => {
    const {chatDepartmentId, qrCodeChats} = consultationState;
    if (!chatDepartmentId || !qrCodeChats) {
        return false;
    }
    return !!qrCodeChats[chatDepartmentId];
};

const getDepartment = (departmentsState, consultationState) => {
    if (consultationState.chatDepartmentId && departmentsState.departments) {
        return departmentsState.departments.find(
            d => d.id === consultationState.chatDepartmentId
        );
    }
    return null;
};

export default props => (
    <ScreenConsumer>
        {({state: screenState}) => (
            <ConsultationConsumer>
                {({state: consultationState, actions: consultationActions}) => (
                    <TemplateConsumer>
                        {({state: templateState}) => (
                            <DepartmentsConsumer>
                                {({state: departmentsState}) => (
                                    <Consultation
                                        room={screenState.room}
                                        getIsQrChatExists
                                        user={screenState.user}
                                        template={templateState.template}
                                        isQrChatExists={getIsQrChatExists(
                                            consultationState
                                        )}
                                        {...consultationState}
                                        {...consultationActions}
                                        {...props}
                                        department={getDepartment(
                                            departmentsState,
                                            consultationState
                                        )}
                                    />
                                )}
                            </DepartmentsConsumer>
                        )}
                    </TemplateConsumer>
                )}
            </ConsultationConsumer>
        )}
    </ScreenConsumer>
);

import React, {Component} from 'react';
import CheckboxIcon from '../../assets/icons/Checkbox';

import './CheckBox.scss';

class Checkbox extends Component {
    toggleCheck() {
        const {onToggle} = this.props;
        if (onToggle) onToggle();
    }

    render() {
        const {label, checked} = this.props;
        
        return (
            <div className="wc-checkbox-container">
                <div className={`wc-checkbox ${checked ? 'checked' : ''}`} onClick={this.toggleCheck.bind(this)}>
                    <CheckboxIcon/>
                </div>
                <div className="wc-checkbox-label" onClick={this.toggleCheck.bind(this)}>
                    {label}
                </div>
            </div>
        );
    }
}

export default Checkbox;

import React from 'react';
import classnames from 'classnames';
import Back from '../../assets/button-chat-back.svg';
import Video from '../../assets/button-video.svg';
import Audio from '../../assets/button-audio.svg';
import AudioDisabled from '../../assets/button-audio-disabled.svg';
import VideoDisabled from '../../assets/button-video-disabled.svg';
import './ChatHeader.scss';

const renderOperatorInfoOrChatSign = (operator, showConsultationButtons, chatAi) => {
    if (chatAi) {
        return <h1>AI Assistant</h1>
    }
    if (!operator || !operator.userInfo) {
        return (
            <h1>Chat</h1>
        )
    }
    const {userInfo} = operator;
    const lastNameFirstLetter = userInfo.last_name ? `${userInfo.last_name[0]}.` : '';
    return (
        <div className={classnames('chat-header-operator-info', !showConsultationButtons && 'without-buttons')}>
            {userInfo.avatar && <img src={userInfo.avatar}/>}
            <h1>{`${userInfo.first_name} ${lastNameFirstLetter}`}</h1>
        </div>
    )
}

export default props => {
    
    const {
        onChatClose,
        requestConsultationFromChat, 
        isChatAssigned,
        isVideoConsultationEnabled,
        isAudioConsultationEnabled,
        showConsultationButtons,
        showBackButton,
        operator,
        chatAi,
    } = props;

    const isAudioDisabled = !isChatAssigned || chatAi;
    const isVideoDisabled = !isChatAssigned || chatAi;

    const audioButtonClassName = classnames('chat-header-consultation-button', {disabled: isAudioDisabled});
    const videoButtonClassName = classnames('chat-header-consultation-button', {disabled: isVideoDisabled});
    const AudioButton = isAudioDisabled ? AudioDisabled : Audio;
    const VideoButton = isVideoDisabled ? VideoDisabled : Video;
    
    return (
        <div className='chat-header-container'>
            {showBackButton && <div
                onClick={onChatClose}
                className='chat-header-button-back'
            >
                <img src={Back}/>
            </div>}
            {renderOperatorInfoOrChatSign(operator, showConsultationButtons, chatAi)}
            {showConsultationButtons && <div className='chat-header-buttons-wrapper'>
               {isAudioConsultationEnabled && <div className={audioButtonClassName} onClick={requestConsultationFromChat.bind(null, 'audio')}>
                    <img src={AudioButton}/>
                </div>} 
               {isVideoConsultationEnabled && <div className={videoButtonClassName} onClick={requestConsultationFromChat.bind(null, 'audiovideo')}>
                    <img src={VideoButton}/>
                </div>}
            </div>}
        </div>
    );
};

import React, {Component} from 'react';
import classnames from 'classnames';
import './Button.scss';

class Button extends Component {
    render() {
        const {className, type, children, ...rest} = this.props;

        return (
            <div className={classnames('qrCodeButton', `button-${type}`, className)} {...rest}>
                {children}
            </div>
        );
    }
}

export default Button;
import React from 'react';
import classnames from 'classnames';
import './KeyboardButton.scss';

interface KeyboardButtonProps {
    children: React.ReactNode;
    className?: string;
    buttonColor: string;
    onClick?: () => any;
    style?: object;
    visibility?:string
}

export default function KeyboardButton(props: KeyboardButtonProps) {
    const {
        children,
        className,
        buttonColor = '#FFFFFF',
        onClick,
        style,
        visibility = 'visible',
    } = props;
    return (
        <button onClick={onClick} style={{backgroundColor: buttonColor, visibility: visibility, ...style}} className={classnames('keyboard-button', className)}>
            {children}
        </button>
    );
}

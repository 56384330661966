import React, {Component} from 'react';
import classnames from 'classnames';
import Attachment from '../../assets/icon-chat-attachment.svg';
import Send from '../../assets/icon-chat-send.svg';
import Textarea from 'react-textarea-autosize';
import './ChatFooter.scss';
import MobileDetect from "mobile-detect";
import {getBrowserOs} from "../../utils/browserFeatures";
import {isBlank} from "../../utils/validationUtils";
import { v4 } from 'uuid';
import Button from "../../components/Button/Button";
import QrChatAttachmentsView from './QrChatAttachmentsView';
import ClosablePopUpError from '../../components/ClosablePopUpError/ClosablePopUpError';
import _ from 'lodash';

const ALLOWING_ATTACHMENTS_TYPES = ['jpg', 'jpeg', 'png', 'pdf'];
const MAX_ALLOWING_FILES_SIZE = 100; //MB
const MAX_NUMBER_OF_ATTACHMENTS = 10;
const inputId = v4()

class ChatFooter extends Component {
    constructor(props) {
        super(props);
        this.isMobile = Boolean((new MobileDetect(window.navigator.userAgent)).mobile());
        const platform = navigator?.userAgentData?.platform || navigator?.platform
        this.isIpad = (platform === 'MacIntel' && navigator.maxTouchPoints > 0) || platform === 'iPad';
        this.state = {
            isClosablePopupErrorOpen: false,
            error: '',
        };
        this.errorTimerId = null;
    }

    componentWillUnmount() {
        if (this.errorTimerId) {
            clearTimeout(this.errorTimerId);
        }
    }

    handleKeyPress = (e) => {
        const {onMessageSend} = this.props;
        if (!this.isMobile && e.key === 'Enter' && !e.shiftKey) {
            onMessageSend();
            e.preventDefault();
        }
    };

    addOutTouchListener = () => {
        const isMobileIOS = getBrowserOs() === 'iOS' && this.isMobile;
        if (!isMobileIOS) {
            return;
        }
        const htmlElement = document.getElementsByTagName('html')[0];
        htmlElement.addEventListener('touchmove', this.handleClickOutside);
    };

    handleClickInside = (e) => {
        e.stopPropagation();
    };

    handleClickOutside = () => {
        const isMobileIOS = getBrowserOs() === 'iOS' && this.isMobile;
        if (!isMobileIOS) {
            return;
        }
        this.input && this.input.blur();
        const htmlElement = document.getElementsByTagName('html')[0];
        htmlElement.removeEventListener('touchmove', this.handleClickOutside);
    };

    onAttachmentChange(e) {
        const [newFile] = e.target.files;
        if (!newFile) {
            return;
        }
        const {attachments: attachmentsObj, onAttachmentsChange} = this.props;
        const {attachments, attachmentsUploadedProgress} = attachmentsObj;
        const allFilesSizeInBytes = attachments.reduce((acc, a) => acc + a.file.size, 0);
        if (!ALLOWING_ATTACHMENTS_TYPES.some(a => newFile.type.endsWith(a))) {
            e.target.value = '';
            const allowedTypes = ALLOWING_ATTACHMENTS_TYPES.map(t => `.${t}`).join(', ');
            this.showError(`Only ${allowedTypes} are supported`);
            return;
        }
        if ((allFilesSizeInBytes + newFile.size) / 1024 / 1024 > MAX_ALLOWING_FILES_SIZE) {
            e.target.value = '';
            this.showError(`Max allowed files size is ${MAX_ALLOWING_FILES_SIZE}mb`);
            return;
        }
        if (!!attachments.find(a => newFile.name === a.name && newFile.lastModified === a.file.lastModified)) {
            return;
        }
        const newAttachments = [
            ...attachments,
            {
                name: newFile.name,
                file: newFile
            }
        ];
        const newAttachmentsUploadedProgress = attachmentsUploadedProgress.slice();
        newAttachmentsUploadedProgress.push(0);
        onAttachmentsChange({
            ...attachmentsObj,
            attachmentsUploadedProgress: newAttachmentsUploadedProgress,
            attachments: newAttachments,
        });
        e.target.value = '';
    }

    onAttachmentChooseClick(e) {
        const {attachments: attachmentsObj, isAttachmentsUploads} = this.props;
        const {attachments} = attachmentsObj;
        if (attachments.length === MAX_NUMBER_OF_ATTACHMENTS) {
            this.showError(`Max amount of files is ${MAX_NUMBER_OF_ATTACHMENTS}`);
            return;
        }
        if (this.attachmentInput && !isAttachmentsUploads) {
            this.attachmentInput.click();
        }
    }

    onAttachmentDelete(idx) {
        const {attachments: attachmentsObj, onAttachmentsChange} = this.props;
        const {attachments, attachmentsUploadedProgress} = attachmentsObj;
        const newAttachments = attachments.slice();
        newAttachments.splice(idx, 1);
        const newAttachmentsUploadedProgress = attachmentsUploadedProgress.slice();
        newAttachmentsUploadedProgress.splice(idx, 1);
        onAttachmentsChange({
            attachments: newAttachments,
            attachmentsUploadedProgress: newAttachmentsUploadedProgress,
        });
    }

    showError(error) {
        if (this.errorTimerId) {
            clearTimeout(this.errorTimerId);
            this.errorTimerId = null;
        }
        const setTimer = () => {
            this.errorTimerId = setTimeout(
                this.hideError.bind(this),
                5000
            );
        };
        this.setState({
            error,
            isClosablePopupErrorOpen: true
        }, setTimer);
    }

    hideError() {
        if (this.errorTimerId) {
            clearTimeout(this.errorTimerId);
            this.errorTimerId = null;
        }
        this.setState({
            error: '',
            isClosablePopupErrorOpen: false
        });
    }

    onClosablePopupErrorClose() {
        this.hideError();
    }

    render() {
        const {
            value, 
            onMessageChange, 
            onMessageSend, 
            onHeightChange, 
            attachments: attachmentsObj, 
            isAttachmentsUploads,
            chatAi,
        } = this.props;
        const {isClosablePopupErrorOpen, error} = this.state;
        const {attachments, attachmentsUploadedProgress} = attachmentsObj;
        const sendButtonClassName = classnames(
            'chat-footer-button-send', 
            'chat-controlbtn',
            ((isBlank(value) && !attachments.length) || isAttachmentsUploads) && 'disabled'
        );
        return (
            <div className={classnames('chat-footer-container-outer', {'chat-ai': chatAi})}>
                <input
                    ref={r => (this.attachmentInput = r)}
                    style={{display: 'none'}}
                    type='file'
                    accept={ALLOWING_ATTACHMENTS_TYPES.map(a => `.${a}`).join(', ')}
                    onChange={this.onAttachmentChange.bind(this)}
                />
                <QrChatAttachmentsView
                    attachments={_.zip(
                        attachments,
                        attachmentsUploadedProgress
                    ).map(([a, p]) => ({...a, uploadingProgress: p}))}
                    attachmentsUploads={isAttachmentsUploads}
                    onItemDelete={this.onAttachmentDelete.bind(this)}
                />
                <div className={classnames('chat-footer-container', window.navigator.standalone && this.isIpad && 'ipad-screenkeyboard-fix')}>
                    {!chatAi && <div className='chat-controlbtn'>
                    <ClosablePopUpError
                            isOpen={isClosablePopupErrorOpen}
                            onClose={this.onClosablePopupErrorClose.bind(this)}
                            errorText={error}
                            top
                            left={true}
                            title='Error:'
                            style={{marginLeft: '10px'}}
                        />
                        <div
                            onClick={this.onAttachmentChooseClick.bind(this)}
                            className='chat-footer-button-attachments'
                        >
                            <img src={Attachment}/>
                        </div>
                    </div>}
                    <Textarea
                        disabled={isAttachmentsUploads}
                        value={value}
                        inputRef={ref => this.input = ref}
                        onChange={onMessageChange}
                        onTouchMove={this.handleClickInside}
                        onKeyPress={this.handleKeyPress}
                        onFocus={this.addOutTouchListener}
                        onHeightChange={onHeightChange}
                        placeholder={chatAi ? "Ask AI assistant a question" : undefined}
                        maxLength={chatAi ? 500 : undefined}
                        maxRows={6}
                        id={inputId}
                        className={classnames('chat-footer-input')}/>
                    <Button
                        className={sendButtonClassName}
                        onClick={onMessageSend}
                    >
                        <img src={Send}/>
                    </Button>
                </div>
            </div>
        );
    }
};

export default ChatFooter;
import React from 'react';
import './OperatorOfflineModal.scss';
import Modal from '../../components/Modal/Modal';

const OperatorOfflineModal = props => {
    const {toStartState, sendConsultationRequestSignalToOnlineMembers, requestedOperatorName} = props;
    const title = `${requestedOperatorName} is offline. Do you want to connect with other operator or try later?`;
    return (
        <Modal
            title={title}
            contentClassName='operator-offline-modal-content'
            show={() => {}}
            withoutScroll
        >
            <div className='operator-offline-modal-content'>
                <div
                    className='operator-offline-modal-button-primary'
                    onClick={sendConsultationRequestSignalToOnlineMembers}
                >
                    Connect now
                </div>
                <div className='operator-offline-modal-button-secondary' onClick={toStartState}>Try later</div>
            </div>
        </Modal>
    );
};

export default OperatorOfflineModal;

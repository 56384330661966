import React from 'react';

function MarkIcon({className}) {
    return (
        <svg viewBox="0 0 38 38" width="38" height="38" className={className}>
            <path
                fill="#FFFFFF"
                fillRule="evenodd"
                d="M16.6 19.4l-4-2.4-1.6 1.6 5.6 6.4L27 14.6 25.4 13l-8.8 6.4z"
                clipRule="evenodd"
            />
        </svg>
    );
}

export default MarkIcon;

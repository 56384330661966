import React, {Component} from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import logoImage from '../../assets/logo.svg';
import CallButtons from './CallButtons';
import './TextScreen.scss';
import {Scrollbars} from 'react-custom-scrollbars-2';
import MobileAgent from 'mobile-detect';

class TextScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            device: this.getDeviceType(),
        };
        this.isSafari = (new MobileAgent(window.navigator.userAgent)).userAgent() === 'Safari';
    }

    componentDidMount() {
        this.onResize = _.debounce(this.onScreenResize.bind(this), 100);
        const {previewType} = this.props;
        if (previewType === undefined) {
            window.addEventListener('resize', this.onResize);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    getDeviceType() {
        return window.innerHeight > window.innerWidth ? 'mobile' : 'desktop';
    }

    onScreenResize() {
        this.setState({device: this.getDeviceType()});
    }

    getImage() {
        const {previewType, screen} = this.props;
        const getImageByDevice = device => device === 'desktop' ? screen.settings.options.desktopImage : screen.settings.options.image;
        if (previewType !== undefined) {
            return getImageByDevice(previewType);
        } else {
            const {device} = this.state;
            return getImageByDevice(device);
        }
    }

    getAutoHeightMaxForScrollbar() {
        const {previewType} = this.props;
        if (previewType === 'mobile') {
            return '55vh';
        }
        if (this.getDeviceType() === 'mobile') {
            // 80 px - padding from top; 3 rem - buttons positioning at bottom.
            return 'calc(75vh - 85px - 3rem)';
        }
        return '75vh';
    }

    consultationRequestHandler(type) {
        const {screen, requestConsultationWithType, requestPhoneConsultation} = this.props;
        if (screen.settings.whereToCall === 'phone' && type === 'audio') {
            return requestPhoneConsultation(screen.settings.phone);
        }
        requestConsultationWithType(type);
    }

    onTouchMove() {
        // webkit-overflow-scrolling: touch at safari cause freezes when user start scroll top at top and scroll bottom at bottom.
        // This hack resolve it.
        // https://stackoverflow.com/questions/44815543/ios-10-uncertain-freezing-during-scroll-of-html-list-styled-with-webkit-overf
        if (this.isSafari) {
            document.body.scrollTop = 0;
        }
    }

    renderScrollbarsView({style, ...props}) {
        return (
            <div onTouchMove={this.onTouchMove.bind(this)} {...props} style={{...style, marginRight: 0}}/>
        )
    }

    consultationRequestHandler(type) {
        const {screen, requestConsultationWithType, requestPhoneConsultation} = this.props;
        if (screen.settings.whereToCall === 'phone' && type === 'audio') {
            return requestPhoneConsultation(screen.settings.phone);
        }
        requestConsultationWithType(type);
    }

    render() {
        const {
            screen,
            room,
            isVideoConsultationEnabled,
            isAudioConsultationEnabled,
            requestChat,
            requestAIChat,
            consultationRequestProgress,
            logo,
            departmentsState,
            showChatWasNotReadIcon,
            isQrChatEnabled,
            onQrLogoClick,
            isCallButtonsExpanded,
        } = this.props;

        const text = screen.settings.options.message;
        const image = this.getImage();
        const {settings} = screen;
        const buttonColor = screen.settings.buttonColor;
        const backgroundColor = screen.settings.backgroundColor;
        const buttonsContainerClassName = classnames('mn-call-buttons-container', screen.settings.buttonTopRight && 'top-right', 'mw-call-buttons-opened ');
        const showTeleportivityLogo = room && room.roomInfo.powered_by_logo;
        const shouldHideUploadedLogo = screen.settings.buttonTopRight && isCallButtonsExpanded;
        const uploadedLogoClassName = classnames('mw-uploaded-logo', shouldHideUploadedLogo && 'withExpandedCallButtons');

        return (
            <div className="mw-screen-container mw-text-screen" style={{background: backgroundColor}}>
                {image && (
                    <div className="image-container">
                        <img src={image.url ? image.url : URL.createObjectURL(image.file)}/>
                    </div>
                )}
                {logo && <div onClick={onQrLogoClick} className={uploadedLogoClassName}><img src={logo.url}/></div>}
                <div className="text-content">
                    <Scrollbars
                        renderView={this.renderScrollbarsView.bind(this)}
                        className="scrollbars"
                        autoHeight
                        autoHeightMax={this.getAutoHeightMaxForScrollbar()}
                    >
                        <div className="text-overflow">
                            <div className="screen-text" style={{color: screen.settings.textColor || 'white'}}>
                                {text}
                            </div>
                        </div>
                    </Scrollbars>
                    <div className={buttonsContainerClassName}>
                        <CallButtons
                            isVideoConsultationEnabled={isVideoConsultationEnabled && settings.isVideoConsultationEnabled}
                            isAudioConsultationEnabled={isAudioConsultationEnabled && settings.isAudioConsultationEnabled}
                            requestChat={requestChat}
                            requestAIChat={requestAIChat}
                            requestConsultationWithType={this.consultationRequestHandler.bind(this)}
                            consultationRequestProgress={consultationRequestProgress}
                            color={buttonColor}
                            forceBigIcons={!screen.settings.buttonTopRight}
                            departmentsState={departmentsState}
                            showChatWasNotReadIcon={showChatWasNotReadIcon}
                            isQrChatEnabled={isQrChatEnabled && settings.isQrChatEnabled}
                            logo={logo}
                            isInHeader={screen.settings.buttonTopRight}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default TextScreen;

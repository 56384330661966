import React from 'react';

export default (props) => {
    return (
        <svg viewBox="0 0 60 60" {...props}>
            <ellipse cx="30.152" cy="30.158" fill="#FDCA47" rx="28.152" ry="28.158"/>
            <ellipse cx="40.944" cy="21.241" fill="#302C3B" rx="4.223" ry="6.57"/>
            <ellipse cx="19.361" cy="21.241" fill="#302C3B" rx="4.223" ry="6.57"/>
            <path fill="#302C3B"
                  d="M47.982 35.591c0-.838-.496-1.887-1.911-2.156-3.633-.69-9-1.4-15.919-1.4h-.001c-6.916 0-12.285.71-15.917 1.4-1.415.27-1.911 1.318-1.911 2.156 0 7.582 5.884 15.215 17.828 15.215h.001c11.946 0 17.83-7.633 17.83-15.215z"/>
            <path fill="#F46C60"
                  d="M22.645 47.129c2.17.986 4.673 1.566 7.507 1.566 2.835 0 5.338-.58 7.508-1.566-2.093-1.069-4.586-1.72-7.508-1.72-2.92 0-5.412.651-7.507 1.72z"/>
            <path fill="#FFF"
                  d="M43.763 35.824c-2.353-.354-7.334-.974-13.61-.974-6.278 0-11.257.62-13.612.974-1.388.207-1.472.713-1.376 1.426.058.424.146.939.283 1.49.152.613.274.774 1.358.786 2.085.025 24.607.025 26.693 0 1.084-.012 1.202-.173 1.356-.785.136-.552.227-1.067.282-1.49.1-.714.011-1.22-1.374-1.427z"/>
        </svg>
    );
}
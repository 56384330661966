import React, {Component} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {FixedSizeGrid} from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import {TemplateConsumer} from '../../contexts/Template';
import {DepartmentsConsumer} from '../../contexts/Departments';
import {ConsultationConsumer} from '../../contexts/Consultation';
import {ScreenConsumer} from '../../contexts/Screen';
import Chevron from '../../assets/icons/Chevron';
import DepartmentsBack from '../../assets/icons/DepartmentsBack';
import ChatNewMessagesIcon from '../../assets/icons/ChatNewMessages';
import {Scrollbars} from 'react-custom-scrollbars-2';

import './DepartmentsList.scss';
import Logo from '../../components/Logo/Logo';

const GUTTER_SIZE_FOR_GRID = 10;
const GUTTER_SIZE_FOR_LIST = 30;

class DepartmentsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
        };
        this.resizeHandler = _.debounce(this.onWindowResize.bind(this), 500);
    }

    static propTypes = {
        departments: PropTypes.array.isRequired,
        consultationAction: PropTypes.object.isRequired,
    };

    componentDidMount() {
        window.addEventListener('resize', this.resizeHandler);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.departments.length > 1) return;

        this.props.consultationAction.toStartState();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeHandler);
    }

    onWindowResize() {
        this.setState({windowWidth: window.innerWidth});
    }

    handleDepartmentClick(department) {
        const {isChat, consultationAction} = this.props;
        const {sendConsultationRequestSignal, toSendingPhoneNumber, openChat} =
            consultationAction;
        if (isChat) {
            openChat(department);
            return;
        }
        const isDepartmentAvailableOrSipEnabled =
            department.hasAvailableMembers ||
            (department.callToPhone && department.phone);
        if (isDepartmentAvailableOrSipEnabled) {
            const params = {departmentId: department.id};
            if (department.callToPhone && department.phone) {
                params.departmentNumber = department.number;
            }
            sendConsultationRequestSignal(params);
            return;
        }

        toSendingPhoneNumber({departmentId: department.id});
    }

    renderThumbVertical({style, ...props}) {
        return (
            <div
                {...props}
                style={{
                    ...style,
                    cursor: 'pointer',
                    borderRadius: 'inherit',
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                }}
                onMouseDown={e => e.stopPropagation()}
                onClick={e => e.stopPropagation()}
            ></div>
        );
    }

    renderNewChatMessageIcon() {
        return (
            <div className='chat-new-message-icon'>
                <ChatNewMessagesIcon />
            </div>
        );
    }

    renderDepartment = (department, index, style) => {
        const {
            room,
            template,
            isChat,
            companyHasAvailableMinutes,
            consultant,
            qrCodeChats,
        } = this.props;
        const wasChatInDepartmentRead = _.get(
            qrCodeChats,
            `${department.id}.chat.wasRead`,
            true
        );
        const isQrChatEnabled =
            _.get(room, 'roomInfo.qr_code_chat', false) &&
            _.get(template, 'isQrChatEnabled', false) &&
            !_.get(consultant, 'connectedWithMobileDevice', false);
        const shouldShowNewMessageIcon =
            !wasChatInDepartmentRead &&
            isQrChatEnabled &&
            isChat &&
            department.hasAvailableMembers;
        const isDepartmentAvailableOrSipEnabled =
            department.hasAvailableMembers ||
            (department.callToPhone && department.phone);
        const indicatorClassName = classnames(
            'indicator',
            isDepartmentAvailableOrSipEnabled &&
                companyHasAvailableMinutes &&
                'active'
        );
        const itemClassName = classnames(
            'departmentItem',
            !isDepartmentAvailableOrSipEnabled && 'blocked'
        );
        return (
            <div
                style={style}
                key={index}
                className={itemClassName}
                onClick={this.handleDepartmentClick.bind(this, department)}
            >
                {shouldShowNewMessageIcon && this.renderNewChatMessageIcon()}
                <div className='departmentName'>
                    <div className={indicatorClassName} />
                    <div className='name'>{department.name}</div>
                </div>
                    <div className='departmentDescription'>
                        {department.description}
                    </div>
                {isDepartmentAvailableOrSipEnabled && (
                    <Chevron className='chevron' />
                )}
            </div>
        );
    };

    getDepartmentsList(height, width) {
        const {windowWidth} = this.state;
        const {departments} = this.props;
        if (windowWidth >= 1024) {
            return (
                <FixedSizeGrid
                    height={height - 80}
                    width={width}
                    itemCount={departments.length}
                    rowCount={Math.ceil(departments.length / 2)}
                    columnCount={2}
                    columnWidth={width / 2 - 10}
                    rowHeight={140}
                >
                    {({columnIndex, rowIndex, style}) => {
                        const newStyle = {
                            ...style,
                            left: style.left + GUTTER_SIZE_FOR_GRID,
                            top: style.top + GUTTER_SIZE_FOR_GRID,
                            width: style.width - GUTTER_SIZE_FOR_GRID,
                            height: style.height - GUTTER_SIZE_FOR_GRID,
                        };
                        const index = parseInt(rowIndex * 2) + columnIndex;
                        const department = departments[index];
                        if (!department) {
                            return null;
                        }
                        return this.renderDepartment(
                            department,
                            index,
                            newStyle
                        );
                    }}
                </FixedSizeGrid>
            );
        }

        return (
            <FixedSizeGrid
                height={height - 80}
                width={width}
                itemCount={departments.length}
                rowCount={departments.length}
                columnCount={1}
                columnWidth={width - 2 * GUTTER_SIZE_FOR_LIST}
                rowHeight={100}
            >
                {({columnIndex, rowIndex, style}) => {
                    const newStyle = {
                        ...style,
                        left: style.left + GUTTER_SIZE_FOR_LIST,
                        top: style.top + GUTTER_SIZE_FOR_LIST,
                        width: style.width - GUTTER_SIZE_FOR_LIST,
                        height: style.height - GUTTER_SIZE_FOR_LIST,
                    };
                    const index = rowIndex + columnIndex;
                    const department = departments[index];
                    return this.renderDepartment(department, index, newStyle);
                }}
            </FixedSizeGrid>
        );
    }

    render() {
        const {room, template, miniWebsiteScreen, isChat} = this.props;
        const {toStartState, closeChat} = this.props.consultationAction;

        const screen = template.screens[miniWebsiteScreen];
        const {
            isVideoConsultationEnabled,
            isAudioConsultationEnabled,
            isQrChatEnabled,
        } = screen.settings;
        const anyConsultationEnabled =
            isVideoConsultationEnabled ||
            isAudioConsultationEnabled ||
            isQrChatEnabled;
        const backgroundColor =
            (anyConsultationEnabled
                ? screen.settings.backgroundColor
                : '#5465e6') || '#2f3243';
        return (
            <div className='departmentsContainer' style={{backgroundColor}}>
                <div className='departmentTitle'>
                    Please, select a call group for call
                </div>
                <div className='departmentsInnerContainer'>
                    <div className='departmentItems'>
                        <AutoSizer>
                            {({height, width}) =>
                                this.getDepartmentsList(height, width)
                            }
                        </AutoSizer>
                    </div>
                </div>
                <div
                    className='departmentsGoBack'
                    style={{
                        backgroundImage: `linear-gradient(to bottom, rgba(84, 101, 230, 0), ${backgroundColor}`,
                    }}
                >
                    <DepartmentsBack
                        onClick={isChat ? closeChat : toStartState}
                    />
                </div>
                <Logo room={room} position='bottomleft' withLink />
            </div>
        );
    }
}

export default props => (
    <ScreenConsumer>
        {({state: screenState}) => (
            <ConsultationConsumer>
                {({state: consultationState, actions: consultationAction}) => (
                    <TemplateConsumer>
                        {({state: templateState}) => (
                            <DepartmentsConsumer>
                                {({state, actions}) => (
                                    <DepartmentsList
                                        companyHasAvailableMinutes={
                                            screenState.companyHasAvailableMinutes
                                        }
                                        consultant={
                                            consultationState.consultant
                                        }
                                        consultationAction={consultationAction}
                                        template={templateState.template}
                                        room={screenState.room}
                                        qrCodeChats={
                                            consultationState.qrCodeChats
                                        }
                                        {...state}
                                        {...actions}
                                        {...props}
                                    />
                                )}
                            </DepartmentsConsumer>
                        )}
                    </TemplateConsumer>
                )}
            </ConsultationConsumer>
        )}
    </ScreenConsumer>
);

import React from 'react';
import PropTypes from 'prop-types';
import ZoomIn from '../../../assets/icons/ZoomIn'
import ZoomOut from '../../../assets/icons/ZoomOut'
import './ZoomSlider.scss';

const ZoomSlider = ({min, max, step, value, onChange, zoomIn, zoomOut, ...rest}) => {
    return (
        <div className='zoom-slider-wrapper'>
            <ZoomOut disabled={value === min} onClick={() => {
                if (value === min) {
                    return;
                }
                zoomOut(step)
            }}/>
            <input
                min={min*100}
                max={max*100}
                step={step*100}
                value={value*100}
                onChange={e => {
                    onChange(parseInt(e.target.value)/100)
                }}
                type='range'
            />
            <ZoomIn disabled={value === max} onClick={() => {
                if (value === max) {
                    return;
                }
                zoomIn(step)
            }}/>
        </div>
    );
};

ZoomSlider.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    step: PropTypes.number,
};

ZoomSlider.defaultProps = {
    step: 1,
};

export default ZoomSlider;

 import React from 'react';

export default props => {
    return (
        <svg viewBox="0 0 11 11" {...props}>
            <path fill="#B0B3C3"
                  d="M10.682 9.728L6.44 5.5l4.228-4.228.013-.013c.238-.265.238-.676-.013-.914a.68.68 0 0 0-.49-.2.644.644 0 0 0-.451.2L5.5 4.572 1.14.2A.68.68 0 0 0 .65 0a.659.659 0 0 0-.437 1.127L4.572 5.5.198 9.873a.68.68 0 0 0-.199.49c0 .173.08.332.199.451a.687.687 0 0 0 .94 0l4.374-4.373 4.228 4.228a.64.64 0 0 0 .477.185.696.696 0 0 0 .477-.185.68.68 0 0 0 .199-.49.658.658 0 0 0-.212-.451z"/>
        </svg>
    );
};

import React, {Component} from 'react';
import IOController from '../controllers/IOController';
import {hasWebrtcSupport} from '../utils/browserFeatures';
import securityStates from '../utils/securityStates';
import {instanceOf} from 'prop-types';
import {Cookies, withCookies} from 'react-cookie';
import getPageId from '../utils/getPageIdentificator';

const AppContext = React.createContext('app');

class AppProviderComponet extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    state = {
        code: null,
        appError: null,
        appState: 'initial',
        previewType: '',
        securityLockValidationResult: securityStates.LOCKED,
        expiresAt: null,
        disconnectAfterConsultation: false,
        isCallButtonsExpanded: false,
    };
    actions = {
        setAppError: this.setAppError.bind(this),
        setAppState: this.setAppState.bind(this),
        validateSecurityLock: this.validateSecurityLock.bind(this),
        setCallButtonsExpanded: this.setCallButtonsExpanded.bind(this),
    };

    componentDidMount() {
        if (!hasWebrtcSupport()) {
            return this.setAppError('Sorry, your browser is not supported');
        }

        this.checkIfExpired();
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.isInitiallyConnected && this.props.isInitiallyConnected) {
            IOController.socket.on('webscreen_updateState', data => this.setState({appState: data.state}));
            IOController.socket.on('qrCode_update', _ => this.getInitialState());

            this.getInitialState();
        }
    }

    checkIfExpired() {
        const {expiresAt} = this.state;

        if (expiresAt && expiresAt < Date.now() / 1000) {
            this.setState({disconnectAfterConsultation: true});
            return;
        }

        setTimeout(this.checkIfExpired.bind(this), 5 * 1000);
    }

    setAppError(appError) {
        if (IOController.socket) {
            IOController.socket.disconnect();
        }
        this.setState({appError});
    }

    setAppState(appState) {
        this.setState({appState});
    }

    blockLockInput = () => {
        const pageIdentificator = getPageId();
        this.props.cookies.set(`${pageIdentificator}lastBlocked`, Date.now());
        this.props.cookies.set(`${pageIdentificator}attemptsNumber`, 0);
    };

    onAttemptFail = () => {
        const pageIdentificator = getPageId();
        const attemptsNumber = +this.props.cookies.get(`${pageIdentificator}attemptsNumber`) + 1;
        this.props.cookies.set(`${pageIdentificator}attemptsNumber`, attemptsNumber || 1);
        if (attemptsNumber >= 5) {
            this.blockLockInput();
        }
    };

    validateSecurityLock(lockCode, cb) {
        const pageIdentificator = getPageId();
        const data = this.formInfoData();
        data.securityLock = lockCode;
        IOController.socket.emit('validate_security_lock', data, (err, response) => {
            if (err) {
                cb && cb();
                return this.setAppError(err.code === 400 ? err.message : 'Internal server error');
            }
            this.setState({
                securityLockValidationResult: response ? securityStates.UNLOCKED : securityStates.FAIL
            });
            if (!response) {
                this.onAttemptFail();
                cb && cb();
            } else {
                this.props.cookies.set(`${pageIdentificator}attemptsNumber`, 0);
            }
        });
    };

    formInfoData() {
        const info = location.pathname.slice(1).split('/');
        if (info[0] === '') {
            return location.replace('https://teleportivity.com');
        }
        if (info[0] === 'preview') {
            this.setState({previewType: info[1], appState: 'preview'});
            return {isPreview: true};
        }

        let data = {};

        switch (info && info.length) {
            case 1:
                data.code = info[0];
                break;
            case 2:
                data.companyIdOrName = info[0];
                data.code = info[1];
                break;
            case 3:
                data.companyIdOrName = info[0];
                data.domainName = info[1];
                data.code = info[2];
                break;
            default:
                return this.setAppError('Sorry, your link is no longer valid.\n Please request the new link from company employee.');
        }
        return data;
    }

    getInitialState() {
        const data = this.formInfoData() || {};
        if (data.isPreview) {
            return;
        }
        IOController.socket.emit('webscreen_getState', data, (err, response) => {
            if (err) {
                this.setAppError(err.code === 400 ? err.message : 'Internal server error');
                return;
            }
            this.setState({
                code: data.code,
                domainName: data.domainName,
                companyIdOrName: data.companyIdOrName,
                appState: response.state,
                hasSecurityLock: response.hasSecurityLock,
                expiresAt: response.expiresAt,
            });
            if (!response.hasSecurityLock) {
                this.setState({securityLockValidationResult: securityStates.UNLOCKED});
            }
        });
    }

    setCallButtonsExpanded(isCallButtonsExpanded) {
        this.setState({isCallButtonsExpanded});
    }

    render() {
        const {connected} = this.props;
        return (
            <AppContext.Provider value={{state: {...this.state, connected}, actions: this.actions}}>
                {this.props.children}
            </AppContext.Provider>
        );
    }
}

export const AppProvider = withCookies(AppProviderComponet);
export const AppConsumer = AppContext.Consumer;

import React from 'react';
import classnames from 'classnames';
import InfoIcon from '../../assets/info.svg';

import './RecordingNotification.scss';

export default function RecordingNotification(props) {
    const {
        className,
    } = props;
    return (
        <div className={classnames('recording-notification-wrapper', className)}>
            <img className='info-icon' src={InfoIcon} alt='info icon' />
            <div className='recording-notification-info'>
                <h1 className='recording-header'>Please note</h1>
                <p className='recording-text'>The session might be recorded for security purposes</p>
            </div>
        </div>
    );
}

import React from 'react';
import QrChatAttachmentItem from './QrChatAttachmentItem';

const QrChatAttachmentsView = props => {
    const {attachments, onItemDelete, attachmentsUploads} = props;
    return (
        <div className='qr-chat-attachments-view'>
            {attachments.map((a, idx) => (
                <QrChatAttachmentItem
                    key={`${idx}${a.name}`}
                    name={a.name}
                    onItemDelete={onItemDelete.bind(null, idx)}
                    isUploading={attachmentsUploads}
                    uploadingProgress={a.uploadingProgress}
                />
            ))}
        </div>
    );
};

export default QrChatAttachmentsView;

import React from 'react';
import CallIcon from '../../../../../assets/icons/CallIcon';
import classNames from 'classnames'
import './BellButton.scss';

interface BellButtonProps {
    buttonColor: string;
    onCallClick: () => void;
    canDisplayBellButton: boolean;
}

export default function BellButton(props: BellButtonProps) {
    const {buttonColor, onCallClick, canDisplayBellButton} = props;

    return (
        <CallIcon
            className={classNames('bell-button', 'keyboard-button')} color={buttonColor}
            onClick={onCallClick}
            style={{display: canDisplayBellButton ? 'block' : 'none'}}
        />
    );
}

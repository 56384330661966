import React, {Component} from 'react';
import {Scrollbars} from 'react-custom-scrollbars-2';
import ReactDOM from 'react-dom';
import CloseModal from '../../assets/icons/CloseModal';
import './Modal.scss';

import classnames from 'classnames';

class Modal extends Component {
    UNSAFE_componentWillMount() {
        this.modal = document.createElement('div');
        document.body.appendChild(this.modal);
    }

    componentWillUnmount() {
        this.modal.remove();
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        return ReactDOM.createPortal(<ModalContent {...this.props}/>, this.modal);
    }
}

class ModalContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            infoMsg: ''
        };
    }

    componentWillUnmount() {
        clearTimeout(this.disableMessageTimeout);
    }

    handleCloseOutside = () => {
        const {onHide, closable, closeOutside, disabled} = this.props;
        if (onHide && closable && closeOutside !== false && !disabled) onHide();
    };

    handleMouseDownOutside = (e) => {
        const mouseDownTime = new Date();
        e.target.addEventListener('mouseup', () => {
            if (new Date() - mouseDownTime < 300) {
                this.handleCloseOutside();
            }
        });
    };

    handleClose() {
        const {onHide, closable} = this.props;
        if (onHide && closable) onHide();
    }

    handleClickInside(e) {
        e.stopPropagation();
        if (this.props.disabled) {
            e.preventDefault();
            this.setState({infoMsg: this.props.disableMessage || 'Window disabled'});
            clearTimeout(this.disableMessageTimeout);
            this.disableMessageTimeout = setTimeout(() => {
                this.setState({infoMsg: ''});
            }, 2000);
        }
    }

    resolvePaddings(withoutContentPadding) {
        if (withoutContentPadding) {
            return {wrapperPadding: '2rem 0rem', headPadding: '0rem 2rem'};
        }

        return {wrapperPadding: '2rem', headPadding: '0rem'};
    }

    getContent() {
        const {closable, children, title, leftIcon, withoutContentPadding, titleClassName,} = this.props;
        let closeBtn;
        if (closable) {
            closeBtn = <CloseModal className={classnames('action-button', 'modal-close-button')} onClick={this.handleClose.bind(this)}/>;
        }
        let leftBtn = leftIcon && React.cloneElement(leftIcon, {className: classnames('action-button-left', 'modal-left-button')});

        const {wrapperPadding, headPadding} = this.resolvePaddings(withoutContentPadding);

        return (
            <div className='modal-wrapper' style={{padding: wrapperPadding}}>
                <div className="modal-head" style={{padding: headPadding}}>
                    <div className='modal-controls'>{leftBtn}{closeBtn}</div>
                    <h3 className={titleClassName}>{title}</h3>
                </div>
                <div className="modal-body">{children}</div>
            </div>
        );
    }

    render() {
        const {
            children,
            width,
            backgroundColor,
            className,
            hideModal,
            empty,
            zIndex,
            contentClassName,
            withoutScroll,
            SubContent,
            subContentProps,
            onModalScroll,
            disabled
        } = this.props;
        let modalClass = classnames('modal', hideModal ? 'hidden' : '', className);
        let contentClass = classnames('modal-content', contentClassName, {['disabled']: disabled});
        if (empty) {
            return (
                <div className={modalClass} onMouseDown={this.handleMouseDownOutside}>
                    {children}
                </div>
            );
        }

        return (
            <div className={modalClass} onMouseDown={this.handleMouseDownOutside} style={{zIndex}}>
                <div ref="modalContent"
                     className={contentClass}
                     style={{width, backgroundColor}}
                     onMouseDown={this.handleClickInside.bind(this)}
                     onClick={this.handleClickInside.bind(this)}>

                    {withoutScroll
                        ? this.getContent()
                        : <Scrollbars onScroll={onModalScroll}
                                      onScrollStart={onModalScroll}
                                      autoHeight
                                      autoHeightMax="90vh">
                            {this.getContent()}
                        </Scrollbars>}
                    <div className="info-message">{this.state.infoMsg || ''}</div>
                </div>
                {SubContent && <SubContent {...subContentProps} disabled={disabled}/>}
            </div>
        );
    }
}

export default Modal;

import React from 'react';

export default (props) => {
    return (
        <svg viewBox="0 0 60 60" {...props}>
            <circle cx="30" cy="30" r="28" fill="#FDCA47"/>
            <path fill="#302C3B"
                  d="M19.267 18.886c2.318 0 4.2 2.713 4.2 6.067 0 3.348-1.882 6.066-4.2 6.066-2.322 0-4.2-2.718-4.2-6.066 0-3.354 1.878-6.067 4.2-6.067zM40.731 18.886c2.32 0 4.2 2.713 4.2 6.067 0 3.348-1.88 6.066-4.2 6.066-2.319 0-4.2-2.718-4.2-6.066 0-3.354 1.881-6.067 4.2-6.067zM42.013 43.067H17.985c-1.404 0-1.404-3.734 0-3.734h24.028c1.404 0 1.404 3.734 0 3.734z"/>
        </svg>
    );
}
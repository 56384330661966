import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ConsultationConsumer} from '../../contexts/Consultation';
import {TemplateConsumer} from '../../contexts/Template';
import {DepartmentsConsumer} from '../../contexts/Departments';
import ChatHeader from './ChatHeader';
import ChatBody from './ChatBody';
import ChatFooter from './ChatFooter';
import './Chat.scss';
import _ from 'lodash';
import ss from 'socket.io-stream';
import Modal from '../../components/Modal/Modal';
import { ChatGPTConsumer } from '../../contexts/ChatGPTContext';

const MESSAGES_PER_PAGE = 20;

const BASE_ATTACHMENT_OBJECT_STATE = {
    attachments: [],
    attachmentsUploadedProgress: [],
};

class Chat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            message: '',
            didUserTypingEventSend: false,
            attachments: {
                ...BASE_ATTACHMENT_OBJECT_STATE,
            },
            isAttachmentsUploads: false,
            showConfirmationExitModal: false,
            functionToCallAfterExitConfirmed: null,
        };
        this.sendUserIsTypingEventDebounced = _.debounce(this.sendUserIsTypingEvent.bind(this), 5000); 
    }

    static propTypes = {
        onMessageSend: PropTypes.func.isRequired,
        room: PropTypes.object.isRequired,
        onChatClose: PropTypes.func.isRequired,
        requestConsultationFromChat: PropTypes.func.isRequired,
        currentChat: PropTypes.object,
        template: PropTypes.object.isRequired,
        showConsultationButtons: PropTypes.bool,
        showBackButton: PropTypes.bool,
    }

    static defaultProps = {
        showConsultationButtons: true,
        showBackButton: true,
    }

    componentDidMount() {
        const {currentChat, readQrCodeChat} = this.props;
        if (currentChat && !currentChat.wasRead) {
            readQrCodeChat(currentChat.id);
        }
    }

    componentDidUpdate(prevProps) {
        const {currentChat, readQrCodeChat, onChatClose} = this.props;
        const {currentChat: prevChat} = prevProps;
        if (!currentChat && prevChat) {
            onChatClose();
            return;
        }
        if (!currentChat && !prevChat) {
            return;
        }
        const currentChatMessagesNumber = _.get(currentChat, `messages.length`, 0);
        const prevChatMessagesNumber = _.get(prevChat, `messages.length`, 0);
        if (currentChatMessagesNumber !== prevChatMessagesNumber) {
            readQrCodeChat(currentChat.id);
        }
    }

    componentWillUnmount() {
        this._destroyAttachmentStreams();
        const {didUserTypingEventSend} = this.state;
        const {currentChat, sendOperatorIsTyping} = this.props;
        if (didUserTypingEventSend && currentChat) {
            this.sendUserIsTypingEventDebounced.cancel();
            sendOperatorIsTyping(currentChat.id, false);
        }
    }

    _destroyAttachmentStreams() {
        const {attachments: attachmentsObj} = this.state;
        const {attachments} = attachmentsObj;
        if (!attachments.length) {
            return;
        }
        attachments.forEach(a => {
            if (a.stream) {
                a.stream.destroy();
            }
        });
    }

    sendMessage() {
        const {message, attachments: attachmentsObj} = this.state;
        const {room, onMessageSend, currentChat} = this.props;
        const {attachments} = attachmentsObj;
        if (!message && !attachments.length) {
            return;
        }

        if (!message && attachments.length < 1) {
            return;
        }
        const attachmentsWithStreams = attachments.map(({name, file}, idx) => ({
            name,
            stream: this.getFileStream(file, idx)
        }))
        this.setState({
            attachments: {
                ...attachmentsObj,
                attachments: attachmentsWithStreams,
            }
        });
        const messageObj = {
            text: message,
            roomId: room.id,
            attachments: attachmentsWithStreams,
        };
        if (attachments) {
            this.setState({isAttachmentsUploads: true});
        }
        if (currentChat) {
            this.sendUserIsTypingEventDebounced.cancel();
            this.sendUserIsTypingEvent(currentChat.id, false);
        }
        onMessageSend(messageObj, () => {
            this.setState({message: '', attachments: {...BASE_ATTACHMENT_OBJECT_STATE}, isAttachmentsUploads: false});
        });
    }

    sendUserIsTypingEvent(chatId, value) {
        const {sendOperatorIsTyping} = this.props;
        this.setState({didUserTypingEventSend: value}, () => {
            sendOperatorIsTyping(chatId, value);
        });
    }

    onMessageChange(e) {
        const {didUserTypingEventSend} = this.state;
        const {currentChat} = this.props;
        if (currentChat) {
            if (!didUserTypingEventSend) {
                this.sendUserIsTypingEvent(currentChat.id, true);
            }
            this.sendUserIsTypingEventDebounced(currentChat.id, false);
        }
        this.setState({message: e.target.value});
    }

    requestConsultation(consultationType) {
        const {requestConsultationFromChat, department, toSendingPhoneNumber} = this.props;
        if (department && !department.hasAvailableMembers) {
            toSendingPhoneNumber({departmentId: department.id});
            return;
        }
        requestConsultationFromChat(consultationType);
    }

    onAttachmentsChange(attachments) {
        this.setState({
            attachments
        });
    }

    _updateAttachmentProgress(progress, idx) {
        const {attachments: attachmentsObj} = this.state;
        const {attachmentsUploadedProgress} = attachmentsObj;
        const newAttachmentsUploadedProgress = attachmentsUploadedProgress.slice();
        newAttachmentsUploadedProgress.splice(idx, 1, progress);
        this.setState({
            attachments: {
                ...attachmentsObj,
                attachmentsUploadedProgress: newAttachmentsUploadedProgress
            }
        });
    }

    getFileStream(file, idx) {
        const stream = ss.createStream();
        const blobStream = ss.createBlobReadStream(file);
        blobStream.on('data', chunk => {
            const {attachments: attachmentsObj} = this.state;
            const {attachmentsUploadedProgress} = attachmentsObj;
            this._updateAttachmentProgress(attachmentsUploadedProgress[idx] + chunk.length / file.size, idx);
        });
        blobStream.on('end', () => {
            this._updateAttachmentProgress(1, idx);
        })
        blobStream.pipe(stream);
        return stream;
    }

    onChatClose() {
        const {onChatClose} = this.props;
        const {isAttachmentsUploads} = this.state;
        if (isAttachmentsUploads) {
            this.setState({showConfirmationExitModal: true});
            return;
        }
        onChatClose();
    }

    _getConfirmationExitDialogTitle(attachments) {
        if (attachments.length > 1) {
            return 'Files won\'t be sent. Continue?';
        }
        return 'File won\'t be sent. Continue?';
    }

    checkIfAttachmentsUploading(functionToCallAfterExitConfirmed, ...params) {
        const {isAttachmentsUploads} = this.state;
        if (isAttachmentsUploads) {
            this.setState({showConfirmationExitModal: true, functionToCallAfterExitConfirmed: functionToCallAfterExitConfirmed.bind(this, ...params)});
            return;
        }
        functionToCallAfterExitConfirmed(...params);
    }

    onExitConfirmed() {
        const {functionToCallAfterExitConfirmed} = this.state;
        if (!functionToCallAfterExitConfirmed) {
            return;
        }
        this.setState({functionToCallAfterExitConfirmed: null});
        functionToCallAfterExitConfirmed();
    }

    render() {        
        const {
            room,
            onChatClose,
            user,
            currentChat,
            template,
            showConsultationButtons,
            showBackButton,
            getQrChatMessages,
            chatAi,
        } = this.props;
        const {message, attachments, isAttachmentsUploads, showConfirmationExitModal, functionToCallAfterExitConfirmed} = this.state;
        const messages = currentChat ? currentChat.messages || [] : [];
        const loadedMessagesCount = currentChat ? currentChat.loadedMessagesCount : 0;
        const firstRequestedMessageTimestamp = currentChat ? currentChat.firstRequestedMessageTimestamp : 0;
        const isOperatorTyping = currentChat ? currentChat.isOperatorTyping : false;
        const operatorName = currentChat ? currentChat.operatorName : '';
        const operator = currentChat ? currentChat.operator : null;
        return (
            <div className="chat-container">
                {showConfirmationExitModal && <Modal
                    title={this._getConfirmationExitDialogTitle(attachments.attachments)}
                    contentClassName='confirmation-exit-modal-content'
                    show={() => {}}
                    withoutScroll
                >
                    <div className='confirmation-exit-modal-content'>
                        <div
                            className='confirmation-exit-modal-button-primary'
                            onClick={this.onExitConfirmed.bind(this)}
                        >
                            Yes
                        </div>
                        <div 
                            className='confirmation-exit-modal-button-secondary' 
                            onClick={() => this.setState({showConfirmationExitModal: false, functionToCallAfterExitConfirmed: null})}
                        >
                            No
                        </div>
                    </div>
                </Modal>}
                <ChatHeader
                    isVideoConsultationEnabled={room.roomInfo.qr_mini_websites_video_call && template.isVideoConsultationEnabled}
                    isAudioConsultationEnabled={room.roomInfo.qr_mini_websites_audio_call && template.isAudioConsultationEnabled}
                    isChatAssigned={currentChat && currentChat.assigned}
                    requestConsultationFromChat={this.checkIfAttachmentsUploading.bind(this, this.requestConsultation.bind(this))}
                    showConsultationButtons={showConsultationButtons}
                    showBackButton={showBackButton}
                    chatAi={chatAi}
                    onChatClose={this.checkIfAttachmentsUploading.bind(this, this.onChatClose.bind(this))}
                    operator={operator}/>
                <ChatBody
                    isOperatorTyping={isOperatorTyping}
                    operatorName={operatorName}
                    isChatAssigned={currentChat && currentChat.assigned}
                    room={room}
                    user={user}
                    chatAi={chatAi}
                    firstRequestedMessageTimestamp={firstRequestedMessageTimestamp}
                    getQrChatMessages={getQrChatMessages}
                    loadedMessagesCount={loadedMessagesCount}
                    messages={messages}/>
                <ChatFooter
                    isAttachmentsUploads={isAttachmentsUploads}
                    value={message}
                    chatAi={chatAi}
                    attachments={attachments}
                    onAttachmentsChange={this.onAttachmentsChange.bind(this)}
                    onMessageChange={this.onMessageChange.bind(this)}
                    onMessageSend={this.sendMessage.bind(this)}/>
            </div>
        );
    }

}

const getDepartment = (departmentsState, consultationState) => {
    if (consultationState.chatDepartmentId && departmentsState.departments) {
        return departmentsState.departments.find(d => d.id === consultationState.chatDepartmentId);
    }
    return null;
}

export default props => (//
    <ConsultationConsumer>
        {({state: consultationState, actions: consultationAction}) => (
            <DepartmentsConsumer>
                {({state: departmentsState}) => (
                    <TemplateConsumer>
                        {({ state, actions }) => (
                            <ChatGPTConsumer>
                                {({ state: chatGPTState, actions: chatGPTActions }) => {
                                    const { chatAi } = props;
                                    const currentChat = consultationState.qrCodeChats[consultationState.chatDepartmentId]
                                    const currentChatAi = chatGPTState.currentChat;
                                    const requestConsultationFromChat = consultationAction.requestConsultationFromChat;
                                    const toSendingPhoneNumber = consultationAction.toSendingPhoneNumber
                                    const getQrChatMessages = consultationAction.getQrChatMessages
                                    const getQrChatMessagesAi = chatGPTActions.getMessages;
                                    const sendOperatorIsTyping = consultationAction.sendOperatorIsTyping
                                    const sendOperatorIsTypingAi = () => {};
                                    const readQrCodeChat = consultationAction.readQrCodeChat
                                    const readQrCodeChatAi = () => {};
                                    const template = state.template
                                    const department = getDepartment(departmentsState, consultationState)

                                    return <Chat
                                        currentChat={chatAi ? currentChatAi : currentChat}
                                        requestConsultationFromChat={requestConsultationFromChat}
                                        toSendingPhoneNumber={toSendingPhoneNumber}
                                        getQrChatMessages={chatAi ? getQrChatMessagesAi : getQrChatMessages}
                                        sendOperatorIsTyping={chatAi ? sendOperatorIsTypingAi : sendOperatorIsTyping}
                                        readQrCodeChat={chatAi ? readQrCodeChatAi : readQrCodeChat}
                                        template={template || {}}
                                        department={department}
                                        {...props}
                                        onMessageSend={chatAi ? chatGPTActions.sendMessage?.bind(null, props.user.id) : props.onMessageSend}
                                    />
                                }}
                            </ChatGPTConsumer>
                        )}
                    </TemplateConsumer>
                )}
            </DepartmentsConsumer>
        )}
    </ConsultationConsumer>
);;
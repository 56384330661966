import React, {useState, useCallback} from 'react';
import classnames from 'classnames';
import useCamera from '../../../hocs/useCamera';
import {TemplateScreenProps} from '../../../interfaces/templateScreenProps';
import NumberDisplay from './NumberDisplay/NumberDisplay';
import Keyboard from './Keyboard/Keyboard';
import ErrorIcon from '../../../assets/error.svg';
import CallButtons from '../CallButtons';
import Modal from '../../Modal/Modal';
import './QrIntercomScreen.scss';
import { getOsAndBrowser } from '../../../utils/browserFeatures';

const MAX_NUMBER_LENGTH = 6;

export default function QrIntercomScreen(
    props: TemplateScreenProps<'qrintercom'>
) {
    const {
        logo,
        screen,
        isVideoConsultationEnabled,
        isAudioConsultationEnabled,
        consultationRequestProgress,
        departmentsState,
        requestConsultationWithDepartmentNumber,
        requestConsultationWithDepartmentNumberWithType,
        requestConsultationWithDepartmentId,
        requestPhoneConsultation,
        qrIntercomNumberNotFound,
        removeQrIntercomNumberNotFoundState,
        onPhotoDone,
        setInWaitingCallState,
        requestAIChat,
    } = props;
    const {whereToCall, phone} = screen.settings;
    const {
        digitButtonColor,
        controlButtonColor,
        backgroundColor,
        conciergeDepartment,
        header = 'Please, enter department number for call',
        textColor,
    } = screen.settings.options;
    const [
        browserName,
        browserOs,
        isMobile,
        brand
    ] = getOsAndBrowser();
    const {settings} = screen;
    const [numberValue, setNumberValue] = useState('');
    const updateNumber = (newNum: string) =>
        setNumberValue(n => {
            if (n.length === MAX_NUMBER_LENGTH) {
                return n;
            }
            return `${n}${newNum}`;
        });

    const {makePhoto} = useCamera({
        width: 1280,
        height: 720,
        onPhotoDone,
        imageQuality: 0.9,
    });
    const callToConcierge = useCallback(() => {
        if (whereToCall === 'phone' && phone) {
            setInWaitingCallState();
            makePhoto(() => requestPhoneConsultation(phone));
            return;
        }
        if (!conciergeDepartment) {
            return;
        }
        setInWaitingCallState();
        makePhoto(() =>
            requestConsultationWithDepartmentId(conciergeDepartment)
        );
    }, [conciergeDepartment, whereToCall, phone]);
    const callToDepartments = useCallback(
        (callType?: string) => {
            if (!Boolean(numberValue.length)) {
                return;
            }
            if (!callType) {
                setInWaitingCallState();
                makePhoto(() =>
                    requestConsultationWithDepartmentNumber(numberValue)
                );
                return;
            }
            setInWaitingCallState();
            makePhoto(() =>
                requestConsultationWithDepartmentNumberWithType(
                    numberValue,
                    callType
                )
            );
        },
        [numberValue]
    );
    const buttonsContainerClassName = classnames(
        'mn-call-buttons-container',
        'mw-call-buttons-opened',
        'call-buttons-wrapper'
    );
    const videoConsultationEnabled =
        isVideoConsultationEnabled && settings.isVideoConsultationEnabled;
    const audioConsultationEnabled =
        isAudioConsultationEnabled && settings.isAudioConsultationEnabled;
    const additionalKeyboardEnabled = settings.isKeyboardEnabled;
    const audioAndVideoConsultationEnabled =
        videoConsultationEnabled && audioConsultationEnabled;
    const showBellButton = Boolean(conciergeDepartment);

    return (
        <div
            style={{backgroundColor}}
            className='mw-screen-container screen-wrapper'
        >
            <div className='content-wrapper'>
                <div className='header'>
                    {logo && logo.url && (
                        <img
                            className='screen-logo'
                            src={logo.url}
                            alt='qr intercom logo'
                        />
                    )}
                    <h1 style={{color: textColor}} className='title'>
                        {header}
                    </h1>
                </div>
                <div className='tile-wrapper'>
                    <NumberDisplay
                        layoutColor={controlButtonColor}
                        value={numberValue}
                    />
                    <Keyboard
                        insideElementColor={backgroundColor}
                        onCallClick={callToConcierge}
                        onClearClick={() =>
                            setNumberValue(num =>
                                num.substring(0, num.length - 1)
                            )
                        }
                        onKeyboardClick={updateNumber}
                        digitButtonColor={digitButtonColor}
                        controlButtonColor={controlButtonColor}
                        showBellButton={showBellButton}
                        additionalKeyboardEnabled={additionalKeyboardEnabled}
                    />
                </div>
            </div>
            <div className={buttonsContainerClassName}>
                <CallButtons
                    forceBigIcons
                    isVideoConsultationEnabled={videoConsultationEnabled}
                    isAudioConsultationEnabled={audioConsultationEnabled}
                    additionalKeyboardEnabled={additionalKeyboardEnabled}
                    consultationRequestProgress={consultationRequestProgress}
                    requestAIChat={requestAIChat}
                    isConciergeCallEnabled={
                        showBellButton &&
                        additionalKeyboardEnabled &&
                        callToConcierge
                    }
                    onConciergeCall={callToConcierge}
                    color={controlButtonColor}
                    departmentsState={departmentsState}
                    requestConsultationWithType={(type: string) =>
                        callToDepartments(type)
                    }
                    disabled={numberValue.length === 0}
                    conciergeButtonClassname={classnames(
                        'video-consultation-button',
                        'two-buttons'
                    )}
                    audioButtonClassName={classnames(
                        'audio-consultation-button',
                        audioAndVideoConsultationEnabled && 'two-buttons'
                    )}
                    videoButtonClassName={classnames(
                        'video-consultation-button',
                        audioAndVideoConsultationEnabled && 'two-buttons'
                    )}
                    chatAiButtonClassName={
                        classnames(
                            'chat-ai-button',
                        )
                    }
                >
                    {/* <BellButton
                        buttonColor={controlButtonColor}
                        onCallClick={callToConcierge}
                        canDisplayBellButton={
                            showBellButton && additionalKeyboardEnabled
                        }
                    /> */}
                </CallButtons>
            </div>
            <Modal
                show={qrIntercomNumberNotFound}
                contentClassName='consultation-modal-content'
                withoutScroll
            >
                <div className='modal-wrapper'>
                    <img
                        className='error-icon'
                        src={ErrorIcon}
                        alt='error icon'
                    />
                    <h1 className='modal-error-header'>Invalid selection</h1>
                    <p className='modal-error-text'>Please try again</p>
                    <button
                        onClick={removeQrIntercomNumberNotFoundState}
                        className='modal-error-button'
                    >
                        Try Again
                    </button>
                </div>
            </Modal>
        </div>
    );
}

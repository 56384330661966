import React, {Component} from 'react';
import PropTypes from 'prop-types';

let timer;

class RedirectTimeout extends Component {
    static defaultProps = {
        time: 20000,
        forceSingleTimeout: false
    };

    static propTypes = {
        time: PropTypes.number,
        forceSingleTimeout: PropTypes.bool.isRequired,
        onRedirect: PropTypes.func.isRequired,
    };

    state = {
        timer: undefined,
    }

    componentDidMount() {
        this.setRedirectTimeout();
        if (this.props.forceSingleTimeout) return;
        document.body.addEventListener('click', this.clearRedirectTimeout);
        document.body.addEventListener('touchstart', this.clearRedirectTimeout);
        document.addEventListener('keydown', this.clearRedirectTimeout);
    }

    componentWillUnmount() {
        this.clearRedirectTimeout();

        if (this.props.forceSingleTimeout) return;

        document.body.removeEventListener('click', this.clearRedirectTimeout);
        document.body.removeEventListener('touchstart', this.clearRedirectTimeout);
        document.removeEventListener('keydown', this.clearRedirectTimeout);
    }

    setRedirectTimeout = () => {
        const {time} = this.props;
        this.setState((state) => {
            if (state.timer) {
                clearTimeout(state.timer);
            }  
           return {...state, timer: setTimeout(this.props.onRedirect, time)}
        })
    };

    clearRedirectTimeout = () => {
        this.setState((state) => {
            clearTimeout(state.timer);
            return ({...state, timer: undefined})
        })
    };

    render() {
        return null;
    }
}

export default RedirectTimeout;

import React from 'react';

export default (props) => {
    return (
        <svg viewBox="0 0 60 60" {...props}>
            <circle cx="30" cy="30" r="28" fill="#FDCA47"/>
            <path fill="#302C3B"
                  d="M40.256 45.709c-5.407-4.48-15.138-4.453-20.512 0-.647.535-1.23-.393-.792-1.25C20.59 41.253 25.765 38.4 30 38.4c4.237 0 9.41 2.854 11.047 6.06.44.856-.142 1.784-.79 1.249zM19.267 18.886c2.318 0 4.2 2.713 4.2 6.067 0 3.348-1.882 6.066-4.2 6.066-2.322 0-4.2-2.718-4.2-6.066 0-3.354 1.878-6.067 4.2-6.067zM40.731 18.886c2.32 0 4.2 2.713 4.2 6.067 0 3.348-1.88 6.066-4.2 6.066-2.319 0-4.2-2.718-4.2-6.066 0-3.354 1.881-6.067 4.2-6.067z"/>
        </svg>
    );
}
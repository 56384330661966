import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import {v4} from 'uuid';
import { ChatGPT, DOOR_STATES } from "../utils/ChatGPT";
import { ScreenContext } from "./Screen";
import chatGPTAvatar from '../assets/waves-avatar.svg';
import IOController from "../controllers/IOController";

const ChatGPTContext = React.createContext();

const DefaultCurrentChat = {
    id: v4(),
    assigned: true,
    messages: [],
    operator: '',
    wasRead: false,
    isOperatorTyping: false,
}

const CHAT_GPT_SENDER_ID = v4();

const chatGPTSender = {
    id: CHAT_GPT_SENDER_ID,
    userInfo: {
        avatar: chatGPTAvatar
    }
}

const WELCOME_MESSAGE = "Hello, I'm FONECOM. You can ask me a question.";
const ERROR_ANSWER = "Sorry, your request wasn’t accepted. Please try again or seek further assistance."
const FAKE_TYPING_TIMEOUT = 1000;

const ChatGPTContextProvider = (props) => {
    const { children } = props;
    const [currentChat, setCurrentChat] = useState(DefaultCurrentChat);
    const [messages, setMessages] = useState([]);
    const {state: screenState, actions: screenActions} = useContext(ScreenContext);
    const chatGPT = useRef(new ChatGPT({ context: screenState?.qrCode?.contextualAiInformation }));

    useEffect(() => {
        chatGPT.current.setConfig({ context: screenState?.qrCode?.contextualAiInformation });
    }, [screenState?.qrCode?.contextualAiInformation])

    const sendWelcomeMessage = useCallback(async () => {
        setCurrentChat(p => ({...p, isOperatorTyping: true}));
        await new Promise(res => setTimeout(res, FAKE_TYPING_TIMEOUT));

        const message = {
            sender: chatGPTSender,
            text: screenState?.qrCode?.welcomeAiMessage || WELCOME_MESSAGE,
            attachments: [],
        }
        setMessages(p => [...p, message]);
        setCurrentChat(p => ({...p, isOperatorTyping: false}))
    }, [screenState?.qrCode?.welcomeAiMessage])

    const getChatGPTAnswer = useCallback(async (text) => {
        setCurrentChat(p => ({...p, isOperatorTyping: true}));
        IOController.socket.emit('chatGPT_ask', { prompt: chatGPT.current.getChatGPTPrompt(text) }, async(err, response) => {
            if (err) {
                const message = {
                    sender: chatGPTSender,
                    text: ERROR_ANSWER,
                    attachments: [],
                }
                setMessages(p => [...p, message]);
                setCurrentChat(p => ({...p, isOperatorTyping: false}));
                return;
            }
            let {
                answer,
                doorState,
            } = response
            chatGPT.current.addToHistory(text, answer);
            if (doorState === DOOR_STATES.open) {
                await screenActions?.openDoor().catch(err => {
                    if (err.error === 'Door not chosen') {
                        answer = 'Access control is not connected. Please request further assistance from management.'
                    } else {
                        answer = ERROR_ANSWER
                    }
                })
            }
    
            const message = {
                sender: chatGPTSender,
                text: answer,
                attachments: [],
            }
            setMessages(p => [...p, message]);
            setCurrentChat(p => ({...p, isOperatorTyping: false}))
        })
    }, [])

    const sendMessage = useCallback((id, {text, roomId, attachments}, cb) => {
        const message = {
            sender: {
                id,
                userInfo: {
                    avatar: ''
                }
            },
            text,
            attachments: [],
        }
        setMessages(p => [...p, message]);
        cb(null, message)
        getChatGPTAnswer(text)
    }, [])

    const getMessages = useCallback(() => {
        if (!messages.length) {
            sendWelcomeMessage()
        }
        return messages;
    }, [messages, sendWelcomeMessage])

    const state = useMemo(() => ({
        currentChat: {
            ...currentChat,
            messages,
        },
    }), [
        currentChat,
        messages,
    ]);

    const actions = {
        sendMessage,
        getMessages,
    }

    return <ChatGPTContext.Provider 
        value={{
            state,
            actions,
        }}
    >
        {children}
    </ChatGPTContext.Provider>
}
export const ChatGPTConsumer = ChatGPTContext.Consumer;
export { ChatGPTContext, ChatGPTContextProvider }; 

import React, {Component} from 'react';
import logoImage from '../../assets/logo.svg';
import LockIcon from '../../assets/icons/Lock';
import './EnterSecurityLock.scss';
import {AppConsumer} from '../../contexts/App';


class EnterSecurityLock extends Component {
    constructor(props) {
        super(props);
        this.state = {enteredCode: ''};
    }

    onChange = e => {
        const {value} = e.target;

        if (/^[0-9]{0,5}$/.test(value)) {
            this.setState({enteredCode: value});
        }
    };

    handleSubmit = () => {
        this.props.validateSecurityLock(this.state.enteredCode, () => {
            this.setState({enteredCode: ''});
        });
    };


    render() {
        const {room, logo} = this.props;
        const {enteredCode} = this.state;
        const showTeleportivityLogo = room && room.roomInfo.powered_by_logo;
        return (
            <div className="mw-screen-container enter-lock-screen">
                <div className="enter-lock-screen-content-wrapper">
                    <LockIcon/>
                    <div className="enter-lock-text">Please, enter security code</div>
                    <div className='code-row'>
                        <input onChange={this.onChange}
                               value={enteredCode || ''}
                               autoFocus
                               className="enter-lock-screen-input"/>
                        <div className="cells-wrapper">
                            <div className={'cell'}>{enteredCode ? enteredCode.substring(0, 1) : ''}</div>
                            <div className={'cell'}>{enteredCode ? enteredCode.substring(1, 2) : ''}</div>
                            <div className={'cell'}>{enteredCode ? enteredCode.substring(2, 3) : ''}</div>
                            <div className={'cell'}>{enteredCode ? enteredCode.substring(3, 4) : ''}</div>
                            <div className={'cell'}>{enteredCode ? enteredCode.substring(4, 5) : ''}</div>
                        </div>
                    </div>
                    <button className="enter-lock-screen-submit-button"
                            disabled={!enteredCode || enteredCode.length !== 5}
                            onClick={this.handleSubmit}>
                        Submit
                    </button>
                </div>
            </div>);
    }
}

export default props => (
    <AppConsumer>
        {({state, actions}) => <EnterSecurityLock {...actions} {...props}/>}
    </AppConsumer>
)

import React, {Component} from 'react';
import './Loading.scss';

class Loading extends Component {
    render() {
        return (
            <div className={`loadingContainer ${this.props.className || ''}`}>
                <div className="loader"/>
                {this.props.children}
            </div>
        );
    }

}

export default Loading;
import React, {Component} from 'react';
import {ScreenConsumer} from './Screen';
import {AppConsumer} from './App';
import IOController from '../controllers/IOController';

const DepartmentsContext = React.createContext('departments');

class DepartmentsContextProvider extends Component {
    state = {
        departments: [],
        hasAvailableDepartments: false,
        allowMultipleTapMeButtons: false,
        gotDepartments: false,
        isUpdatingDepartments: false
    };
    actions = {};

    componentDidUpdate(prevProps) {
        if (this.gotScreenInfo(prevProps) || this.changedAppState(prevProps)) {
            this.loadDepartments();
        }

        if (this.changedAppState(prevProps)) {
            this.setState({isUpdatingDepartments: true});
        }

        if (!prevProps.isInitiallyConnected && this.props.isInitiallyConnected) {
            IOController.socket.on('department_updated', this.setDepartments.bind(this));
        }
    }

    gotScreenInfo(prevProps) {
        const oldScreen = prevProps.screenState.user;
        const screen = this.props.screenState.user;
        return !oldScreen && screen;
    }

    changedAppState(prevProps) {
        const prevState = prevProps.appState.appState;
        const currentState = this.props.appState.appState;

        return prevState !== 'initial' && prevState !== currentState;
    }

    loadDepartments() {
        IOController.socket.emit('department_getAllowed', {}, (err, data) => {
            if (!err) {
                this.setDepartments(data);
            }
        });
    }

    setDepartments(data) {
        const hasAvailableDepartments = data.allowMultipleTapMeButtons &&
            data.departments.filter(d => d.hasAvailableMembers).length !== 0;
        this.setState({...data, hasAvailableDepartments, gotDepartments: true, isUpdatingDepartments: false});
    }

    render() {
        return (
            <DepartmentsContext.Provider value={{state: this.state, actions: this.actions}}>
                {this.props.children}
            </DepartmentsContext.Provider>
        );
    }
}

export const DepartmentsProvider = props => (
    <AppConsumer>
        {({state: appState, actions: appActions}) => (
            <ScreenConsumer>
                {({state, actions}) => <DepartmentsContextProvider screenState={state}
                                                                   screenActions={actions}
                                                                   appState={appState}
                                                                   appActions={appActions}
                                                                   {...props}/>}
            </ScreenConsumer>
        )}
    </AppConsumer>
);

export const DepartmentsConsumer = DepartmentsContext.Consumer;

import React from 'react';

export default props => {
    const {className} = props;
    return (
        <svg
            className={className}
            width='61'
            height='61'
            viewBox='0 0 61 61'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M54.4052 44.6376C51.6792 43.0397 45.7994 39.5896 43.0734 37.9895C42.1779 37.4635 41.0774 37.4745 40.1864 38.005L35.674 40.6991C34.6639 41.3025 33.4023 41.2318 32.476 40.5068C30.8748 39.2537 28.2966 37.1452 26.0536 34.8975C23.8107 32.6498 21.7066 30.0662 20.4561 28.4616C19.7327 27.5334 19.6621 26.2692 20.2642 25.257L22.9527 20.735C23.4842 19.8421 23.4887 18.7304 22.9638 17.8331L16.3429 6.4996C15.7011 5.40337 14.4263 4.8641 13.1935 5.16689C11.9959 5.45863 10.441 6.17029 8.81115 7.80578C3.70766 12.92 0.997115 21.5462 20.1893 40.7787C39.3814 60.0112 47.9872 57.2972 53.0929 52.1807C54.7272 50.543 55.4351 48.9827 55.7285 47.7804C56.0262 46.5471 55.4969 45.2785 54.4052 44.6376ZM51.078 26.742C51.1803 27.8531 52.0743 28.7597 53.19 28.7597C54.3058 28.7597 55.218 27.8509 55.1442 26.7376C54.3441 14.6772 46.3435 6.65464 34.3095 5.84814C33.1921 5.77325 32.28 6.68897 32.28 7.8089C32.28 8.92884 33.19 9.82606 34.3052 9.92963C43.5334 10.7867 50.2271 17.4948 51.078 26.742ZM32.28 18.623C32.28 19.7427 33.193 20.6264 34.3032 20.7718C37.9146 21.2447 39.7833 23.1199 40.2531 26.7416C40.3968 27.8497 41.2789 28.7606 42.3963 28.7606C43.5137 28.7606 44.4312 27.85 44.3502 26.7355C43.8715 20.1476 40.8803 17.1474 34.3092 16.6656C33.1925 16.5837 32.28 17.5032 32.28 18.623Z'
                fill='white'
            />
        </svg>
    );
};

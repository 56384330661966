import {v4} from 'uuid';

interface phrase {
    type: 'operator' | 'client';
    offset: number;
    text: string;
    updatedAt: number;
    id: string;
}

export default class ParticipantPhrases {
    private recognitionPhrasesTtl: number;
    private phrasesArray: phrase[] = [];
    private phrasesIndex: Record<string, number> = {};
    constructor(recognitionPhrasesTtl: number) {
        this.recognitionPhrasesTtl = recognitionPhrasesTtl;
    }

    add({type, offset, text}: { type: 'operator' | 'client', offset: number, text: string}) {
        const newElement = {type, offset, text, updatedAt: Date.now()};
        const phraseIndex = this.phrasesIndex[`${type}${offset}`];
        if (phraseIndex !== undefined && phraseIndex !== null) {
            this.phrasesArray[phraseIndex] = {...this.phrasesArray[phraseIndex], ...newElement};
            return;
        }
        const newArrLength = this.phrasesArray.push({...newElement, id: v4()});
        this.phrasesIndex = {
            ...this.phrasesIndex,
            [`${type}${offset}`]: newArrLength - 1,
        };
    }

    clear() {
        this.phrasesArray = [];
        this.phrasesIndex = {};
    }

    clearByTime() {
        const now = Date.now();
        const {
            newPhrasesArray,
            newPhrasesIndex,
        } = this.phrasesArray.reduce((acc, el) => {
            const {
                newPhrasesArray,
                newPhrasesIndex,
            } = acc;
            const {type, offset, updatedAt} = el;
            if ((now - updatedAt) < this.recognitionPhrasesTtl) {
                const index = newPhrasesArray.length;
                return {
                    newPhrasesArray: [...newPhrasesArray, el],
                    newPhrasesIndex: {...newPhrasesIndex, [`${type}${offset}`]: index},
                }
            }
            return acc;
        }, {
            newPhrasesIndex: {},
            newPhrasesArray: [],
        });
        this.phrasesArray = newPhrasesArray;
        this.phrasesIndex = newPhrasesIndex;
    }

    getPhrases() {
        return [...this.phrasesArray];
    }
}
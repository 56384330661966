import React, {Component} from 'react';
import TextScreen from './TextScreen';
import WebsiteScreen from './WebsiteScreen';
import VideoTextScreen from './VideoTextScreen';
import FileScreen from './FileScreen';
import NavigationScreen from './navigationScreen/NavigationScreen';
import ServiceNowScreen from './ServiceNowScreen';
import QrIntercomScreen from './QrIntercomScreen/QrIntercomScreen';
import Modal from '../Modal/Modal';
import {getCountryCodeWithoutCountry, getPhoneWithoutCode} from '../../utils/phoneNumberUtils';
import {AppConsumer} from '../../contexts/App';
import './TemplateScreen.scss';

class TemplateScreen extends Component {
    state = {
        isInitiallyConnected: false
    };

    componentDidMount() {
        this.setState({isInitiallyConnected: this.props.connected});
    }

    componentDidUpdate(prevProps) {
        if (!this.state.isInitiallyConnected && !prevProps.connected && this.props.connected) {
            this.setState({isInitiallyConnected: true});
        }
    }

    getPhoneModal() {
        const {phoneConsultationOnPCRequested, removePhoneConsultationOnPC} = this.props;
        const {phone} = this.props.screen.settings;
        const phoneString = `${getCountryCodeWithoutCountry(phone)}${getPhoneWithoutCode(phone)}`;
        return (
            <Modal title="Call us"
                   contentClassName="wc-template-modal-content"
                   onHide={removePhoneConsultationOnPC}
                   width="20rem"
                   show={phoneConsultationOnPCRequested}
                   withoutScroll
                   closable>
                       <div style={{textAlign: 'center'}}>{phoneString}</div>
            </Modal>
        )
    }

    getScreenContent() {
        const {screen} = this.props;
        switch (screen.settings.type) {
            case 'text':
                return <TextScreen {...this.props}/>;
            case 'website':
                return <WebsiteScreen {...this.props}/>;
            case 'videotext':
                return <VideoTextScreen {...this.props}/>;
            case 'file':
                return <FileScreen {...this.props}/>;
            case 'navigationscreen':
                return <NavigationScreen {...this.props}/>;
            case 'servicenow':
                return <ServiceNowScreen {...this.props}/>;
            case 'qrintercom':
                return <QrIntercomScreen {...this.props}/>;
            default:
                return <div/>;
        }
    }

    render() {
        const {phone} = this.props.screen.settings;
        return (
            <div>
                {phone && this.getPhoneModal()}
                {this.state.isInitiallyConnected && this.getScreenContent()}
            </div>
        );
    }
}

export default props => (
    <AppConsumer>
        {({state}) => (
            <TemplateScreen
                connected={state.connected}
                isCallButtonsExpanded={state.isCallButtonsExpanded}
                {...props}
            />
        )}
    </AppConsumer>
);

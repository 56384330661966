import React from 'react';

export default props => (
    <svg viewBox="0 0 38 38" {...props}>
        <path
            fill="#FFF"
            d="M19 21c1.66 0 2.99-1.34 2.99-3l.01-6c0-1.66-1.34-3-3-3s-3 1.34-3 3v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S13.7 21 13.7 18H12c0 3.41 2.72 6.23 6 6.72V28h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z"
        />
    </svg>
);

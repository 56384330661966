import React from 'react';
import './LeftArrow.scss';

export default function LeftArrow() {
    return (
        <svg
            width='7'
            height='12'
            viewBox='0 0 7 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M2.15862 5.99998L6.03519 1.92959C6.21574 1.74 6.21613 1.43568 6.0376 1.24822C5.85783 1.05947 5.56855 1.06191 5.38869 1.25076L1.19129 5.65803L1.19008 5.6593L1.18887 5.66057C1.09939 5.75453 1.05502 5.87698 1.05521 5.99956C1.05547 6.12382 1.10001 6.24609 1.18887 6.33939L1.19008 6.34066L1.19129 6.34193L5.38869 10.7492C5.56924 10.9388 5.85908 10.9392 6.0376 10.7517C6.21737 10.563 6.21505 10.2592 6.03518 10.0704L2.15862 5.99998Z'
                fill='white'
                stroke='white'
            />
        </svg>
    );
}

import React from 'react';

export default props => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'>
            <g fill='none' fillRule='evenodd'>
                <g>
                    <circle cx='20' cy='20' r='20' fill='#4E4F6B' />
                    <g stroke='#FFF' strokeWidth='2'>
                        <path d='M25.731 14l-5.865 5.866 5.865 5.865M14 14l5.865 5.866L14 25.73' />
                    </g>
                </g>
            </g>
        </svg>
    );
};

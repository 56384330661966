import React, {useState, useRef} from 'react';
import Modal from '../Modal/Modal';
import Checkbox from '../CheckBox/CheckBox';
import Button from '../Button/Button';
import Terms from './Terms/Terms';
import './TermsModal.scss';

function TermsModal({modalsState, closeTermsModal}) {
    const [isChecked, setIsChecked] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    return (
        <Modal
            title="Terms and Conditions"
            onHide={closeTermsModal}
            show={modalsState.terms.isOpened}
            contentClassName="consultation-modal-content"
            withoutScroll
            closable
        >
            <p className='terms-please-read-sign'>Please read to the end</p>
            <div className="termsContainer">
                <Terms onScrolledToBottom={() => setIsScrolled(true)}/>
            </div>
            <Checkbox
                label="I agree to the Terms and Conditions"
                checked={isChecked}
                onToggle={() => setIsChecked(prev => !prev)}
            />
            <Button
                onClick={modalsState.onConfirm}
                disabled={!isScrolled || !isChecked}
                type="primary"
                className={(!isScrolled || !isChecked) && 'disabledButton'}
            >
                Confirm
            </Button>
        </Modal>
    );
}

export default TermsModal;

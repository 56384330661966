import React from 'react';

export default ({disabled, onClick}) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='17'
        viewBox='0 0 16 17'
        onClick={disabled ? null : onClick}
        style={{cursor: disabled ? 'default' : 'pointer'}}
    >
        <path
            fill={disabled ? '#CCCCCC' : '#FFFFFF'}
            fillRule='evenodd'
            d='M7.1 0c3.9 0 7 3.2 7 7.1 0 1.6-.5 3-1.4 4.2l3 3.1c.4.4.4 1 0 1.4-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3l-3-3c-1.1.9-2.6 1.4-4.2 1.4C3.2 14.2 0 11 0 7.1 0 3.2 3.2 0 7.1 0zm0 2C4.3 2 2 4.3 2 7.1s2.3 5 5.1 5 5.1-2.2 5.1-5S9.9 2 7.1 2zM8 4v2h2v2H8v2H6V8H4V6h2V4h2z'
        />
    </svg>
);

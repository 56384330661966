import React, {FunctionComponent} from 'react';
import Button from '../Button/Button';
import './LeaveMessageButton.scss';

interface Props {
    onClick: () => void;
    display: boolean;
}

const LeaveMessageButton: FunctionComponent<Props> = (props: Props) => {
    const {onClick, display} = props;

    if (!display) {
        return null;
    }

    return (
        <div className='leave-message-button-wrapper'>
            <Button
                type='primary'
                onClick={onClick}
                className='leave-message-button'
            >
                Leave a message
            </Button>
        </div>
    );
};

export default LeaveMessageButton;

import React, {useState} from 'react';
import classNames from 'classnames';
import Button from '../Button/Button';
import CameraIcon from '../../assets/icons/CameraIcon';
import MicIcon from '../../assets/icons/MicIcon';
import MarkIcon from '../../assets/icons/MarkIcon';
import './DeviceSelect.scss';

export default function DeviceSelect({deviceType, selected, onSelect, mediaDevices}) {
    const [expanded, setExpanded] = useState(false);

    function handleDeviceSelect(device) {
        onSelect(device.deviceId);
        setExpanded(false);
    }

    function toggleExpand() {
        setExpanded(curVal => !curVal);
    }

    const devicesForType = mediaDevices.filter(d => d.kind === deviceType);

    return (
        <div
            className="deviceSelect"
        >
            <Button onClick={toggleExpand} type="primary" className={classNames("deviceButton", "center")}>
                <DeviceIcon deviceType={deviceType}/>
                <div className={classNames("arrow", expanded && "up")}/>
            </Button>
            {expanded &&
            <div className="devicesList">
                {devicesForType.map((el, i) => (<DeviceEntry
                    key={i}
                    name={el.label}
                    selected={el.deviceId === selected}
                    onClick={() => handleDeviceSelect(el)}
                />))}
            </div>}
        </div>
    );
}

function DeviceEntry({name, onClick, selected}) {
    return (
        <Button onClick={onClick} className="deviceButton">
            <span className="name">{name}</span>
            {selected && <MarkIcon/>}
        </Button>);
}

function DeviceIcon({deviceType}) {
    switch (deviceType) {
        case 'videoinput':
            return <CameraIcon/>;
        case 'audioinput':
            return <MicIcon/>;
        default:
            return <div>icon</div>;
    }
}

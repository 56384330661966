import React from 'react';
import './NumberDisplay.scss';

interface NumberDisplayProps {
    value: string;
    layoutColor: string;
};

export default function NumberDisplay(props: NumberDisplayProps) {
    const {
        value,
        layoutColor,
    } = props;
    return (
        <div style={{backgroundColor: layoutColor}} className="display-number-layout">
            <p className="display-number-value">{value}</p>
        </div>
    );
}

import React from 'react';
import moment from 'moment';
import validator from 'validator';
import PdfIcon from '../../assets/pdf-icon.svg';
import './ChatMessage.scss'
import classNames from 'classnames';

const renderAttachment = attachment => {
    if (attachment.name.endsWith('.pdf')) {
        return (
            <a title={attachment.name} key={attachment.url} href={attachment.url} download target='_blank' className='qr-chat-message-attachment-pdf'>
                <img src={PdfIcon} />
                <p>{attachment.name}</p>
            </a>
        );
    }
    return (
        <div key={attachment.url} className='qr-chat-message-attachment-image'>
            <a href={attachment.url} target='_blank'>
                <img src={attachment.url} />
            </a>
        </div>
    );
};

const renderConsultantInfo = ({sender}) => {
    return (
        <div className='chat-body-message-consultant-info-container'>
            {sender.userInfo.avatar && (
                <img
                    className='chat-body-message-consultant-img'
                    src={sender.userInfo.avatar}
                ></img>
            )}
            <p className='chat-body-message-consultant-name'>{sender.userInfo.first_name}</p>
        </div>
    );
};

const getDateFormat = timestamp => {
    const messageDate = moment(timestamp);
    if (moment(moment.now()).year() !== messageDate.year()) {
        return 'DD MMM YYYY';
    }
    return 'DD MMM';
};

const getTime = ({timestamp}) => {
    return moment(timestamp).format(`${getDateFormat(timestamp)} hh:mm A`);
};

const getURLString = word => {
    if (word.startsWith('http://') || word.startsWith('https://')) {
        return word;
    }
    return `//${word}`;
} 

const formatWord = (word, idx) => {
    if (validator.isURL(word)) {
        return <a key={idx} href={getURLString(word)} target='_blank'>{word}</a>
    }
    return word;
};

const getText = message => {
    const {text} = message;
    return text.split(' ').reduce((acc, word, idx) => {
        const words = word.split('\n');
        if (words.length < 2) {
            const [word] = words;
            return [...acc, formatWord(word, idx), ' '];
        }
        return [...acc, ...words.reduce((acc, word, innerIdx, words) => {
            if (innerIdx < words.length - 1) {
                return [...acc, formatWord(word, `${idx}${innerIdx}`), '\n'];
            }
            return [...acc, formatWord(word, `${idx}${innerIdx}`), ' '];
        }, []), ' ']
    }, []);
}

const getOrderedAttachments = attachments => {
    const images = [];
    const pdfs = [];
    attachments.forEach(attachment => {
        if (attachment.mimeType.startsWith('image')) {
            images.push(attachment);
            return;
        }
        pdfs.push(attachment);
    })
    return [...images, ...pdfs];
};


const ChatMessage = props => {
    const {isMyMessage, message, chatAi} = props;
    const messageClassName = isMyMessage ? 'chat-body-message-me' : 'chat-body-message-consultant';
    const messageType = isMyMessage ? 'right' : 'left';
    const {attachments} = message;
    return (
        <div key={message.id} className={classNames(`chat-body-message-container ${messageType}`, {'chat-ai': chatAi})}>
            {!isMyMessage && !chatAi && renderConsultantInfo(message)}
            <div className={messageClassName}>
                <div className='message-info'>
                    <div className='chat-body-message-time'>{getTime(message)}</div>
                </div>
                {message && message.text && <div className='chat-body-message-text'>{getText(message)}</div>}
                {attachments && attachments.length > 0 && <div className='qr-chat-message-attachments-container'>
                    {getOrderedAttachments(attachments).map(renderAttachment)}
                </div>}
            </div>
        </div>
    );
};

export default React.memo(ChatMessage);

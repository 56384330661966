import React, {FunctionComponent} from 'react';
import './PhoneRing.scss';

interface Props {
    fill?: string;
}

const PhoneRing: FunctionComponent<Props> = (props: Props) => {
    const {fill = '#fff'} = props;

    return (
        <div className='phone-ring-wrapper'>
            <div className='phone-ring-icon-wrapper'>
                <svg
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill={fill}
                    xmlns='http://www.w3.org/2000/svg'
                    className='phone-and-waves'
                >
                    <g>
                        <path
                            d='M18.096 8.52136C18.1362 8.9585 18.4879 9.3152 18.9269 9.3152C19.3659 9.3152 19.7248 8.95764 19.6958 8.51962C19.381 3.77454 16.2332 0.618125 11.4985 0.300812C11.0589 0.271347 10.7 0.631629 10.7 1.07226C10.7 1.51289 11.0581 1.86589 11.4968 1.90664C15.1276 2.24385 17.7612 4.88311 18.096 8.52136Z'
                            className='outer'
                        />
                        <path
                            d='M10.7 5.3269C10.7 5.76747 11.0592 6.11515 11.4961 6.17235C12.9169 6.35842 13.6522 7.09619 13.837 8.52111C13.8935 8.9571 14.2406 9.31548 14.6802 9.31548C15.1198 9.31548 15.4808 8.9572 15.449 8.51871C15.2606 5.92674 14.0837 4.74635 11.4984 4.55677C11.059 4.52455 10.7 4.88634 10.7 5.3269Z'
                            className='inner'
                        />
                    </g>
                    <path
                        d='M14.9467 12.9466C16.0192 13.5762 18.3326 14.9336 19.4051 15.5623C19.8346 15.8144 20.0429 16.3136 19.9257 16.7988C19.8103 17.2718 19.5318 17.8857 18.8888 18.5301C16.88 20.5431 13.4941 21.6109 5.9431 14.044C-1.6079 6.47711 -0.541458 3.08322 1.46647 1.07106C2.10773 0.427591 2.71948 0.147593 3.19066 0.0328111C3.67572 -0.0863185 4.17727 0.125854 4.42978 0.557155L7.03471 5.01625C7.24123 5.36929 7.2395 5.80668 7.03037 6.15798L5.97261 7.9371C5.73572 8.33536 5.76348 8.83275 6.0481 9.19796C6.5401 9.82926 7.36792 10.8458 8.2504 11.7301C9.13289 12.6145 10.1473 13.444 10.7772 13.9371C11.1417 14.2223 11.638 14.2501 12.0354 14.0127L13.8108 12.9527C14.1614 12.744 14.5944 12.7397 14.9467 12.9466Z'
                        className='phone'
                    />
                </svg>
            </div>
        </div>
    );
};

export default PhoneRing;

import React, {Component} from 'react';
import {ScreenConsumer} from '../../contexts/Screen';
import {ConsultationConsumer} from '../../contexts/Consultation';
import Consultation from './Consultation';
import SurveyRateChart from './SuveyRateChart';

class TransferConsultation extends Component {
    render() {
        const {room} = this.props;
        const {consultationState} = this.props.consultationState;

        if (consultationState === 'surveyRate') {
            return <SurveyRateChart room={room}/>;
        }

        return <Consultation/>;
    }
}

export default props => (
    <ConsultationConsumer>
        {({state: consultationState, actions: consultationAction}) => (
            <ScreenConsumer>
                {({state, actions}) => <TransferConsultation consultationState={consultationState}
                                                             consultationAction={consultationAction}
                                                             {...state}
                                                             {...actions}
                                                             {...props}/>}
            </ScreenConsumer>
        )}
    </ConsultationConsumer>
);
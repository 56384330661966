import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Scrollbars} from 'react-custom-scrollbars-2';
import Text from 'react-textarea-autosize';
import classNames from 'classnames';

import './TextArea.scss';

class TextArea extends Component {

    static defaultProps = {
        autoHeight: true,
        autoHeightMax: '70px',
        autoHeightMin: '70px',
    };

    static propTypes = {
        autoHeight: PropTypes.bool,
        autoHeightMax: PropTypes.string,
        autoHeightMin: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            value: ''
        };
    }

    onChange(e) {
        this.setState({value: e.target.value});
    }

    get value() {
        return this.state.value;
    }

    getCharactersLeft() {
        return this.props.maxLength - this.state.value.length;
    }

    render() {
        const {autoHeight, autoHeightMax, autoHeightMin, containerClassName, ...restProps} = this.props;
        return (
            <div className={classNames("webscreenTextAreaContainer", containerClassName)}>
                <Scrollbars autoHeight={autoHeight} autoHeightMax={autoHeightMax} autoHeightMin={autoHeightMin}>
                    <Text onChange={this.onChange.bind(this)} className="webscreenTextArea" {...restProps}/>
                </Scrollbars>
                <p className="webscreenTextAreaCharCounter">{this.getCharactersLeft()}</p>
            </div>
        );
    }
}

export default TextArea;

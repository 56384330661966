import React from 'react';

export default props => {
    const {color} = props;
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48'>
            <g fill='none' fillRule='evenodd'>
                <circle cx='24' cy='24' r='24' fill={color} />
                <g>
                    <path
                        fill='#FFF'
                        d='M22.746 6.8c3.165 0 4.312.33 5.47.949 1.156.618 2.064 1.527 2.683 2.684l.124.24c.538 1.085.824 2.284.824 5.23v4.873l-.005.627c-.042 2.697-.367 3.767-.943 4.844-.619 1.157-1.527 2.066-2.684 2.685l-.24.124c-1.084.538-2.282.824-5.229.824h-9.693l-4.668 3.167c-.405.275-.957.17-1.232-.237-.1-.147-.153-.32-.153-.498V15.904c0-3.166.33-4.314.948-5.471.619-1.157 1.527-2.066 2.684-2.684l.24-.124c1.044-.52 2.194-.803 4.908-.824l6.966-.001zm-6.943 5.5c-.16-.27-.476-.404-.781-.33-.297.073-.683.249-1.087.654-1.266 1.265-1.938 3.4 2.821 8.16 4.76 4.758 6.894 4.087 8.16 2.82.405-.405.58-.79.653-1.088.074-.305-.057-.62-.328-.778l-2.81-1.645c-.222-.13-.495-.128-.716.004l-1.119.666c-.25.15-.563.132-.793-.047-.397-.31-1.036-.832-1.592-1.388-.557-.556-1.078-1.196-1.388-1.593-.18-.23-.197-.542-.048-.793l.667-1.119c.131-.22.133-.496.002-.718z'
                        transform='translate(5 4)'
                    />
                </g>
            </g>
        </svg>
    );
};

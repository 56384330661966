import React from 'react';

export default (props) => {
    return (
        <svg viewBox="0 0 19 19" {...props}>
            <g fill="#FFF">
                <rect width="5.637" height="19.917" x="6.302" y="-.641" rx="2.819" transform="rotate(45 9.12 9.318)"/>
                <rect width="5.637" height="19.917" x="6.302" y="-.641" rx="2.819" transform="rotate(-45 9.12 9.318)"/>
            </g>
        </svg>
    );
}
import React, {Component, useEffect, useMemo, useState} from 'react';
import {CookiesProvider} from 'react-cookie';
import IOController from '../../controllers/IOController';
import {AppProvider} from '../../contexts/App';
import {ScreenProvider} from '../../contexts/Screen';
import {TemplateProvider} from '../../contexts/Template';
import {DepartmentsProvider} from '../../contexts/Departments';
import {ConsultationProvider} from '../../contexts/Consultation';
import Content from '../Content/Content';
import './App.scss';
import withBrowserCheck from '../../hocs/withBrowserCheck';
import WrongBrowser from '../../components/WrongBrowser/WrongBrowser';
import { getBrowserOs } from '../../utils/browserFeatures';
import { ChatGPTContextProvider } from '../../contexts/ChatGPTContext';


const recommendedBrowsers = {
    ios: {
        is: ['safari', 'chrome']
    },
    android: [
        {
            is: ['chrome']
        },
        {
            brands: ['samsung'],
            is: ['samsung', 'chrome']
        }
    ]
}

const ContentWithBrowserCheck = withBrowserCheck(Content, recommendedBrowsers, WrongBrowser)

function isScreenInPreviewMode() {
    try {
        return location.pathname.slice(1).split('/')[0] === 'preview';
    } catch (err) {
        return false;
    }
}

const App = (props) => {

    const [ isConnected, setIsConnected ] = useState(false);
    const [isInitiallyConnected, setIsInitiallyConnected] = useState(false);
    
    useEffect(() => {
        if (isScreenInPreviewMode()) {
            IOController.fakeConnect();
            setIsConnected(true);
            setIsInitiallyConnected(true);
            return;
        }
        IOController.connect().then(() => {
            console.log('CONNECTED');

            setIsConnected(true);
            setIsInitiallyConnected(true);
        });
        IOController.socket.on('disconnect', () => {
            if (window.__QR_STOP_UPDATE_LOADER_FUNCTION__) {
                window.__QR_STOP_UPDATE_LOADER_FUNCTION__();
            }
            console.log('DISCONNECTED');
            setIsConnected(false);
        });
        IOController.socket.on('reconnect', () => {
            console.log('RECONNECTED');
            setIsConnected(true);
        });
    }, []);

    return (
        <CookiesProvider>
            <div className="appContainer">
                <AppProvider connected={isConnected} isInitiallyConnected={isInitiallyConnected}>
                    <ScreenProvider connected={isConnected} isInitiallyConnected={isInitiallyConnected}>
                        <TemplateProvider isInitiallyConnected={isInitiallyConnected}>
                            <ChatGPTContextProvider>
                                <DepartmentsProvider isInitiallyConnected={isInitiallyConnected}>
                                    <ConsultationProvider connected={isConnected} isInitiallyConnected={isInitiallyConnected}>
                                        <ContentWithBrowserCheck/>
                                    </ConsultationProvider>
                                </DepartmentsProvider>
                            </ChatGPTContextProvider>
                        </TemplateProvider>
                    </ScreenProvider>
                </AppProvider>
            </div>
        </CookiesProvider>
    );
}

export default App;

import React, {useRef, useEffect} from 'react';

const TERMS_AND_CONDITIONS_URL = process.env.TERMS_AND_CONDITIONS_URL;
const WAS_SCROLLED_TO_BOTTOM_MESSAGE = 'wasScrolledToBottom';

function getOriginOrUrl(url) {
    try {
        return (new URL(TERMS_AND_CONDITIONS_URL)).origin;
    }
    catch (err) {
        return '*';
    }
}

function Terms(props) {
    const {onScrolledToBottom} = props;
    const iframeRef = useRef(null);

    const addIframeListener = () => {
        if (!iframeRef.current) {
            return;
        }
        iframeRef.current.contentWindow.postMessage('wasScrolledToBottom', getOriginOrUrl(TERMS_AND_CONDITIONS_URL))
    }

    useEffect(() => {
        if (!onScrolledToBottom) {
            return () => {};
        }
        const onMessageHandler = (ev) => {
            const {
                message,
                result,
            } = ev.data;
            if (message === WAS_SCROLLED_TO_BOTTOM_MESSAGE && result) {
                onScrolledToBottom();
            }
        }
        window.addEventListener('message', onMessageHandler);
        return () => {
            window.removeEventListener('message', onMessageHandler);
        }
    }, [onScrolledToBottom]);

    return (
        <iframe
            src={TERMS_AND_CONDITIONS_URL}
            style={{
                width: '100%',
                height: '100%',
                border: 'unset',
            }}
            onLoad={addIframeListener}
            ref={iframeRef}
        />
    );
}

export default Terms;

import React, {Component} from 'react';
import classnames from 'classnames';
import Audio from '../../assets/icons/AudioCall';
import Video from '../../assets/icons/VideoCall';
import Chat from '../../assets/icons/ChatButton';
import Menu from '../../assets/icons/MenuButton';
import Close from '../../assets/icons/CloseButton';
import ChatNewMessagesIcon from '../../assets/icons/ChatNewMessages';
import {AppConsumer} from '../../contexts/App';
import './CallButtons.scss';
import CallIcon from '../../assets/icons/CallIcon';
import ChatAiIcon from '../../assets/icons/chatAIIcon';
import { ScreenConsumer } from '../../contexts/Screen';

const RESET_BUTTONS_STATE_MS = 2000;

class CallButtons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            audioButtonClicked: false,
            videoButtonClicked: false,
            qrChatButtonClicked: false,
        };
        this.resetButtonStateTimer = null;
    }

    componentWillUnmount() {
        if (this.resetButtonStateTimer) {
            clearTimeout(this.resetButtonStateTimer);
        }
    }

    resetButtonsState() {
        if (this.resetButtonStateTimer) {
            clearTimeout(this.resetButtonStateTimer);
        }
        this.resetButtonStateTimer = setTimeout(() => {
            this.setState({
                audioButtonClicked: false,
                videoButtonClicked: false,
                qrChatButtonClicked: false,
            });
        }, RESET_BUTTONS_STATE_MS);
    }

    renderNewChatMessageIcon() {
        return (
            <div className='chat-new-message-icon'>
                <ChatNewMessagesIcon />
            </div>
        );
    }

    render() {
        let {
            isVideoConsultationEnabled,
            isAudioConsultationEnabled,
            requestChat,
            requestAIChat,
            requestConsultationWithType,
            consultationRequestProgress,
            color,
            forceBigIcons,
            departmentsState: {isUpdatingDepartments, gotDepartments},
            showChatWasNotReadIcon,
            isQrChatEnabled,
            connected,
            isInHeader,
            isExpanded,
            setExpanded,
            disabled,
            audioButtonClassName,
            videoButtonClassName,
            chatAiButtonClassName,
            children,

            isConciergeCallEnabled,
            onConciergeCall,
            conciergeButtonClassname,
            qrCode,
        } = this.props;

        requestConsultationWithType =
            requestConsultationWithType || (() => {});
        const buttonDisabled =
            disabled ||
            !gotDepartments ||
            isUpdatingDepartments ||
            consultationRequestProgress ||
            !connected;
        const buttonContainerClassName = classnames(
            'buttonContainer',
            buttonDisabled && 'disabled',
            !forceBigIcons && 'small',
        );

        const {
            audioButtonClicked,
            videoButtonClicked,
            qrChatButtonClicked,
        } = this.state;

        const consultationTypes = [isQrChatEnabled, isAudioConsultationEnabled, isVideoConsultationEnabled];
        const hasManyConsultationTypes = consultationTypes.filter(t => t).length > 1;
        const isExpandable = isInHeader && hasManyConsultationTypes;
        const showCollapsedButtons = isExpandable && !isExpanded || !hasManyConsultationTypes;

        return (
            <div className={classnames('mw-call-buttons', !isInHeader && 'bottomButtons', showCollapsedButtons && 'collapsedButtons')}>
                {!isExpanded && isExpandable && (
                    <div
                        className={buttonContainerClassName}
                        onClick={() => setExpanded(true)}
                    >
                        <Menu color={color} className='mw-call-button' />
                        {showChatWasNotReadIcon && this.renderNewChatMessageIcon()}
                        <div className='icon-shadow' />
                    </div>
                )}
                {(!isExpandable || isExpanded) && (
                    <React.Fragment>
                        {children}
                        {qrCode?.useAiAssistant && !qrCode?.aiDisabled && (
                            <div
                                className={classnames('buttonContainer', chatAiButtonClassName, {small: !forceBigIcons})}
                                onClick={() => {
                                    this.setState({qrChatButtonClicked: true}, () => {
                                        this.resetButtonsState();
                                        requestAIChat();
                                    })
                                }}
                            >
                                <ChatAiIcon />
                                <div className='icon-shadow' />
                                <p style={{color}}>AI Assistant</p>
                            </div>
                        )}
                        {isQrChatEnabled && (
                            <div
                                className={classnames(buttonContainerClassName, buttonDisabled && (qrChatButtonClicked || disabled) && 'withClick')}
                                onClick={() => {
                                    this.setState({qrChatButtonClicked: true}, () => {
                                        this.resetButtonsState();
                                        requestChat();
                                    })
                                }}
                            >
                                <Chat color={color} className='mw-call-button' />
                                {showChatWasNotReadIcon &&
                                this.renderNewChatMessageIcon()}
                                <div className='icon-shadow' />
                                <p style={{color}}>Chat</p>
                            </div>
                        )}
                        {
                            isConciergeCallEnabled && (
                                <div
                                    className={classnames(
                                        'buttonContainer',
                                        !forceBigIcons && 'small',
                                        'withClick',
                                        conciergeButtonClassname,
                                    )}
                                    onClick={onConciergeCall}
                                >
                                    <CallIcon color={color} className='mw-call-button' />
                                    <div className='icon-shadow' />
                                    <p style={{color}}>Concierge</p>
                                </div>
                            )
                        }
                        {isAudioConsultationEnabled && (
                            <div
                                className={classnames(
                                    buttonContainerClassName,
                                    buttonDisabled && (audioButtonClicked || disabled) && 'withClick',
                                    audioButtonClassName,
                                )}
                                onClick={() => {
                                    this.setState({audioButtonClicked: true}, () => {
                                        this.resetButtonsState();
                                        requestConsultationWithType('audio');
                                    })
                                }}
                            >
                                <Audio color={color} className='mw-call-button' />
                                <div className='icon-shadow' />
                                <p style={{color}}>Audio call</p>
                            </div>
                        )}
                        {isVideoConsultationEnabled && (
                            <div
                                className={classnames(
                                    buttonContainerClassName,
                                    buttonDisabled && (videoButtonClicked || disabled) && 'withClick',
                                    videoButtonClassName,
                                )}
                                onClick={() => {
                                    this.setState({videoButtonClicked: true}, () => {
                                        this.resetButtonsState();
                                        requestConsultationWithType(
                                            isAudioConsultationEnabled
                                            ? 'audiovideo'
                                            : 'video'
                                        );
                                    })
                                }}
                            >
                                <Video color={color} className='mw-call-button' />
                                <div className='icon-shadow' />
                                <p style={{color}}>Video call</p>
                            </div>
                        )}
                        {isExpandable && (
                            <div
                                className={buttonContainerClassName}
                                onClick={() => setExpanded(false)}
                            >
                                <Close className='mw-call-button' />
                                <div className='icon-shadow' />
                            </div>
                        )}
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default props => (
    <AppConsumer>
        {({state, actions}) => (
            <ScreenConsumer>
                {({state: screenState}) =>
                    <CallButtons
                        connected={state.connected}
                        isExpanded={state.isCallButtonsExpanded}
                        setExpanded={actions.setCallButtonsExpanded}
                        qrCode={screenState?.qrCode}
                        {...props}
                    />
                }
            </ScreenConsumer>
        )}
    </AppConsumer>
);

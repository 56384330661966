import React, {Component} from 'react';
import _ from 'lodash';
import ScreenHeader from '../ScreenHeader';
import logoImage from '../../../assets/logo.svg';
import './NavigationScreen.scss';
import classnames from 'classnames';

class NavigationScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {device: this.getDeviceType()};
    }

    componentDidMount() {
        this.onResize = _.debounce(this.onScreenResize.bind(this), 100);
        const {previewType} = this.props;
        if (previewType === undefined) {
            window.addEventListener('resize', this.onResize);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    getDeviceType() {
        return window.innerHeight > window.innerWidth ? 'mobile' : 'desktop';
    }

    onScreenResize() {
        this.setState({device: this.getDeviceType()});
    }

    getImage() {
        const {previewType, screen} = this.props;
        const getImageByDevice = device => device === 'desktop'
            ? _.get(screen, 'settings.options.desktopImage')
            : _.get(screen, 'settings.options.image');
        if (previewType !== undefined) {
            return getImageByDevice(previewType);
        } else {
            const {device} = this.state;
            return getImageByDevice(device);
        }
    }

    getDeviceType() {
        return window.innerHeight > window.innerWidth ? 'mobile' : 'desktop';
    }

    render() {
        const {
            screen,
            room,
            isVideoConsultationEnabled,
            isAudioConsultationEnabled,
            requestConsultationWithType,
            requestPhoneConsultation,
            consultationRequestProgress,
            logo,
            departmentsState,
            onChangeScreenById,
            requestChat,
            showChatWasNotReadIcon,
            isQrChatEnabled,
            onQrLogoClick,
            isCallButtonsExpanded,
            requestAIChat,
        } = this.props;
        const {
            buttonColor,
            backgroundColor,
            buttons = [],
            textColor = '#FFFFFF',
        } = screen.settings;
        const showTeleportivityLogo = room && room.roomInfo.powered_by_logo;
        const image = this.getImage() ;
        const uploadedLogoClassName = classnames('mw-uploaded-logo', isCallButtonsExpanded && 'withExpandedCallButtons');

        return (
            <div
                className='mw-screen-container mw-navigation-screen'
                style={{background: backgroundColor}}
            >
                <ScreenHeader
                    room={room}
                    screen={screen}
                    isVideoConsultationEnabled={isVideoConsultationEnabled}
                    isAudioConsultationEnabled={isAudioConsultationEnabled}
                    requestConsultationWithType={requestConsultationWithType}
                    requestChat={requestChat}
                    requestAIChat={requestAIChat}
                    requestPhoneConsultation={requestPhoneConsultation}
                    consultationRequestProgress={consultationRequestProgress}
                    departmentsState={departmentsState}
                    showChatWasNotReadIcon={showChatWasNotReadIcon}
                    isQrChatEnabled={isQrChatEnabled}
                    logo={logo}
                    isCallButtonsExpanded={isCallButtonsExpanded}
                />
                {logo && (
                    <div onClick={onQrLogoClick} className={uploadedLogoClassName}>
                        <img src={logo.url} />
                    </div>
                )}

                <div className="mv-navigation-screen-grid-wrapper">
                    <div className='mv-navigation-screen-buttons-container customYScroll'>
                        {buttons &&
                        buttons.map((el, i) => {
                            const background = el.isCustomColor ? el.color || buttonColor : buttonColor;
                            const color = el.isCustomTextColor ? el.textColor || textColor : textColor;
                            if (el.buttonType === 'screen') {
                                return (
                                    <button
                                        key={`button${i}`}
                                        onClick={() =>
                                            onChangeScreenById(el.link)
                                        }
                                        style={{
                                            background,
                                            color,
                                        }}
                                    >
                                        <span>{el.name}</span>
                                    </button>
                                );
                            } else {
                                return (
                                    <a
                                        key={`button${i}`}
                                        href={el.link}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        style={{
                                            background,
                                            color,
                                        }}
                                    >
                                        {el.name}
                                    </a>
                                );
                            }
                        })}
                    </div>
                    {image && <div className="image-container">
                        <img src={image.url ? image.url : URL.createObjectURL(image.file)}/>
                    </div>}
                </div>
            </div>
        );
    }
}

export default NavigationScreen;

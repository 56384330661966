import React, {FunctionComponent} from 'react';
import classnames from 'classnames';
import HangOut from '../../assets/icons/HangOut';
import './HangoutButton.scss';

interface Props {
    isConsultationEnded: boolean;
    disabled: boolean;
    onClick: () => void;
}

const HangoutButton: FunctionComponent<Props> = (props: Props) => {
    const {isConsultationEnded, onClick, disabled} = props;

    if (isConsultationEnded) {
        return (
            <div className='hang-out-button-animated'>
                <div className='circle-left' />
                <div className='circle-right' />
            </div>
        );
    }

    return (
        <div onClick={onClick} className={classnames('hang-out-icon', disabled && 'disabled')}>
            <HangOut />
        </div>
    );
};

export default HangoutButton;

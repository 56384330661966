import React from 'react';

export default (props) => {
    return (
        <svg viewBox="0 0 18 18" {...props}>
            <g fill="#5364E4" transform="translate(.552 .078)">
                <rect width="6.303" height="16.808" y=".664" rx="3.152"/>
                <rect width="6.303" height="16.808" x="10.366" y=".664" rx="3.152"/>
            </g>
        </svg>
    );
}
import React, {useState} from 'react';
import classnames from 'classnames';
import Close from './Icons/Close/Close';
import LeftArrow from './Icons/LeftArrow/LeftArrow';
import RightArrow from './Icons/RightArrow/RightArrow';
import './Letters.scss';

interface KeyboardProps {
    onKeyboardClick: (v: string) => void;
    controlButtonColor: string;
    toggleAdditionalKeyboard: () => void;
}

const LETTERS_IN_ROW_MAX = 4;
const LETTERS_IN_ROW_MIN = 3;

const letters = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
];

export default function Letters(props: KeyboardProps) {
    const {onKeyboardClick, controlButtonColor, toggleAdditionalKeyboard} =
        props;

    const [offset, setOffset] = useState<number>(0);
    const limit =
        window.screen.width < 720 ? LETTERS_IN_ROW_MIN : LETTERS_IN_ROW_MAX;

    const changeLetters = (modifier: number) => {
        if (
            offset + modifier < 0 ||
            offset + limit + modifier > letters.length
        ) {
            return;
        }
        setOffset(prev => prev + modifier);
    };
    
    return (
        <div
            className='additional-letters'
            style={{backgroundColor: controlButtonColor}}
        >
            <button
                className='close-letter-button'
                onClick={() => toggleAdditionalKeyboard()}
            >
                <div className='icon-with-border'>
                    <Close />
                </div>
            </button>
            <div className='letters-slider'>
                <button
                    onClick={() => changeLetters(-1)}
                    disabled={offset === 0}
                    className={classnames(
                        'slider-button',
                        'left-slider-button',
                        {['slider-button-disabled']: offset === 0}
                    )}
                >
                    <LeftArrow />
                </button>
                <div className='letters'>
                    {letters.slice(offset, limit + offset).map(l => {
                        return (
                            <div
                                key={l}
                                className='letter'
                                onClick={() => onKeyboardClick(l)}
                            >
                                {l}
                            </div>
                        );
                    })}
                </div>
                <button
                    onClick={() => changeLetters(+1)}
                    disabled={offset + limit === letters.length}
                    className={classnames(
                        'slider-button',
                        'left-slider-button',
                        {
                            ['slider-button-disabled']:
                                offset + limit === letters.length,
                        }
                    )}
                >
                    <RightArrow />
                </button>
            </div>
        </div>
    );
}

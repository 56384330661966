import React, {Component} from 'react';

import {ScreenConsumer} from '../../contexts/Screen';
import {ConsultationConsumer} from '../../contexts/Consultation';
import Consultation from './Consultation';
import DepartmentsList from './DepartmentsList';
import ConsultationRequest from './ConsultationRequest';
import SurveyRateChart from './SuveyRateChart';
import TapMe from './TapMe';
import LeavePhoneNumber from './LeavePhoneNumber';
import MiniWebsite from './MiniWebsite';
import Chat from '../Chat/Chat';
import OperatorOfflineModal from './OperatorOfflineModal';
import TermsModal from '../../components/TermsModal/TermsModal';
import DevicesModal from '../../components/DevicesModal/DevicesModal';

class QRConsultation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            miniWebsiteScreen: 0,
            qrScreenRegistered: false,
            screenName: null,
        };
    }

    setMiniWebsiteScreen({index, screenId, screenName}) {
        document.location.hash = index;
        if (this.state.screenName !== screenName) {
            this.setState({screenName});
        }
        this.setState({miniWebsiteScreen: index});
    }

    componentDidUpdate(prevProps, prevState) {
        const isGotGeoLocation =
            !prevProps.isGeoLocationReady && this.props.isGeoLocationReady;
        const isScreenChanged =
            prevState.screenName !== this.state.screenName &&
            this.props.isGeoLocationReady;

        if (isGotGeoLocation || isScreenChanged) {
            this.setState({qrScreenRegistered: true}, () =>
                this.props.registerScreenVisit(this.state.screenName)
            );
        }
    }

    renderContent() {
        const {room, user} = this.props;
        const {
            consultationRequestState,
            chatState,
            consultationState,
            inWaitingToCallState,
        } = this.props;
        const {backFromChat, sendQrChatMessage, backFromAIChat} =
            this.props.consultationAction;

        const {miniWebsiteScreen} = this.state;

        if (inWaitingToCallState) {
            return <Consultation />;
        }

        if (consultationRequestState === 'chooseDepartments') {
            return <DepartmentsList miniWebsiteScreen={miniWebsiteScreen} />;
        }

        if (consultationRequestState === 'requestedOperatorIsOffline') {
            const {toStartState, sendConsultationRequestSignalToOnlineMembers} =
                this.props.consultationAction;
            const {requestedOperatorName} = this.props;
            return (
                <OperatorOfflineModal
                    toStartState={toStartState}
                    sendConsultationRequestSignalToOnlineMembers={
                        sendConsultationRequestSignalToOnlineMembers
                    }
                    requestedOperatorName={requestedOperatorName}
                />
            );
        }

        if (consultationRequestState === 'requestSended') {
            return <ConsultationRequest room={room} />;
        }

        if (
            chatState === 'chooseDepartments' &&
            consultationState !== 'phoneNumber'
        ) {
            return (
                <DepartmentsList isChat miniWebsiteScreen={miniWebsiteScreen} />
            );
        }

        switch (consultationState) {
            case 'miniWebsite':
                return (
                    <MiniWebsite
                        room={room}
                        miniWebsiteScreen={miniWebsiteScreen}
                        setMiniWebsiteScreen={this.setMiniWebsiteScreen.bind(
                            this
                        )}
                    />
                );
            case 'tapme':
                return <TapMe />;
            case 'started':
                return <Consultation />;
            case 'surveyRate':
                return <SurveyRateChart room={room} />;
            case 'phoneNumber':
                return <LeavePhoneNumber room={room} />;
            case 'chat':
                return (
                    <Chat
                        room={room}
                        onMessageSend={sendQrChatMessage}
                        user={user}
                        onChatClose={backFromChat}
                    />
                );
            case 'chatai':
                return (
                    <Chat
                        room={room}
                        onMessageSend={sendQrChatMessage}
                        user={user}
                        chatAi
                        onChatClose={backFromAIChat}
                    />
                );
        }

        return null;
    }

    renderModals() {
        const {
            modalsState,
            consultationAction: {closeTermsModal, closeDevicesModal},
        } = this.props;

        return (
            <React.Fragment>
                <TermsModal
                    modalsState={modalsState}
                    closeTermsModal={closeTermsModal}
                />
                <DevicesModal
                    modalsState={modalsState}
                    closeDevicesModal={closeDevicesModal}
                />
            </React.Fragment>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.renderContent()}
                {this.renderModals()}
            </React.Fragment>
        );
    }
}

export default props => (
    <ConsultationConsumer>
        {({state: consultationState, actions: consultationAction}) => (
            <ScreenConsumer>
                {({state, actions}) => (
                    <QRConsultation
                        consultationRequestState={
                            consultationState.consultationRequestState
                        }
                        consultationAction={consultationAction}
                        consultationState={consultationState.consultationState}
                        requestedOperatorName={
                            consultationState.requestedOperatorName
                        }
                        chatState={consultationState.chatState}
                        modalsState={consultationState.modalsState}
                        inWaitingToCallState={
                            consultationState.inWaitingToCallState
                        }
                        {...state}
                        {...actions}
                        {...props}
                    />
                )}
            </ScreenConsumer>
        )}
    </ConsultationConsumer>
);

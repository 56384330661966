import React, { useState } from 'react';
import {detect} from 'detect-browser';
import Modal from '../components/Modal/Modal';
import { getOsAndBrowser } from '../utils/browserFeatures';

/**
 * 
 * @param {React.Component} Component 
 * @param {*} browserCriteria 
 * accepts object with keys as os names ('windows', 'linux', 'osx', 'android', 'ios') or "*" for unversal condition
 * values are objects with following keys:
 *  - "is": string[] for browser names matching a pattern,
 *  - "not": string[] for browsers not matching certain pattern,
 *  - "brands": string[] - array of device makers to apply constraints to
 * 
 * e. g.
 * {
 *      windows: {
 *          not: ["edge", "internet explorer"]
 *      }
 * }
 * will return success for all browsers except win+edge, win + internet explorer
 * @returns 
 */

const checkAgainstConditions = (conditions, checkSubject) => {
    let match = true;

    const { brand, browser } = checkSubject;

    if (conditions.brands) {
        if (!brand || !conditions.brands.includes(brand)) return match;
    }
    if (conditions.is) {
        match &&= Boolean(conditions.is.find( q => q.toLowerCase().includes(browser)));
    }

    if (conditions.not) {
        match &&= !Boolean(conditions.not.find( q => q.toLowerCase().includes(browser)));
    }

    return match;
}

const DefaultErrorComponent = (props) => {
    return (<Modal
        title="Warning"
        show={isErrorModalOpen}
        onHide={()=>{setIsErrorModalOpen(false)}}
        closable
    >
        Please use another browser
</Modal>)
}

const withBrowserCheck = (Component, browserCriteria, ErrorComponent = DefaultErrorComponent) => (props) => {
    const [
        browserName,
        browserOs,
        isMobile,
        brand
    ] = getOsAndBrowser();


    const recommendedBrowsers = [];
    
    const doesMatchConditions = Object.entries(browserCriteria).reduce((acc, [os, conditions]) => {
        let isCheckPassed = true;
        switch (os) {
            case ('mobile'):
                    isCheckPassed &&= isMobile
                    recommendedBrowsers.push(...is)
                break;
            case ('desktop'):
                    isCheckPassed &&= !isMobile
                    recommendedBrowsers.push(...is)
                break;
            default:
                if (os.includes(browserOs) || os === '*') {
                    const applicableConditions = Array.isArray(conditions)
                        ? conditions
                            .filter(({brands}) => !brands || brands.includes(brand))
                            .reduce((stack, cond) => {
                                const { is = [], not = [] } = cond;
                                const processedIs = new Set([...is, ...stack.is ])
                                const processedNot = new Set([...not, ...stack.not])
                                return ({ is: [...processedIs], not: [...processedNot]})
                            }, {is: [], not: []})
                        : conditions;

                    isCheckPassed &&= checkAgainstConditions(applicableConditions, {browser: browserName, brand})
                    if (applicableConditions.is) recommendedBrowsers.push(...applicableConditions.is)
                }
                break;
        }
        return acc && isCheckPassed;
    }, true);
    const [ isErrorModalOpen, setIsErrorModalOpen ] = useState(!doesMatchConditions);
    return (
        <>

            <Component {...props}/>
            {
                (!doesMatchConditions) && 
                    <ErrorComponent
                        show={isErrorModalOpen}
                        onHide={()=>{setIsErrorModalOpen(false)}}
                        recommendedBrowsers={recommendedBrowsers}
                        closable 
                    />
            }
        </>
    )
};

export default withBrowserCheck
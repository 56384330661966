import React from 'react';

export default props => (
    <svg viewBox="0 0 38 38" {...props}>
        <path
            fill="#FFF"
            d="M24 17.5V14c0-.55-.45-1-1-1H11c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"
        />
    </svg>
);

import React from 'react';

export default props => (
    <svg width="176" height="206" viewBox="0 0 176 206">
        <g fill="none" fillRule="evenodd">
            <rect width="174" height="204" x="1" y="1" fill="#F5F6F7" stroke="#BBC0CB" strokeWidth="2" rx="4"/>
            <path fill="#E7EAEE" d="M15 127h146v63H15z"/>
            <path fill="#E7E9EB" d="M15 52h146v63H15z"/>
            <path fill="#E7E9EB" stroke="#BBC0CB" strokeWidth="2" d="M46 14h113v8H46z"/>
            <path fill="#BBC0CB" d="M15 14h6v8h-6zM28 14h6v8h-6zM0 34h176v2H0z"/>
        </g>
    </svg>
);

import React from 'react';
import './VoiceToText.scss';

const PARTICIPANT_NAMES = {
    operator: 'Operator',
    screen: 'Me',
};

function VoiceToText({recognitionPhrases, recognitionEnabled, width}) {
    return (
        <div className='voiceToText' style={{width}}>
            {recognitionEnabled && (
                <>
                    {recognitionPhrases.map(recognitionPhrase => (
                        <div className='participant' key={recognitionPhrase.id}>
                            <div className='participant-name'>
                                {PARTICIPANT_NAMES[recognitionPhrase.type]}:
                            </div>
                            <div>
                                <div
                                    className='phrase'
                                    key={recognitionPhrase.id}
                                >
                                    {recognitionPhrase.text}
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            )}
        </div>
    );
}

export default VoiceToText;

import React, {Component} from 'react';
import CrossIcon from '../../assets/icons/Cross';
import './Warning.scss';

class Warning extends Component {
    render() {
        return (
            <div className="warningContainer">
                <div className="roundBox">
                    <CrossIcon/>
                </div>
                <span dangerouslySetInnerHTML={{__html: this.props.text.split('\n').join('<br>')}}/>
            </div>
        );
    }
}

export default Warning;
import React from 'react';

export default (props) => {
    return (
        <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_12591_135101)">
                <path fillRule="evenodd" clipRule="evenodd" d="M47 94C72.9574 94 94 72.9574 94 47C94 21.0426 72.9574 0 47 0C21.0426 0 0 21.0426 0 47C0 72.9574 21.0426 94 47 94Z" fill="#536AE2" />
                <g clipPath="url(#clip1_12591_135101)">
                    <path opacity="0.025" d="M69.2427 42.3391C106.789 65.0078 39.602 99.92 38.1722 68.4747C37.4572 52.8362 7.98285 56.9933 11.1839 41.5779C14.3758 26.1667 26.8154 29.8365 40.5167 24.5202C57.5863 17.8115 59.3148 36.3411 69.2427 42.3391Z" stroke="#E5E5FE" strokeWidth="1.10085" strokeMiterlimit="10" />
                    <path opacity="0.05" d="M69.6718 41.6201C104.656 64.5995 41.7036 99.4889 38.0758 69.1556C36.2884 54.2196 7.76194 55.2114 11.7241 40.4705C15.6679 25.7382 27.9441 28.1401 41.1713 24.1033C57.6715 19.0612 60.1636 35.3707 69.6718 41.6201Z" stroke="#E5E5FE" strokeWidth="1.10085" strokeMiterlimit="10" />
                    <path opacity="0.075" d="M70.0976 40.8913C102.524 64.1074 43.8308 99.0943 37.9892 69.8325C35.1531 55.6179 7.54432 53.4225 12.2768 39.3518C16.9816 25.2941 29.0669 26.399 41.8411 23.668C57.7299 20.2823 61.0052 34.3803 70.0976 40.8913Z" stroke="#C6C5FE" strokeWidth="1.10085" strokeMiterlimit="10" />
                    <path opacity="0.1" d="M70.5265 40.173C100.402 63.5446 46.0083 98.7482 37.8991 70.5169C34.0313 57.0398 7.32583 51.6339 12.8233 38.248C18.28 24.8692 30.1744 24.6771 42.502 23.2547C57.7834 21.4935 61.8354 33.3771 70.5265 40.173Z" stroke="#C6C5FE" strokeWidth="1.10085" strokeMiterlimit="10" />
                    <path opacity="0.125" d="M70.9532 39.4446C98.2985 62.8717 48.2323 98.4051 37.8108 71.2015C32.9376 58.4735 7.10649 49.8527 13.3677 37.1341C19.5763 24.4342 31.2758 22.935 43.1609 22.8314C57.8387 22.7048 62.6688 32.3495 70.9532 39.4446Z" stroke="#C6C5FE" strokeWidth="1.10085" strokeMiterlimit="10" />
                    <path opacity="0.15" d="M71.3766 38.7233C96.217 62.116 50.5107 98.0779 37.7152 71.883C31.8615 59.8983 6.88645 48.0713 13.9087 36.0273C20.8758 24.0092 32.3673 21.1971 43.8098 22.4123C57.8802 23.9102 63.5001 31.3042 71.3766 38.7233Z" stroke="#C6C5FE" strokeWidth="1.10085" strokeMiterlimit="10" />
                    <path opacity="0.175" d="M71.8069 38.005C94.1793 61.2619 52.8487 97.7349 37.6265 72.5674C30.8267 61.316 6.66942 46.2827 14.4567 34.9234C22.1756 23.5842 33.4684 19.4548 44.4722 21.999C57.9208 25.0981 64.3332 30.2343 71.8069 38.005Z" stroke="#918EFF" strokeWidth="1.10085" strokeMiterlimit="10" />
                    <path opacity="0.2" d="M72.2309 37.2764C92.1855 60.3019 55.2356 97.3558 37.5382 73.2445C29.8104 62.7177 6.45001 44.4941 15.0076 33.805C23.4784 23.1447 34.5619 17.685 45.1375 21.5712C57.9551 26.2759 65.1745 29.1354 72.2309 37.2764Z" stroke="#918EFF" strokeWidth="1.10085" strokeMiterlimit="10" />
                    <path opacity="0.225" d="M72.655 36.5549C90.2511 59.249 57.666 96.9272 37.4433 73.9258C28.8048 64.0975 6.22407 42.7096 15.5427 32.695C24.7812 22.7122 35.6581 15.9149 45.7963 21.1475C57.9947 27.4461 66.0291 28.0072 72.655 36.5549Z" stroke="white" strokeWidth="1.10085" strokeMiterlimit="10" />
                    <path opacity="0.25" d="M73.0867 35.829C88.4007 58.1078 60.1226 96.4435 37.356 74.6027C27.8214 65.4541 6.0058 40.9207 16.0947 31.5764C26.085 22.2725 36.7646 14.1332 46.4562 20.7165C58.0472 28.5976 66.9123 26.8588 73.0867 35.829Z" stroke="white" strokeWidth="1.10085" strokeMiterlimit="10" />
                    <path opacity="0.275" d="M73.5101 35.1079C86.6353 56.9034 62.5919 95.9066 37.2669 75.2872C26.8375 66.7937 5.79493 39.1352 16.6423 30.4727C27.3845 21.8476 37.8719 12.3519 47.1182 20.3034C58.1006 29.7494 67.8254 25.6732 73.5101 35.1079Z" stroke="white" strokeWidth="1.10085" strokeMiterlimit="10" />
                    <path opacity="0.3" d="M73.9339 34.3866C84.9586 55.6453 65.0671 95.3129 37.1783 75.9716C25.8527 68.1157 5.57531 37.3538 17.193 29.3615C28.6936 21.4183 38.9955 10.5691 47.7833 19.8828C58.1702 30.8997 68.7942 24.4614 73.9339 34.3866Z" stroke="white" strokeWidth="1.10085" strokeMiterlimit="10" />
                    <path opacity="0.325" d="M74.366 33.6612C83.394 54.3703 67.5348 94.6745 37.0823 76.6532C24.8593 69.4176 5.35085 35.5624 17.7362 28.2476C29.9887 20.9833 40.1208 8.77913 48.4409 19.4595C58.2455 32.053 69.8212 23.234 74.366 33.6612Z" stroke="white" strokeWidth="1.10085" strokeMiterlimit="10" />
                    <path opacity="0.35" d="M74.7885 32.9394C81.9222 53.1108 69.9864 93.9949 36.995 77.3299C23.8575 70.6986 5.1325 33.7733 18.2789 27.1331C31.2899 20.5506 41.2522 6.99152 49.0981 19.0356C58.3229 33.1985 70.9201 21.996 74.7885 32.9394Z" stroke="white" strokeWidth="1.10085" strokeMiterlimit="10" />
                    <path opacity="0.375" d="M75.2156 32.2111C80.5642 51.9015 72.4217 93.2825 36.9071 78.0146C22.8538 71.9701 4.91359 31.9922 18.8276 26.0295C32.5931 20.1186 42.3896 5.21474 49.7574 18.6123C58.413 34.3578 72.1065 20.7545 75.2156 32.2111Z" stroke="white" strokeWidth="1.10085" strokeMiterlimit="10" />
                    <path opacity="0.4" d="M75.645 31.4928C79.3002 50.7851 74.841 92.5466 36.811 78.6961C21.8327 73.2428 4.69301 30.2109 19.3682 24.9227C33.8921 19.6937 43.5227 3.445 50.4189 18.199C58.5105 35.5125 73.3819 19.5361 75.645 31.4928Z" stroke="white" strokeWidth="1.10085" strokeMiterlimit="10" />
                </g>
                <g clipPath="url(#clip2_12591_135101)">
                    <path d="M47.9806 22.183C82.2323 -15.5122 100.439 69.848 66.0887 61.0949C48.8802 56.7107 48.5637 91.2325 32.1588 84.6844C15.7539 78.1364 24.4004 65.7695 21.095 48.9183C16.9763 27.8778 39.1357 31.9282 47.9806 22.183Z" stroke="white" strokeWidth="0.538698" strokeMiterlimit="10" />
                    <path opacity="0.9375" d="M47.3298 21.5512C81.3455 -13.3339 100.507 67.501 66.7864 61.3609C50.0272 58.3098 46.6145 91.0355 31.131 83.8274C15.635 76.6329 22.8285 64.1405 20.8261 48.111C18.2409 28.1005 38.3449 30.7641 47.3298 21.5512Z" stroke="white" strokeWidth="0.538698" strokeMiterlimit="10" />
                    <path opacity="0.875" d="M46.6706 20.9168C80.3835 -11.1736 100.639 65.143 67.4759 61.6242C51.2072 59.8991 44.6664 90.8381 30.0949 82.9677C15.5175 75.1289 21.3264 62.5183 20.5504 47.293C19.5847 28.3322 37.5553 29.5995 46.6706 20.9168Z" stroke="white" strokeWidth="0.538698" strokeMiterlimit="10" />
                    <path opacity="0.8125" d="M46.0224 20.277C79.3463 -9.03834 100.829 62.7411 68.186 61.8843C52.4106 61.4694 42.7198 90.633 29.0683 82.1105C15.4205 73.6217 19.794 60.8977 20.2842 46.4776C20.8984 28.5655 36.7451 28.4387 46.0224 20.277Z" stroke="white" strokeWidth="0.538698" strokeMiterlimit="10" />
                    <path opacity="0.75" d="M45.3717 19.6448C78.2301 -6.9128 101.072 60.3183 68.884 62.1498C53.627 63.0181 40.7612 90.4334 28.0312 81.2508C15.3019 72.1177 18.2495 59.2825 20.0156 45.6697C22.2014 28.7965 35.9118 27.289 45.3717 19.6448Z" stroke="white" strokeWidth="0.538698" strokeMiterlimit="10" />
                    <path opacity="0.6875" d="M44.712 19.0109C77.0216 -4.8243 101.338 57.8273 69.5729 62.4137C54.8593 64.538 38.8126 90.2365 26.9945 80.3917C15.1853 70.6064 16.696 57.6657 19.7393 44.8524C23.4925 29.0415 35.0613 26.1276 44.712 19.0109Z" stroke="white" strokeWidth="0.538698" strokeMiterlimit="10" />
                    <path opacity="0.625" d="M44.0635 18.3707C75.7236 -2.7897 101.616 55.2728 70.2731 62.6712C56.0978 66.0257 36.8561 90.0288 25.9581 79.5319C15.0674 69.1023 15.1413 56.0562 19.4618 44.0422C24.7742 29.2836 34.1905 24.9691 44.0635 18.3707Z" stroke="white" strokeWidth="0.538698" strokeMiterlimit="10" />
                    <path opacity="0.5625" d="M43.404 17.7363C74.307 -0.814813 101.866 52.6545 70.9718 62.9367C57.323 67.4777 34.9092 89.8235 24.9312 78.6744C14.9591 67.6005 13.5769 54.4445 19.1953 43.2264C26.0464 29.5237 33.3005 23.8063 43.404 17.7363Z" stroke="white" strokeWidth="0.538698" strokeMiterlimit="10" />
                    <path opacity="0.5" d="M42.7539 17.1044C72.8101 1.10938 102.096 49.9829 71.6704 63.2025C58.5415 68.9122 32.9608 89.6264 23.8947 77.8151C14.8426 66.089 12.0029 52.8311 18.9192 42.4089C27.3184 29.7642 32.3736 22.6273 42.7539 17.1044Z" stroke="white" strokeWidth="0.538698" strokeMiterlimit="10" />
                    <path opacity="0.4375" d="M42.105 16.4645C71.2002 2.9421 102.256 47.2543 72.3687 63.4682C59.7242 70.322 31.0023 89.4269 22.8673 76.9578C14.7338 64.5874 10.4285 51.2174 18.6508 41.6012C28.5694 30.008 31.419 21.4337 42.105 16.4645Z" stroke="white" strokeWidth="0.538698" strokeMiterlimit="10" />
                    <path opacity="0.375" d="M41.4464 15.8297C69.5092 4.68167 102.373 44.4985 73.0683 63.7333C60.9004 71.7212 29.055 89.2291 21.8304 76.1055C14.6169 63.083 8.83645 49.5989 18.3742 40.7908C29.8153 30.2333 30.4417 20.2094 41.4464 15.8297Z" stroke="white" strokeWidth="0.538698" strokeMiterlimit="10" />
                    <path opacity="0.3125" d="M40.796 15.1983C67.7469 6.31622 102.425 41.7129 73.7666 63.9996C62.0368 73.1129 27.1062 89.0324 20.8046 75.241C14.5096 61.5741 7.24592 47.9657 18.1073 39.9759C31.0502 30.4576 29.432 18.9382 40.796 15.1983Z" stroke="#969AFD" strokeWidth="0.538698" strokeMiterlimit="10" />
                    <path opacity="0.25" d="M40.1363 14.5639C65.926 7.83812 102.418 38.9213 74.4666 64.2573C63.1639 74.5017 25.1576 88.835 19.7679 74.3813C14.3915 60.0702 5.64605 46.3297 17.8311 39.158C32.2787 30.6632 28.4012 17.6204 40.1363 14.5639Z" stroke="#969AFD" strokeWidth="0.538698" strokeMiterlimit="10" />
                    <path opacity="0.1875" d="M39.4874 13.9239C64.0886 9.23339 102.372 36.1212 75.1649 64.5229C64.2701 75.894 23.2007 88.6277 18.731 73.5218C14.2731 58.5663 4.04738 44.6859 17.5531 38.3481C33.518 30.8633 27.3517 16.2411 39.4874 13.9239Z" stroke="#969AFD" strokeWidth="0.538698" strokeMiterlimit="10" />
                    <path opacity="0.125" d="M38.8372 13.2918C62.2801 10.5285 102.284 33.3359 75.8634 64.7886C65.356 77.2899 21.2521 88.4303 17.7025 72.6723C14.1646 57.0647 2.44897 43.0421 17.2849 37.5404C34.7575 31.0633 26.2995 14.7708 38.8372 13.2918Z" stroke="#969AFD" strokeWidth="0.538698" strokeMiterlimit="10" />
                    <path opacity="0.0625" d="M38.177 12.6576C60.5424 11.7087 102.164 30.5599 76.5518 65.0521C66.4303 78.6915 19.2934 88.2309 16.6653 71.8128C14.0556 55.563 0.862553 41.3847 17.0177 36.7249C35.9994 31.2477 25.2548 13.204 38.177 12.6576Z" stroke="#969AFD" strokeWidth="0.538698" strokeMiterlimit="10" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_12591_135101">
                    <rect width="94" height="94" rx="47" fill="white" />
                </clipPath>
                <clipPath id="clip1_12591_135101">
                    <rect width="73.2696" height="64.2886" fill="white" transform="matrix(-0.914 -0.405715 0.342482 -0.939524 69.3528 93.2249)" />
                </clipPath>
                <clipPath id="clip2_12591_135101">
                    <rect width="76.0218" height="78.2911" fill="white" transform="matrix(-0.975066 -0.221916 0.183719 -0.982979 75.8044 94.8392)" />
                </clipPath>
            </defs>
        </svg>
    );
}

import React from 'react';

export default (props) => {
    const {color} = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" {...props}>
            <g fill="none" fillRule="evenodd">
                <circle cx="20" cy="20" r="20" fill={color} />
                <path fill="#FFF" fillRule="nonzero" d="M12 12c-1.1 0-2 .9-2 2v10.793a.5.5 0 0 0 .854.353L13 23h9c1.1 0 2-.9 2-2v-7c0-1.1-.9-2-2-2H12zm14 5v4a4 4 0 0 1-4 4h-6v1c0 1.1.9 2 2 2h9l2.146 2.146a.5.5 0 0 0 .854-.353V19c0-1.1-.9-2-2-2h-2z" />
            </g>
        </svg>
    );
};

import React, {Component} from 'react';
import PropTypes, {instanceOf} from 'prop-types';
import {Cookies, withCookies} from 'react-cookie';
import AppError from '../../components/AppError/AppError';
import TransferConsultation from '../Consultation/TransferConsultation';
import QRConsultation from '../Consultation/QRConsultation';
import Preview from '../Consultation/Preview';
import securityStates from '../../utils/securityStates';
import {AppConsumer} from '../../contexts/App';
import EnterSecurityLock from '../SecurityLock/EnterSecurityLock';
import getPageId from '../../utils/getPageIdentificator';

/*
 * User can open web base screen with transfer link
 * User can open web base screen with link created by QR codes
 */
class Content extends Component {
    static propTypes = {
        code: PropTypes.string,
        appError: PropTypes.string,
        appState: PropTypes.string.isRequired,
        hasSecurityLock: PropTypes.bool,
        securityLockValidationResult: PropTypes.string,
        lastBlockTime: PropTypes.number,
        isBlocked: PropTypes.bool,
    };

    render() {
        const {
            appError,
            appState,
            hasSecurityLock,
            securityLockValidationResult,
            isBlocked,
            previewType,
        } = this.props;

        if (hasSecurityLock && isBlocked && appState !== 'preview') {
            return <AppError error={'5 wrong attempts. Try again later'} />;
        }
        if (
            hasSecurityLock &&
            securityLockValidationResult !== securityStates.UNLOCKED &&
            appState !== 'preview'
        ) {
            return <EnterSecurityLock {...this.props} />;
        }

        if (appError) {
            return <AppError error={appError} />;
        }

        if (appState === 'transfering') {
            return <TransferConsultation />;
        }

        if (appState === 'qr' || appState === 'miniWebsite') {
            return <QRConsultation appState={appState} />;
        }

        if (appState === 'preview') {
            return <Preview type={previewType} />;
        }

        return null;
    }
}

class ContentContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isBlocked: false,
        };
    }

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired,
    };

    componentDidMount() {
        this.checkIfBlocked();
    }

    checkIfBlocked = () => {
        const {cookies} = this.props;
        const pageIdentificator = getPageId();
        const FIVE_MINUTES = 1000 * 60 * 5;
        const diffTime = cookies.get(`${pageIdentificator}lastBlocked`)
            ? Date.now() - cookies.get(`${pageIdentificator}lastBlocked`)
            : FIVE_MINUTES * 2;

        if (diffTime < FIVE_MINUTES) {
            if (!this.state.isBlocked) {
                this.setState({isBlocked: true});
            }
            this.__blockTimeout = setTimeout(
                this.checkIfBlocked,
                FIVE_MINUTES - diffTime
            );
        } else if (this.state.isBlocked) {
            this.setState({isBlocked: false});
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.checkIfBlocked();
    }

    componentWillUnmount() {
        clearTimeout(this.__blockTimeout);
    }

    render() {
        return (
            <AppConsumer>
                {({state, actions}) => (
                    <Content
                        {...state}
                        {...actions}
                        {...this.props}
                        isBlocked={this.state.isBlocked}
                        lastBlockTime={
                            +this.props.cookies.get(`${getPageId()}lastBlocked`)
                        }
                    />
                )}
            </AppConsumer>
        );
    }
}

export default withCookies(ContentContainer);

import React from 'react';
import './Close.scss';

export default function Close() {
    return (
        <svg
            width='14'
            height='15'
            viewBox='0 0 14 15'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M13.9057 12.56C13.9057 12.9158 13.7724 13.2493 13.4882 13.5313C13.2395 13.7445 12.968 13.8717 12.5949 13.8717C12.2105 13.8717 11.9012 13.7335 11.6689 13.501L7.5097 9.3388L3.16045 13.6889C2.91178 13.9022 2.64028 14.0294 2.26716 14.0294C1.90344 14.0294 1.63579 13.9013 1.35552 13.6587C1.11566 13.4186 0.970703 13.1009 0.970703 12.7607C0.970703 12.4049 1.10399 12.0714 1.35552 11.8197L5.67282 7.49937L1.36984 3.18025C1.12998 2.94022 0.985027 2.62247 0.986736 2.32683C0.959948 1.97833 1.0973 1.64158 1.35552 1.35567C1.59537 1.11564 1.9129 0.970581 2.25284 0.970581C2.60838 0.970581 2.9416 1.10397 3.19375 1.35629L7.49611 5.67479L11.6546 1.51335C11.8945 1.27332 12.212 1.12826 12.5519 1.12826C12.9075 1.12826 13.2407 1.26164 13.481 1.5024C13.9851 1.98032 13.9931 2.78376 13.5065 3.32359L9.33374 7.49935L13.497 11.6525C13.7421 11.8792 13.8784 12.1834 13.9057 12.56Z'
                fill='white'
            />
        </svg>
    );
}

import React from 'react';

export default props => {
    const {color, className} = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" {...props}>
        <g fill="none" fillRule="evenodd">
                <g>
                <circle cx="20" cy="20" r="20" fill={color} />
                <path fill={'#fff'} class="cls-2" d="M20.82,16.08v-.56h.46a.48.48,0,0,0,.47-.48v-.49a.47.47,0,0,0-.47-.48h-2.8a.47.47,0,0,0-.47.48V15a.48.48,0,0,0,.47.48H19v.56a6.71,6.71,0,0,0-5.61,6.71H26.43A6.71,6.71,0,0,0,20.82,16.08Zm6.07,7.68h-14a.48.48,0,0,0-.46.48v1a.48.48,0,0,0,.46.49h14a.48.48,0,0,0,.47-.49v-1A.48.48,0,0,0,26.89,23.76Z"/>
            </g>
            </g>
        </svg>
    );
}

import React, {Component} from 'react';
import IOController from '../controllers/IOController';
import {ScreenConsumer} from './Screen';

const TemplateContext = React.createContext('template');

class TemplateContextProvider extends Component {
    state = {
        template: null,
        ticketWasSentFromScreens: [],
        isPdfViewerLoaded: false,
    };

    actions = {
        sendServiceNowTicket: this.sendServiceNowTicket.bind(this),
    };

    componentDidMount() {
        const pdfViewerScript = document.createElement('script');
        pdfViewerScript.src =
            'https://documentservices.adobe.com/view-sdk/viewer.js';
        document.body.appendChild(pdfViewerScript);
        document.addEventListener('adobe_dc_view_sdk.ready', () => {
            this.setState({isPdfViewerLoaded: true});
        });
    }

    componentDidUpdate(prevProps) {
        if (this.gotScreenInfo(prevProps)) {
            this.getTemplate();
        }

        if (
            !prevProps.isInitiallyConnected &&
            this.props.isInitiallyConnected
        ) {
            IOController.socket.on(
                'template_changed',
                this.getTemplate.bind(this)
            );
        }
    }

    componentWillUnmount() {
        IOController.socket.removeAllListeners('template_changed');
    }

    gotScreenInfo(prevProps) {
        const oldScreen = prevProps.screenState.user;
        const screen = this.props.screenState.user;
        return oldScreen !== screen;
    }

    getTemplate() {
        IOController.socket.emit('template_getMy', {}, (err, data) =>
            this.setState(data, () => {
                if (window.__QR_STOP_UPDATE_LOADER_FUNCTION__) {
                    window.__QR_STOP_UPDATE_LOADER_FUNCTION__();
                }
            })
        );
    }

    sendServiceNowTicket(ticketData, templateScreenIndex) {
        const {qrCode} = this.props.screenState.user;
        const {screens} = this.state.template;

        IOController.socket.emit('qrCode_serviceNowTicket', {
            ticketData,
            qrCodeId: qrCode.id || qrCode,
            templateScreenId: screens[templateScreenIndex].id,
        });
        this.setState(state => ({
            ticketWasSentFromScreens: [
                ...state.ticketWasSentFromScreens,
                templateScreenIndex,
            ],
        }));
    }

    render() {
        return (
            <TemplateContext.Provider
                value={{state: this.state, actions: this.actions}}
            >
                {this.props.children}
            </TemplateContext.Provider>
        );
    }
}

export const TemplateProvider = props => (
    <ScreenConsumer>
        {({state, actions}) => (
            <TemplateContextProvider
                screenState={state}
                screenActions={actions}
                {...props}
            />
        )}
    </ScreenConsumer>
);

export const TemplateConsumer = TemplateContext.Consumer;

import React, {Component} from 'react';
import ChatIcon from '../../assets/icon-chat.svg';
import {Scrollbars} from 'react-custom-scrollbars-2';
import './ChatBody.scss';
import moment from 'moment';
import MobileAgent from 'mobile-detect';
import ChatMessage from './ChatMessage';
import classNames from 'classnames';

const MESSAGES_PER_PAGE = 20;

class ChatBody extends Component {

    constructor(props) {
        super(props);
        this.state = {
            onMessagesScrollStartPosition: 0,
            onMessagesScrollStopPosition: 0,
            currentMessagePage: 0,
        };
        const mobileClient = new MobileAgent(window.navigator.userAgent);
        if (mobileClient.os() === 'AndroidOS') {
            this.onWindowResizer = this.onWindowResize.bind(this);
            window.addEventListener('resize', this.onWindowResizer);
        }
        this.isSafari = mobileClient.userAgent() === 'Safari';
    }
    
    componentDidMount() {
        const {getQrChatMessages} = this.props;
        getQrChatMessages(0, MESSAGES_PER_PAGE, Date.now(), () => {
            this.scrollToBottom();
        });
        this.scrollToBottom();
    }

    componentDidUpdate(prevProps, prevState) {
        const {messages, isOperatorTyping} = this.props;
        const {messages: prevMessages} = prevProps;
        if (!prevMessages.length || (prevMessages.length < messages.length) || isOperatorTyping) {
            this.scrollToBottom();
        }
    }

    componentWillUnmount() {
        if ((new MobileAgent(window.navigator.userAgent)).os() === 'AndroidOS') {
            window.removeEventListener('resize', this.onWindowResizer);
        }
    }

    onWindowResize() {
        if (!this.messagesScrollbar) {
            return;
        }
        const {onMessagesScrollStopPosition} = this.state;
        const {scrollHeight} = this.messagesScrollbar.getValues();
        if (onMessagesScrollStopPosition > 0.8 || (this.clientHeight && scrollHeight < this.clientHeight)) {
            this.scrollToBottom();
        }
    }

    onTouchMove() {
        // webkit-overflow-scrolling: touch at safari cause freezes when user start scroll top at top and scroll bottom at bottom.
        // This hack resolve it.
        // https://stackoverflow.com/questions/44815543/ios-10-uncertain-freezing-during-scroll-of-html-list-styled-with-webkit-overf
        if (this.isSafari) {
            document.body.scrollTop = 0;
        }
    }

    renderScrollbarsView({style, ...props}) {
        return (
            <div onTouchMove={this.onTouchMove.bind(this)} {...props} style={{...style}}/>
        )
    }

    renderChatIconWithHelloMessage() {
        return (
            <div className="chat-body-icon-with-message">
                <img src={ChatIcon}/>
                <p>Hello. Please type your message.</p>
            </div>
        )
    }

    renderChatIconWithWaitingMessage() {
        return (
            <div className="chat-body-icon-with-waiting-message">
                <img src={ChatIcon}/>
                <p>Waiting for operator<span>.</span><span>.</span><span>.</span></p>
            </div>
        )
    }

    renderOperatorWriteMessage(name) {
        return (
            <div className='chat-body-operator-write-message-container'>
                <p>{name} is typing<span>.</span><span>.</span><span>.</span></p>
            </div>
        )
    }

    scrollToBottom() {
        if (this.messagesScrollbar) {
            this.messagesScrollbar.scrollToBottom();
        }
    }

    onMessagesScrollStart() {
        if (this.messagesScrollbar) {
            const {top} = this.messagesScrollbar.getValues();
            this.setState({onMessagesScrollStartPosition: top})
        }
    }

    onMessagesScrollStop() {
        if (this.messagesScrollbar) {
            const {onMessagesScrollStartPosition, currentMessagePage} =this.state;
            const {loadedMessagesCount, getQrChatMessages, firstRequestedMessageTimestamp} = this.props;
            const {top} = this.messagesScrollbar.getValues();
            this.setState({onMessagesScrollStopPosition: top})
            if (top < 0.3 && onMessagesScrollStartPosition > top && loadedMessagesCount > (currentMessagePage + 1) * MESSAGES_PER_PAGE) {
                getQrChatMessages(currentMessagePage + 1, MESSAGES_PER_PAGE, firstRequestedMessageTimestamp, () => {
                    this.setState({currentMessagePage: currentMessagePage + 1});
                })
            }
        }
    }

    isMyMessage(user, message) {
        return user.id === message.sender.id;
    }

    shouldShowWaitingMessage() {
        const {messages, isChatAssigned} = this.props;
        return !!messages.length && !isChatAssigned;
    }

    onMessagesScrollbarRef(ref) {
        this.messagesScrollbar = ref;
        if (!ref) {
            return;  
        }  
        const {clientHeight} = ref.getValues();
        this.clientHeight = clientHeight;
    }

    render() {
        const {messages, style, user, isOperatorTyping, operatorName, chatAi} = this.props;

        return (
            <div style={style} className={classNames("chat-body-messages-container", {'chat-ai': chatAi})}>
                {chatAi && <div className='chat-ai-background' />}
                {!messages.length && !chatAi && this.renderChatIconWithHelloMessage()}
                <Scrollbars
                    style={{height: "100%"}}
                    onScrollStart={this.onMessagesScrollStart.bind(this)}
                    onScrollStop={this.onMessagesScrollStop.bind(this)}
                    renderView={this.renderScrollbarsView.bind(this)}
                    ref={this.onMessagesScrollbarRef.bind(this)}
                >
                    <div className={'messages-wrapper'}>
                        {this.shouldShowWaitingMessage() && this.renderChatIconWithWaitingMessage()}
                        {messages.map(message => <ChatMessage chatAi={chatAi} key={message.id} message={message} isMyMessage={this.isMyMessage(user, message)}/>)}
                        {isOperatorTyping && this.renderOperatorWriteMessage(operatorName)}
                    </div>
                </Scrollbars>
            </div>
        )
    };
}


export default ChatBody;

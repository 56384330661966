import React from 'react';

export default (props) => {
    return (
        <svg width="100" height="100" viewBox="0 0 145 172" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_12591_135245)">
                <path d="M14.5898 86.2845C14.5898 48.9396 44.8639 18.6655 82.2089 18.6655H149.828V153.904H82.2089C44.8639 153.904 14.5898 123.629 14.5898 86.2845Z" fill="black" />
                <g clipPath="url(#clip1_12591_135245)">
                    <path opacity="0.025" d="M100.803 75.7806C153.432 92.8281 80.9898 155.058 70.1792 116.834C64.798 97.825 29.8053 111.415 29.2966 91.553C28.7779 71.6993 45.1085 72.6285 60.4006 62.1537C79.4275 48.9989 86.8857 71.2681 100.803 75.7806Z" stroke="#E5E5FE" stroke-width="0.965663" stroke-miterlimit="10" />
                    <path opacity="0.05" d="M101.123 74.7741C150.696 92.9405 83.4469 153.924 70.2577 117.698C63.7621 99.8615 29.0217 109.289 29.6418 90.0372C30.2418 70.8014 46.0067 70.2198 61.0852 61.4535C79.8929 50.5102 87.6492 69.8319 101.123 74.7741Z" stroke="#E5E5FE" stroke-width="0.965663" stroke-miterlimit="10" />
                    <path opacity="0.075" d="M101.436 73.7566C147.936 92.95 85.9453 152.827 70.3464 118.555C62.7708 101.907 28.2395 107.154 29.9983 88.5042C31.727 69.8781 46.884 67.7579 61.7822 60.7264C80.3162 51.9941 88.3974 68.3733 101.436 73.7566Z" stroke="#C6C5FE" stroke-width="0.965663" stroke-miterlimit="10" />
                    <path opacity="0.1" d="M101.757 72.7502C145.169 92.8688 88.52 151.775 70.4336 119.422C61.8037 103.976 27.4569 105.019 30.3523 86.9903C33.1997 68.982 47.7488 65.3232 62.4756 60.028C80.7315 53.4665 89.1287 66.9014 101.757 72.7502Z" stroke="#C6C5FE" stroke-width="0.965663" stroke-miterlimit="10" />
                    <path opacity="0.125" d="M102.07 71.7325C142.392 92.6474 91.1508 150.714 70.5212 120.288C60.8725 106.052 26.6736 102.894 30.6989 85.465C34.6649 68.0745 48.5984 62.8658 63.1615 59.3183C81.1471 54.9389 89.8549 65.399 102.07 71.7325Z" stroke="#C6C5FE" stroke-width="0.965663" stroke-miterlimit="10" />
                    <path opacity="0.15" d="M102.382 70.7245C139.618 92.318 93.8538 149.656 70.5998 121.153C59.9613 108.113 25.8902 100.768 31.0441 83.9495C36.1378 67.1784 49.4378 60.4164 63.8372 58.6167C81.5449 56.408 90.5744 63.8755 102.382 70.7245Z" stroke="#C6C5FE" stroke-width="0.965663" stroke-miterlimit="10" />
                    <path opacity="0.175" d="M102.703 69.7184C136.87 91.8551 96.6253 148.562 70.6876 122.02C59.0984 110.153 25.1082 98.6336 31.3987 82.4358C37.611 66.2825 50.2877 57.9591 64.5312 57.9185C81.9363 57.8562 91.2888 62.3215 102.703 69.7184Z" stroke="#918EFF" stroke-width="0.965663" stroke-miterlimit="10" />
                    <path opacity="0.2" d="M103.015 68.7006C134.147 91.2488 99.4486 147.409 70.7758 122.877C58.2561 112.167 24.3255 96.4985 31.7547 80.9026C39.0857 65.367 51.1224 55.4693 65.2266 57.2008C82.3193 59.2928 92.0071 60.7286 103.015 68.7006Z" stroke="#918EFF" stroke-width="0.965663" stroke-miterlimit="10" />
                    <path opacity="0.225" d="M103.327 67.6923C131.467 90.512 102.308 146.183 70.8541 123.741C57.4174 114.152 23.5329 94.3711 32.0909 79.385C40.5595 64.4609 51.9573 52.9792 65.9122 56.4909C82.7036 60.7194 92.7302 59.0968 103.327 67.6923Z" stroke="#6E6BFF" stroke-width="0.965663" stroke-miterlimit="10" />
                    <path opacity="0.25" d="M103.649 66.6764C128.868 89.6426 105.186 144.882 70.9432 124.598C56.6017 116.102 22.751 92.2362 32.4478 77.8519C42.0351 63.5456 52.8041 50.4719 66.5995 55.7716C83.1009 62.1192 93.4852 57.4318 103.649 66.6764Z" stroke="#6E6BFF" stroke-width="0.965663" stroke-miterlimit="10" />
                    <path opacity="0.275" d="M103.961 65.6682C126.353 88.6705 108.063 143.511 71.0303 125.465C55.7787 118.03 21.9772 90.1027 32.8016 76.3379C43.5076 62.6494 53.65 47.9643 67.2927 55.0732C83.4975 63.5188 94.2642 55.712 103.961 65.6682Z" stroke="#6E6BFF" stroke-width="0.965663" stroke-miterlimit="10" />
                    <path opacity="0.3" d="M104.273 64.6603C123.931 87.6075 110.931 142.07 71.1181 126.331C54.9499 119.938 21.1942 87.9775 33.1573 74.8145C44.9909 61.7454 54.5158 45.4508 67.9878 54.3653C83.9138 64.9123 95.1044 53.9446 104.273 64.6603Z" stroke="#6E6BFF" stroke-width="0.965663" stroke-miterlimit="10" />
                    <path opacity="0.325" d="M104.595 63.6445C121.643 86.4911 113.777 140.575 71.1966 127.196C54.1051 121.824 20.4029 85.841 33.5037 73.2895C46.456 60.8382 55.3822 42.9276 68.6736 53.6559C84.3385 66.3077 96.0121 52.1409 104.595 63.6445Z" stroke="#536AE2" stroke-width="0.965663" stroke-miterlimit="10" />
                    <path opacity="0.35" d="M104.907 62.6362C119.474 85.3672 116.592 139.035 71.285 128.053C53.2447 123.686 19.6203 83.7057 33.8499 71.764C47.9297 59.9322 56.2573 40.4057 69.3592 52.946C84.7642 67.6929 97.0056 50.3036 104.907 62.6362Z" stroke="#536AE2" stroke-width="0.965663" stroke-miterlimit="10" />
                    <path opacity="0.375" d="M105.22 61.6185C117.457 84.2723 119.376 137.459 71.3728 128.92C52.3771 125.537 18.8372 81.5804 34.2044 70.2501C49.4041 59.0264 57.1408 37.8954 70.0453 52.2363C85.2071 69.0914 98.1033 48.4369 105.22 61.6185Z" stroke="#536AE2" stroke-width="0.965663" stroke-miterlimit="10" />
                    <path opacity="0.4" d="M105.541 60.6123C115.584 83.2642 122.135 135.858 71.4514 129.785C51.4902 127.395 18.0537 79.4551 34.5496 68.7346C50.8769 58.1304 58.0227 35.3948 70.7388 51.5381C85.6597 70.4819 99.3188 46.5728 105.541 60.6123Z" stroke="#536AE2" stroke-width="0.965663" stroke-miterlimit="10" />
                </g>
                <g clipPath="url(#clip2_12591_135245)">
                    <path d="M68.8942 57.1345C100.095 0.963182 147.03 100.603 102.333 99.7332C79.9414 99.2986 89.4939 141.805 67.4656 138.481C45.4374 135.156 52.4927 117.473 43.5817 97.7201C32.4657 73.0536 60.8403 71.6534 68.8942 57.1345Z" stroke="#4951FF" stroke-width="0.472546" stroke-miterlimit="10" />
                    <path opacity="0.9375" d="M67.9136 56.5453C99.6339 3.89448 146.439 97.6993 103.267 99.859C81.8107 100.933 87.0442 142.124 65.9573 137.723C44.859 133.343 50.0941 115.924 43.0196 96.8052C34.083 72.9631 59.5345 70.4505 67.9136 56.5453Z" stroke="#536AE2" stroke-width="0.472546" stroke-miterlimit="10" />
                    <path opacity="0.875" d="M66.9212 55.9556C99.0743 6.82569 145.921 94.7642 104.189 99.9841C83.7168 102.546 84.5951 142.442 64.4372 136.965C44.2812 131.529 47.7822 114.363 42.4451 95.8797C35.7994 72.8611 58.2293 69.2468 66.9212 55.9556Z" stroke="#536AE2" stroke-width="0.472546" stroke-miterlimit="10" />
                    <path opacity="0.8125" d="M65.9404 55.356C98.4151 9.74772 145.462 91.7583 105.135 100.099C85.646 104.129 82.1453 142.75 62.9293 136.207C43.7275 129.705 45.4333 112.813 41.8829 94.9544C37.4789 72.7698 56.8996 68.0537 65.9404 55.356Z" stroke="#536AE2" stroke-width="0.472546" stroke-miterlimit="10" />
                    <path opacity="0.75" d="M64.96 54.7664C97.6573 12.6805 145.063 88.7116 106.069 100.225C87.5861 105.682 79.6835 143.069 61.4089 135.45C43.1494 127.891 43.0725 111.273 41.321 94.0391C39.146 72.6785 55.546 66.8809 64.96 54.7664Z" stroke="#536AE2" stroke-width="0.472546" stroke-miterlimit="10" />
                    <path opacity="0.6875" d="M63.9677 54.1773C96.7752 15.5941 144.673 85.5744 106.991 100.35C89.5372 107.195 77.2345 143.387 59.8889 134.692C42.5712 126.068 40.6997 109.733 40.7467 93.1141C40.802 72.6078 54.1676 65.6985 63.9677 54.1773Z" stroke="#536AE2" stroke-width="0.472546" stroke-miterlimit="10" />
                    <path opacity="0.625" d="M62.987 53.5775C95.7671 18.4677 144.278 82.3559 107.925 100.466C91.4861 108.666 74.7724 143.695 58.3686 133.934C41.9932 124.254 38.3271 108.203 40.1725 92.1988C42.4453 72.5367 52.7646 64.5258 62.987 53.5775Z" stroke="#536AE2" stroke-width="0.472546" stroke-miterlimit="10" />
                    <path opacity="0.5625" d="M61.9944 52.9878C94.5964 21.3018 143.831 79.0671 108.859 100.591C93.4084 110.098 72.3227 144.003 56.8607 133.176C41.4276 122.44 35.9421 106.673 39.6103 91.2732C44.0763 72.4657 51.3368 63.3533 61.9944 52.9878Z" stroke="#6F75FF" stroke-width="0.472546" stroke-miterlimit="10" />
                    <path opacity="0.5" d="M61.0141 52.3985C93.3123 24.0968 143.344 75.7183 109.794 100.716C95.3174 111.51 69.8733 144.322 55.3404 132.419C40.8492 120.616 33.5447 105.144 39.0356 90.3482C45.7073 72.395 49.8591 62.1714 61.0141 52.3985Z" stroke="#747AFF" stroke-width="0.472546" stroke-miterlimit="10" />
                    <path opacity="0.4375" d="M60.0335 51.7991C91.8638 26.8119 142.756 72.3197 110.728 100.842C97.1761 112.902 67.4117 144.64 53.8325 131.661C40.2836 118.803 31.1474 103.614 38.4739 89.4331C47.314 72.3346 48.3438 60.9797 60.0335 51.7991Z" stroke="#747AFF" stroke-width="0.472546" stroke-miterlimit="10" />
                    <path opacity="0.375" d="M59.041 51.2095C90.2875 29.4367 142.105 68.9009 111.662 100.967C99.0221 114.283 64.9626 144.959 52.3129 130.913C39.7058 116.989 28.7255 102.085 37.9 88.5178C48.9076 72.2538 46.7902 59.7575 59.041 51.2095Z" stroke="#747AFF" stroke-width="0.472546" stroke-miterlimit="10" />
                    <path opacity="0.3125" d="M58.0608 50.62C88.5945 31.9513 141.366 65.4627 112.596 101.093C100.818 115.665 62.5133 145.277 50.8045 130.145C39.1397 115.165 26.3024 100.535 37.3377 87.5925C50.4885 72.1732 45.1845 58.4855 58.0608 50.62Z" stroke="#969AFD" stroke-width="0.472546" stroke-miterlimit="10" />
                    <path opacity="0.25" d="M57.0682 50.0305C86.797 34.3453 140.554 62.0349 113.53 101.208C102.602 117.047 60.064 145.595 49.2842 129.387C38.5618 113.351 23.867 98.9853 36.7631 86.6672C52.0563 72.0724 43.5394 57.163 57.0682 50.0305Z" stroke="#969AFD" stroke-width="0.472546" stroke-miterlimit="10" />
                    <path opacity="0.1875" d="M56.0874 49.4309C84.943 36.5883 139.692 58.6076 114.464 101.333C104.362 118.44 57.6019 145.903 47.7639 128.63C37.9837 111.537 21.4311 97.4255 36.1889 85.752C53.6358 71.9615 41.8539 55.7702 56.0874 49.4309Z" stroke="#969AFD" stroke-width="0.472546" stroke-miterlimit="10" />
                    <path opacity="0.125" d="M55.1075 48.8413C83.0958 38.6999 138.782 55.2107 115.399 101.458C106.097 119.842 55.1529 146.222 46.2567 127.882C37.4184 109.723 18.9955 95.8657 35.6274 84.8367C55.2157 71.8505 40.139 54.2664 55.1075 48.8413Z" stroke="#969AFD" stroke-width="0.472546" stroke-miterlimit="10" />
                    <path opacity="0.0625" d="M54.1149 48.2518C81.3028 40.6497 137.835 51.8343 116.321 101.584C107.821 121.255 52.6912 146.54 44.7365 127.124C36.8529 107.91 16.5711 94.2857 35.0652 83.9113C56.7945 71.7194 38.4059 52.6417 54.1149 48.2518Z" stroke="#969AFD" stroke-width="0.472546" stroke-miterlimit="10" />
                </g>
                <g opacity="0.4" filter="url(#filter0_f_12591_135245)">
                    <circle cx="76.1983" cy="88.5385" r="65.3651" fill="url(#paint0_radial_12591_135245)" />
                </g>
            </g>
            <path d="M162.5 15L162.5 7.5L155 7.5L86 7.5C42.6457 7.5 7.5 42.6456 7.5 86C7.5 129.354 42.6456 164.5 86 164.5L155 164.5L162.5 164.5L162.5 157L162.5 15Z" stroke="#536AE2" strokeOpacity="0.2" stroke-width="15" />
            <defs>
                <filter id="filter0_f_12591_135245" x="-0.436589" y="11.9036" width="153.27" height="153.27" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="5.63492" result="effect1_foregroundBlur_12591_135245" />
                </filter>
                <radialGradient id="paint0_radial_12591_135245" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(76.1983 88.5385) rotate(90) scale(65.3651)">
                    <stop offset="0.557292" stopColor="#0021D6" />
                    <stop offset="1" stopColor="#0021D6" stop-opacity="0" />
                </radialGradient>
                <clipPath id="clip0_12591_135245">
                    <path d="M155 15L155 157L86 157C46.7878 157 15 125.212 15 86C15 46.7878 46.7878 15 86 15L155 15Z" fill="white" />
                </clipPath>
                <clipPath id="clip1_12591_135245">
                    <rect width="92.4113" height="81.1231" fill="white" transform="matrix(-0.982418 -0.186691 0.118845 -0.992913 115.591 138.271)" />
                </clipPath>
                <clipPath id="clip2_12591_135245">
                    <rect width="95.8751" height="98.7977" fill="white" transform="matrix(-0.99998 0.00629195 -0.0455526 -0.998962 123.979 138.398)" />
                </clipPath>
            </defs>
        </svg>
    );
}

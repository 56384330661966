import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import CloseButton from '../../assets/close-button.svg';
import './ClosablePopUpError.scss';

const ClosablePopUpError = ({isOpen, title, errorText, onClose, top, left, style}) => {

    if (!isOpen) {
        return null;
    }

    return (
        <div style={style} className={classnames('qr-chat-closable-popup-error', top && 'top', !top && 'bottom', left && 'left', !left && 'right')}>
            <img src={CloseButton} onClick={onClose} />
            <div className='qr-chat-closable-popup-error-title'>{title}</div>
            <div className='qr-chat-closable-popup-error-text'>{errorText}</div>
        </div>
    );
};

ClosablePopUpError.propTypes = {
    isOpen: propTypes.bool.isRequired,
    onClose: propTypes.func.isRequired,
    title: propTypes.string,
    errorText: propTypes.string,
    top: propTypes.bool, // If false error will pop up at bottom.
    left: propTypes.bool, // If false error will pop up at left.
};

ClosablePopUpError.defaultTypes = {
    title: '',
    errorText: '',
    top: true, 
    left: true,
};

export default ClosablePopUpError;

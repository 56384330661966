import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {AppConsumer} from './App';
import IOController from '../controllers/IOController';
import _ from 'lodash';

export const ScreenContext = React.createContext('screen');

class ScreenContextProvider extends Component {
    static propTypes = {
        appState: PropTypes.object.isRequired,
        appActions: PropTypes.object.isRequired
    };

    state = {
        room: null,
        user: null,
        qrCode: null,
        consultationRequested: false,
        companyRegionCode: null,
        companyCountryChoices: [],
        geoLocation: {},
        isGeoLocationReady: false,
        fetchGeoLocationAttempts: 0,
        isUserRegistered: false,
        companyHasAvailableMinutes: false,
    };

    actions = {
        registerScreenVisit: this.registerScreenVisit.bind(this),
        registerWebscreen: this.registerWebscreen.bind(this),
        registerQRScreen: this.registerQRScreen.bind(this),
        openDoor: this.openDoor.bind(this),
    };

    fetchGeoLocationTimer = null;

    componentDidMount() {
        this.requestGeoLocation();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.appStateChangedTo(prevProps, 'transfering')) {
            this.registerWebscreen();
        }

        const isStateChangedToQr = this.appStateChangedTo(prevProps, 'qr');
        const isStateChangedToMiniWebsite = this.appStateChangedTo(prevProps, 'miniWebsite');
        const isOnQrOrMiniWebsiteState = ['qr', 'miniWebsite'].includes(this.props.appState.appState);
        const isReconnected = !prevProps.connected && this.props.connected && isOnQrOrMiniWebsiteState;
        if (isStateChangedToQr || isStateChangedToMiniWebsite || isReconnected) {
            this.registerQRScreen();
        }

        if (!prevState.room && this.state.room) {
            this.getCountryChoices();
            this.getSubscriptionAvailability();
        }

        if (!prevProps.isInitiallyConnected && this.props.isInitiallyConnected) {
            IOController.socket.on('room_roomInfo', this.updateRoomInfo.bind(this));
            IOController.socket.on('company_editScreenSettings', data => this.updateRoomInfo(data.room));
            IOController.socket.on('room_hasAvailableMembers', data => this.updateRoomInfo(data.room));
            IOController.socket.on('subscription_updateAvailability', this.updateSubscriptionAvailability.bind(this));
            IOController.socket.on('qrCode_updateInfo', this.updateQrCode);
        }
    }

    componentWillUnmount() {
        this.clearFetchGeoLocationTimer();
    }

    updateSubscriptionAvailability({availableMinutes = 0}) {
        this.setState({
            companyHasAvailableMinutes: _.isString(availableMinutes) ? true : availableMinutes > 0
        });
    }

    updateQrCode = (qrCode) => {
        this.setState({qrCode})
    }

    getSubscriptionAvailability() {
        IOController.socket.emit('subscription_getAvailability', {}, (err, {availableMinutes = 0}) => {
            this.setState({
                companyHasAvailableMinutes: _.isString(availableMinutes) ? true : availableMinutes > 0
            })
        })
    }    

    openDoor() {
        return new Promise((resolve, reject) => {
            IOController.socket.emit('doorUnlock_openDoorByChatAiFromQrCode', {}, (err, result) => {
                if (err) {
                    return reject(err)
                }

                return resolve(result);
            })
        })
    }

    requestGeoLocation = () => {
        fetch('https://ipwho.is/')
            .then(res => res.json())
            .then(res => this.setState({isGeoLocationReady: true, geoLocation: {...res, country_name: res.country}}))
            .catch(() => {
                if (this.state.fetchGeoLocationAttempts > 3) {
                    console.error('Unable to fetch geolocation data');
                    return;
                }

                this.setState(
                    state => ({fetchGeoLocationAttempts: state.fetchGeoLocationAttempts + 1}),
                    this.setFetchGeoLocationTimer
                );
            });
    };

    setFetchGeoLocationTimer = () => {
        const timeout = this.state.fetchGeoLocationAttempts * 1000;
        this.fetchGeoLocationTimer = setTimeout(this.requestGeoLocation, timeout);
    };

    clearFetchGeoLocationTimer = () => {
        if (!this.fetchGeoLocationTimer) return;

        clearTimeout(this.fetchGeoLocationTimer);
        this.fetchGeoLocationTimer = null;
    };

    getCountryChoices() {
        const {room} = this.state;
        IOController.socket.emit('company_getCountryChoices', {companyId: room.companyId}, (err, data) => {
            if (!err) {
                this.setState({
                    companyRegionCode: data.company_region_code,
                    companyCountryChoices: data.country_choices
                });
            }
        });
    }

    registerScreenVisit(screenName) {
        const {room, geoLocation} = this.state;
        const {appState} = this.props;
        if (!(room && room.companyId && appState && appState.code)) {
            return;
        }
        IOController.socket.emit('statistics_registerQrCodeScreenVisit', {
            companyId: room.companyId,
            qrCode: appState.code,
            geoLocation,
            domainName: appState.domainName,
            screenName
        });
    }

    appStateChangedTo(prevProps, state) {
        const oldState = prevProps.appState.appState;
        const newState = this.props.appState.appState;
        return oldState !== state && newState === state;
    }

    registerWebscreen() {
        const {appActions, appState} = this.props;
        IOController.socket.emit('consultation_registerWebscreen', {transferId: appState.code}, (err, data) => {
            if (err) {
                return appActions.setAppError(err.message || 'Invalid transfer link');
            }
            this.setState({room: data.room, user: data.user});
        });
    }

    registerQRScreen(code, cb) {
        const {appActions, appState} = this.props;
        IOController.socket.emit('qrCode_registerQrScreen', appState, data => {
            if (data.code && data.message) {
                return appActions.setAppError(data.message || 'Invalid qr code link');
            }
            this.registerUser(data.user);
            if (data.qrCode) {
                data.qrCode.aiDisabled = !data.room.roomInfo.enable_ai_configuration_for_qr
                console.log("aiDisabled", !data.room.roomInfo.enable_ai_configuration_for_qr)
            }
            this.setState({room: data.room, user: data.user, qrCode: data.qrCode}, cb);
            if (!data.room.active) {
                return appActions.setAppError('Sorry, your link is no longer valid.\n Please request the new link from company employee.');
            }
        });
    }

    registerUser(user) {
        const {qrCodeUserRegisterToken} = user;
        if (!qrCodeUserRegisterToken) {
            return;
        }
        fetch(`${process.env.SIGNAL_HTTP_URL}/api/qrCode/registerUser`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({qrCodeUserRegisterToken})
        }).then(_ => {
            this.setState({isUserRegistered: true})
        });
    }

    updateRoomInfo(room) {
        const {appActions} = this.props;
        let qrCode;
        if (this.state.qrCode) {
            qrCode = {...this.state.qrCode, aiDisabled: !room.roomInfo.enable_ai_configuration_for_qr}
            console.log("aiDisabled", !room.roomInfo.enable_ai_configuration_for_qr)
        }
        this.setState({room, qrCode});
        if (!room.active) {
            return appActions.setAppError('Sorry, your link is no longer valid.\n Please request the new link from company employee.');
        }
    }

    render() {
        return (
            <ScreenContext.Provider value={{state: this.state, actions: this.actions}}>
                {this.props.children}
            </ScreenContext.Provider>
        );
    }
}

export const ScreenProvider = props => (
    <AppConsumer>
        {({state, actions}) => <ScreenContextProvider appState={state} appActions={actions} {...props}/>}
    </AppConsumer>
);

export const ScreenConsumer = ScreenContext.Consumer;
